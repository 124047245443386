"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.favoritesReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _state = require("../../types/state.types");

var _favorites = require("./favorites.types");

const updateFavoritesReducer = (0, _immer.default)((draft, favorites) => {
  if (!favorites) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(favorites), Number));
  const ids = Object.keys(favorites);

  for (const id of ids) {
    draft.byId[id] = favorites[id];
  }
});

const favoritesReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_favorites.getFavoritesAsync.success):
      return updateFavoritesReducer(state, action.payload);

    default:
      return state;
  }
};

exports.favoritesReducer = favoritesReducer;
var _default = favoritesReducer;
exports.default = _default;