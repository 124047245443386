"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = qnaSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _dashboard = require("../dashboard/dashboard.types");

var _schema = require("../schema");

var _qna = require("./qna.types");

var _socialUtil = require("../../redux/user/socialUtil");

var _comments = require("../../redux/comments/comments.types");

var _thumbsups = require("../../redux/thumbsups/thumbsups.types");

var _favorites = require("../../redux/favorites/favorites.types");

const getQnaPosts = args => {
  let {
    studyId,
    pageNumber,
    pageSize,
    previewLength,
    query,
    answers,
    includeDeleted
  } = args;

  if (!pageNumber) {
    pageNumber = 0;
  }

  if (!pageSize) {
    pageSize = 25;
  }

  const includeDeletedParam = includeDeleted ? 'true' : 'false';
  let url = `/a/qna/${studyId}/posts?pageSize=${pageSize}&pageNumber=${pageNumber}&includeDeleted=${includeDeletedParam}&answers=${answers}`;

  if (previewLength) {
    url += `&previewLength=${previewLength}`;
  }

  if (query) {
    url += `&searchText=${query}`;
  }

  return (0, _api.default)({
    method: 'get',
    url
  });
};

const getQnaPost = id => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/qna/post/${id}/`
  });
};

const getQnaPostCount = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/qna/${studyId}/postCount`
  });
};

const deleteQnaPost = id => {
  return (0, _api.default)({
    method: 'delete',
    url: `/a/qna/post/${id}`
  });
};

const createQnaPost = post => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/qna`,
    data: post
  });
};

const updateQnaPost = post => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/qna/${post.id}/update`,
    data: post
  });
};

const getFlaggedQnaContent = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/qna/${studyId}/flagged`
  });
};

function* getQnaPostsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getQnaPosts, action.payload);
    const {
      entities,
      result
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.qnaPosts);
    const {
      qnaPosts
    } = entities;

    if (result === null || result === void 0 ? void 0 : result.length) {
      const jsonParam = [(0, _socialUtil.createJsonParam)('qna', result)];
      yield (0, _effects.put)(_comments.getCommentsAsync.request(jsonParam));
      yield (0, _effects.put)(_thumbsups.getThumbsupsAsync.request(jsonParam));
      yield (0, _effects.put)(_favorites.getFavoritesAsync.request(jsonParam));
    }

    yield (0, _effects.put)(_qna.getQnaPostsAsync.success(qnaPosts));
  } catch (error) {
    yield (0, _effects.put)(_qna.getQnaPostsAsync.failure(error));
  }
}

function* getQnaPostHandler(action) {
  try {
    const response = yield (0, _effects.call)(getQnaPost, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.qnaPosts);
    const {
      qnaPosts
    } = entities;
    yield (0, _effects.put)(_qna.getQnaPostAsync.success(qnaPosts));
  } catch (error) {
    yield (0, _effects.put)(_qna.getQnaPostAsync.failure(error));
  }
}

function* deleteQnaPostHandler(action) {
  try {
    const response = yield (0, _effects.call)(deleteQnaPost, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.qnaPosts);
    const {
      qnaPosts
    } = entities;
    yield (0, _effects.put)(_qna.deleteQnaPostAsync.success(qnaPosts));
    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.request());
  } catch (error) {
    yield (0, _effects.put)(_qna.deleteQnaPostAsync.failure(error));
  }
}

function* createQnaPostHandler(action) {
  try {
    const response = yield (0, _effects.call)(createQnaPost, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.qnaPosts);
    const {
      qnaPosts
    } = entities;
    yield (0, _effects.put)(_qna.createQnaPostAsync.success(qnaPosts));
    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.request());
  } catch (error) {
    yield (0, _effects.put)(_qna.createQnaPostAsync.failure(error));
  }
}

function* updateQnaPostHandler(action) {
  try {
    const response = yield (0, _effects.call)(updateQnaPost, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.qnaPosts);
    const {
      qnaPosts
    } = entities;
    yield (0, _effects.put)(_qna.updateQnaPostAsync.success(qnaPosts));
    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.request());
  } catch (error) {
    yield (0, _effects.put)(_qna.updateQnaPostAsync.failure(error));
  }
}

function* getFlaggedQnaContentHandler(action) {
  try {
    const response = yield (0, _effects.call)(getFlaggedQnaContent, action.payload);
    const {
      entities,
      result
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.qnaPosts);
    const {
      qnaPosts
    } = entities;

    if (result === null || result === void 0 ? void 0 : result.length) {
      const jsonParam = [(0, _socialUtil.createJsonParam)('qna', result)];
      yield (0, _effects.put)(_comments.getCommentsAsync.request(jsonParam));
      yield (0, _effects.put)(_thumbsups.getThumbsupsAsync.request(jsonParam));
      yield (0, _effects.put)(_favorites.getFavoritesAsync.request(jsonParam));
    }

    yield (0, _effects.put)(_qna.getFlaggedQnaContentAsync.success(qnaPosts));
  } catch (error) {
    yield (0, _effects.put)(_qna.getFlaggedQnaContentAsync.failure(error));
  }
}

function* getQnaPostCountHandler(action) {
  try {
    const response = yield (0, _effects.call)(getQnaPostCount, action.payload);
    yield (0, _effects.put)(_qna.getQnaPostCountAsync.success(response.data));
  } catch (error) {
    yield (0, _effects.put)(_qna.getQnaPostCountAsync.failure(error));
  }
}

function* getQnaPostsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_qna.getQnaPostsAsync.request), getQnaPostsHandler);
}

function* getQnaPostWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_qna.getQnaPostAsync.request), getQnaPostHandler);
}

function* deleteQnaPostWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_qna.deleteQnaPostAsync.request), deleteQnaPostHandler);
}

function* createQnaPostWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_qna.createQnaPostAsync.request), createQnaPostHandler);
}

function* updateQnaPostWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_qna.updateQnaPostAsync.request), updateQnaPostHandler);
}

function* getFlaggedQnaContentWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_qna.getFlaggedQnaContentAsync.request), getFlaggedQnaContentHandler);
}

function* getQnaPostCountWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_qna.getQnaPostCountAsync.request), getQnaPostCountHandler);
}

function* qnaSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getQnaPostsWatcher), (0, _effects.fork)(getQnaPostWatcher), (0, _effects.fork)(deleteQnaPostWatcher), (0, _effects.fork)(createQnaPostWatcher), (0, _effects.fork)(updateQnaPostWatcher), (0, _effects.fork)(getFlaggedQnaContentWatcher), (0, _effects.fork)(getQnaPostCountWatcher)]);
}