"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getForms = exports.selectForms = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = _interopRequireDefault(require("../schema"));

const selectForms = state => {
  return state.entities.forms;
};

exports.selectForms = selectForms;
const getForms = (0, _reselect.createSelector)([selectForms], forms => {
  if (forms && forms.allIds && forms.allIds.length > 0) {
    const {
      forms: denormalizedForms
    } = (0, _normalizr.denormalize)({
      forms: forms.allIds
    }, _schema.default, {
      forms: forms.byId
    });
    return (0, _toArray.default)(denormalizedForms);
  }

  return undefined;
});
exports.getForms = getForms;
var _default = {
  getForms
};
exports.default = _default;