"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = avatarSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _avatar = require("./avatar.types");

const getAvatar = avatarId => {
  return (0, _api.default)({
    method: 'get',
    url: `/u/avatar/${avatarId}`
  });
};

function* getAvatarHandler(action) {
  try {
    const avatarId = action.payload;
    const response = yield (0, _effects.call)(getAvatar, avatarId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.avatars);
    const {
      avatars
    } = entities;
    yield (0, _effects.put)(_avatar.getAvatarAsync.success(avatars));
  } catch (error) {
    yield (0, _effects.put)(_avatar.getAvatarAsync.failure(error));
  }
}

function* getAvatarWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_avatar.getAvatarAsync.request), getAvatarHandler);
}

const getAvatars = () => {
  return (0, _api.default)({
    method: 'get',
    url: '/u/avatar'
  });
};

function* getAvatarsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getAvatars);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.avatars);
    const {
      avatars
    } = entities;
    yield (0, _effects.put)(_avatar.getAvatarsAsync.success(avatars));
  } catch (error) {
    yield (0, _effects.put)(_avatar.getAvatarsAsync.failure(error));
  }
}

function* getAvatarsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_avatar.getAvatarsAsync.request), getAvatarsHandler);
}

function* avatarSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getAvatarWatcher), (0, _effects.fork)(getAvatarsWatcher)]);
}