"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.goalsReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _goals = require("./goals.types");

var _state = require("../../types/state.types");

const updateGoalsReducer = (0, _immer.default)((draft, goals) => {
  if (!goals) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(goals), String));
  const ids = Object.keys(goals);

  for (const id of ids) {
    draft.byId[id] = goals[id];
  }
});

const goalsReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_goals.getGoalsAsync.success):
      return updateGoalsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_goals.getGoalAsync.success):
      return updateGoalsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_goals.createGoalAsync.success):
      return updateGoalsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_goals.updateGoalAsync.success):
      return updateGoalsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_goals.deleteGoalAsync.success):
      return updateGoalsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_goals.updateGoals):
      return updateGoalsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_goals.clearGoals):
      return {
        allIds: [],
        byId: {}
      };

    default:
      return state;
  }
};

exports.goalsReducer = goalsReducer;
var _default = goalsReducer;
exports.default = _default;