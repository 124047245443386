"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateActivities = exports.uploadActivityImageAsync = exports.unpublishActivityAsync = exports.publishActivityAsync = exports.deleteActivityAsync = exports.updateActivityComponentAsync = exports.upsertActivityAsync = exports.getActivityTypesAsync = exports.getActivityCategoriesAsync = exports.getActivityAsync = exports.getActivitySummariesAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getActivitySummariesAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_ACTIVITY_SUMMARIES_REQUEST', 'hmp/admin/GET_ACTIVITY_SUMMARIES_SUCCESS', 'hmp/admin/GET_ACTIVITY_SUMMARIES_FAILURE')();
exports.getActivitySummariesAsync = getActivitySummariesAsync;
const getActivityAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_ACTIVITY_REQUEST', 'hmp/admin/GET_ACTIVITY_SUCCESS', 'hmp/admin/GET_ACTIVITY_FAILURE')();
exports.getActivityAsync = getActivityAsync;
const getActivityCategoriesAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_ACTIVITY_CATEGORIES_REQUEST', 'hmp/admin/GET_ACTIVITY_CATEGORIES_SUCCESS', 'hmp/admin/GET_ACTIVITY_CATEGORIES_FAILURE')();
exports.getActivityCategoriesAsync = getActivityCategoriesAsync;
const getActivityTypesAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_ACTIVITY_TYPES_REQUEST', 'hmp/admin/GET_ACTIVITY_TYPES_SUCCESS', 'hmp/admin/GET_ACTIVITY_TYPES_FAILURE')();
exports.getActivityTypesAsync = getActivityTypesAsync;
const upsertActivityAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPSERT_ACTIVITY_REQUEST', 'hmp/admin/UPSERT_ACTIVITY_SUCCESS', 'hmp/admin/UPSERT_ACTIVITY_FAILURE')();
exports.upsertActivityAsync = upsertActivityAsync;
const updateActivityComponentAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPDATE_ACTIVITY_COMPONENT_REQUEST', 'hmp/admin/UPDATE_ACTIVITY_COMPONENT_SUCCESS', 'hmp/admin/UPDATE_ACTIVITY_COMPONENT_FAILURE')();
exports.updateActivityComponentAsync = updateActivityComponentAsync;
const deleteActivityAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/DELETE_ACTIVITY_REQUEST', 'hmp/admin/DELETE_ACTIVITY_SUCCESS', 'hmp/admin/DELETE_ACTIVITY_FAILURE')();
exports.deleteActivityAsync = deleteActivityAsync;
const publishActivityAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/PUBLISH_ACTIVITY_REQUEST', 'hmp/admin/PUBLISH_ACTIVITY_SUCCESS', 'hmp/admin/PUBLISH_ACTIVITY_FAILURE')();
exports.publishActivityAsync = publishActivityAsync;
const unpublishActivityAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UNPUBLISH_ACTIVITY_REQUEST', 'hmp/admin/UNPUBLISH_ACTIVITY_SUCCESS', 'hmp/admin/UNPUBLISH_ACTIVITY_FAILURE')();
exports.unpublishActivityAsync = unpublishActivityAsync;
const uploadActivityImageAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPLOAD_ACTIVITY_IMAGE_REQUEST', 'hmp/admin/UPLOAD_ACTIVITY_IMAGE_SUCCESS', 'hmp/admin/UPLOAD_ACTIVITY_IMAGE_FAILURE')();
exports.uploadActivityImageAsync = uploadActivityImageAsync;
const updateActivities = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_ACTIVITIES')();
exports.updateActivities = updateActivities;
const activitiesActions = {
  getActivitySummariesAsync,
  getActivityAsync,
  upsertActivityAsync,
  deleteActivityAsync,
  publishActivityAsync,
  unpublishActivityAsync,
  activityImageUploadAsync: uploadActivityImageAsync,
  getActivityCategoriesAsync,
  getActivityTypesAsync,
  updateActivities
};