"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getParticipants = exports.selectParticipants = void 0;

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _reselect = require("reselect");

var _schema = _interopRequireDefault(require("../schema"));

const selectParticipants = state => {
  return state.entities.participants;
};

exports.selectParticipants = selectParticipants;
const getParticipants = (0, _reselect.createSelector)([selectParticipants], participants => {
  if (!participants) {
    return undefined;
  }

  const {
    participants: denormalizedParticipants
  } = (0, _normalizr.denormalize)({
    participants: participants.allIds
  }, _schema.default, {
    participants: participants.byId
  });
  return (0, _toArray.default)(denormalizedParticipants);
});
exports.getParticipants = getParticipants;