"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = flagSaga;

var _api = _interopRequireDefault(require("../api"));

var _effects = require("@redux-saga/core/effects");

var _normalizr = require("normalizr");

var _comments = require("../comments/comments.types");

var _dashboard = require("../dashboard/dashboard.types");

var _posts = require("../posts/posts.types");

var _schema = require("../schema");

var _flags = require("./flags.types");

var _typesafeActions = require("typesafe-actions");

const getFlags = jsonFlags => {
  const param = JSON.stringify(jsonFlags);
  return (0, _api.default)({
    method: 'get',
    url: `/a/social/flags?flagsJson=${encodeURIComponent(param)}`
  });
};

function* getFlagsHandler(action) {
  try {
    const {
      payload
    } = action;
    const response = yield (0, _effects.call)(getFlags, payload);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.flags);
    const {
      flags
    } = entities;
    yield (0, _effects.put)(_flags.getFlagsAsync.success(flags));
  } catch (error) {
    yield (0, _effects.put)(_flags.getFlagsAsync.failure(error));
  }
}

function* getFlagsWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_flags.getFlagsAsync.request), getFlagsHandler);
}

const resolveFlags = flagResolution => {
  return (0, _api.default)({
    method: 'post',
    url: `/a/social/flag/resolve`,
    data: flagResolution
  });
};

function* resolveFlagsHandler(action) {
  try {
    const flagResolution = action.payload;
    const response = yield (0, _effects.call)(resolveFlags, flagResolution);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.flags);
    const {
      flags
    } = entities;
    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.request());
    yield (0, _effects.put)(_posts.getFlaggedPostsAsync.request());
    yield (0, _effects.put)(_flags.resolveFlagsAsync.success(flags));
  } catch (error) {
    yield (0, _effects.put)(_flags.resolveFlagsAsync.failure(error));
  }
}

function* resolveFlagsWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_flags.resolveFlagsAsync.request), resolveFlagsHandler);
}

const hideContent = payload => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/forum/hide/${payload.type}/${payload.typeId}`
  });
};

function* hideContentHandler(action) {
  try {
    const payload = action.payload;
    const response = yield (0, _effects.call)(hideContent, payload);

    if ('comment' === payload.type) {
      const {
        entities
      } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.comments);
      const {
        comments
      } = entities;
      yield (0, _effects.put)((0, _comments.updateComments)(comments));
    } else if ('post' === payload.type) {
      const {
        entities
      } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.posts);
      const {
        posts
      } = entities;
      yield (0, _effects.put)((0, _posts.updatePosts)(posts));
    }

    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.request());
  } catch (error) {
    yield (0, _effects.put)(_flags.hideContentAsync.failure(error));
  }
}

function* hideContentWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_flags.hideContentAsync.request), hideContentHandler);
}

const unhideContent = payload => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/forum/unhide/${payload.type}/${payload.typeId}`
  });
};

function* unhideContentHandler(action) {
  try {
    const payload = action.payload;
    const response = yield (0, _effects.call)(unhideContent, payload);

    if ('comment' === payload.type) {
      const {
        entities
      } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.comments);
      const {
        comments
      } = entities;
      yield (0, _effects.put)((0, _comments.updateComments)(comments));
    } else if ('post' === payload.type) {
      const {
        entities
      } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.posts);
      const {
        posts
      } = entities;
      yield (0, _effects.put)((0, _posts.updatePosts)(posts));
    }
  } catch (error) {
    yield (0, _effects.put)(_flags.unhideContentAsync.failure(error));
  }
}

function* unhideContentWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_flags.unhideContentAsync.request), unhideContentHandler);
}

function* flagSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getFlagsWatcher)]);
  yield (0, _effects.all)([(0, _effects.fork)(resolveFlagsWatcher)]);
  yield (0, _effects.all)([(0, _effects.fork)(hideContentWatcher)]);
  yield (0, _effects.all)([(0, _effects.fork)(unhideContentWatcher)]);
}