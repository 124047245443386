"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = configureStore;

var _moment = _interopRequireDefault(require("moment"));

var _connectedReactRouter = require("connected-react-router");

var _redux = require("redux");

var _developmentOnly = require("redux-devtools-extension/developmentOnly");

var _reduxOidc = require("redux-oidc");

var _reduxSaga = _interopRequireDefault(require("redux-saga"));

var _UserManager = _interopRequireDefault(require("../oidc/UserManager"));

var _api = _interopRequireDefault(require("./api"));

var _reducers = _interopRequireDefault(require("./reducers"));

var _sagas = _interopRequireDefault(require("./sagas"));

var _clientWebsocketManager = _interopRequireDefault(require("./clientWebsocketManager"));

function configureStore(history) {
  let composeEnhancers = _redux.compose;

  if (process.env.NODE_ENV !== 'production') {
    composeEnhancers = (0, _developmentOnly.composeWithDevTools)({});
  }

  const sagaMiddleware = (0, _reduxSaga.default)();
  const store = (0, _redux.createStore)((0, _reducers.default)(history), {}, composeEnhancers((0, _redux.applyMiddleware)((0, _connectedReactRouter.routerMiddleware)(history), sagaMiddleware)));
  sagaMiddleware.run(_sagas.default);
  (0, _reduxOidc.loadUser)(store, _UserManager.default);
  new _clientWebsocketManager.default(store); // create axios interceptor to add the Access Token to every request header

  _api.default.interceptors.request.use(config => {
    const {
      user
    } = store.getState().oidc;

    if (user) {
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }

    return config;
  }, error => {
    return Promise.reject(error);
  });

  const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

  function isIsoDateString(value) {
    return value && typeof value === 'string' && isoDateFormat.test(value);
  }

  function handleDates(body) {
    if (body === null || body === undefined || typeof body !== 'object') return body;

    for (const key of Object.keys(body)) {
      const value = body[key];

      if (isIsoDateString(value)) {
        body[key] = (0, _moment.default)(value).toDate();
      } else if (typeof value === 'object') handleDates(value);
    }
  }

  _api.default.interceptors.response.use(originalResponse => {
    handleDates(originalResponse.data);
    return originalResponse;
  });

  return store;
}