"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getNotes = exports.selectNotes = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = _interopRequireDefault(require("../schema"));

const selectNotes = state => {
  return state.entities.notes;
};

exports.selectNotes = selectNotes;
const getNotes = (0, _reselect.createSelector)([selectNotes], notes => {
  if (notes && notes.allIds && notes.allIds.length > 0) {
    const {
      notes: denormalizedNotes
    } = (0, _normalizr.denormalize)({
      notes: notes.allIds
    }, _schema.default, {
      notes: notes.byId
    });
    return (0, _toArray.default)(denormalizedNotes);
  }

  return undefined;
});
exports.getNotes = getNotes;
var _default = {
  getNotes
};
exports.default = _default;