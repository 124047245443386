"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = appointmentsSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _appointments = require("./appointments.types");

const getAppointments = queryParams => {
  if (queryParams) {
    const {
      includeDeleted
    } = queryParams;
    return (0, _api.default)({
      method: 'get',
      url: `/a/appointment/?includeDeleted=${includeDeleted}`
    });
  }

  return (0, _api.default)({
    method: 'get',
    url: `/a/appointment/`
  });
};

const createAppointment = appointment => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/appointment/create`,
    data: appointment
  });
};

const updateAppointment = appointment => {
  const {
    id
  } = appointment;
  return (0, _api.default)({
    method: 'put',
    url: `/a/appointment/${id}/update`,
    data: appointment
  });
};

const deleteAppointment = id => {
  return (0, _api.default)({
    method: 'delete',
    url: `/a/appointment/appointment/${id}`
  });
};

let lastGetAppointmentsParam;

function* refreshAppointmentsHandler() {
  yield (0, _effects.put)(_appointments.getAppointmentsAsync.request(lastGetAppointmentsParam));
}

function* getAppointmentsHandler(action) {
  try {
    lastGetAppointmentsParam = action.payload;
    const response = yield (0, _effects.call)(getAppointments, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.appointments);
    const {
      appointments
    } = entities;
    yield (0, _effects.put)(_appointments.getAppointmentsAsync.success(appointments));
  } catch (error) {
    yield (0, _effects.put)(_appointments.getAppointmentsAsync.failure(error));
  }
}

function* createAppointmentHandler(action) {
  try {
    const response = yield (0, _effects.call)(createAppointment, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.appointments);
    const {
      appointments
    } = entities;
    yield (0, _effects.put)(_appointments.createAppointmentAsync.success(appointments));
    yield (0, _effects.call)(refreshAppointmentsHandler);
  } catch (error) {
    yield (0, _effects.put)(_appointments.createAppointmentAsync.failure(error));
  }
}

function* deleteAppointmentHandler(action) {
  try {
    const response = yield (0, _effects.call)(deleteAppointment, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.appointments);
    const {
      appointments
    } = entities;
    yield (0, _effects.put)(_appointments.deleteAppointmentAsync.success(appointments));
    yield (0, _effects.call)(refreshAppointmentsHandler);
  } catch (error) {
    yield (0, _effects.put)(_appointments.deleteAppointmentAsync.failure(error));
  }
}

function* updateAppointmentHandler(action) {
  try {
    const response = yield (0, _effects.call)(updateAppointment, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.appointments);
    const {
      appointments
    } = entities;
    yield (0, _effects.put)(_appointments.updateAppointmentAsync.success(appointments));
    yield (0, _effects.call)(refreshAppointmentsHandler);
  } catch (error) {
    yield (0, _effects.put)(_appointments.updateAppointmentAsync.failure(error));
  }
}

function* getAppointmentsWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_appointments.getAppointmentsAsync.request), getAppointmentsHandler);
}

function* createAppointmentWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_appointments.createAppointmentAsync.request), createAppointmentHandler);
}

function* updateAppointmentWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_appointments.updateAppointmentAsync.request), updateAppointmentHandler);
}

function* deleteAppointmentWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_appointments.deleteAppointmentAsync.request), deleteAppointmentHandler);
}

function* appointmentsSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getAppointmentsWatcher), (0, _effects.fork)(createAppointmentWatcher), (0, _effects.fork)(updateAppointmentWatcher), (0, _effects.fork)(deleteAppointmentWatcher)]);
}