"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

class FeatureNames {} //these should match the feature table and StudyDb


exports.default = FeatureNames;
FeatureNames.FORUM = 'FORUM';
FeatureNames.FORMS = 'FORMS';
FeatureNames.ACTIVITIES = 'ACTIVITIES';
FeatureNames.PEER_REFERRALS = 'PEER_REFERRALS';
FeatureNames.MEDTRACKER = 'MEDTRACKER';
FeatureNames.BADGES = 'BADGES';
FeatureNames.QNA = 'QNA';
FeatureNames.FOLLOW_PARTICIPANT = 'FOLLOW_PARTICIPANT';
FeatureNames.TEST_KITS = 'TEST_KITS';
FeatureNames.INCENTIVES = 'INCENTIVES';
FeatureNames.ACCESS_CODE_ENROLLMENT = 'ACCESS_CODE_ENROLLMENT';
FeatureNames.FORUM_POST_PUBLISH_DATE_MUTABLE = 'FORUM_POST_PUBLISH_DATE_MUTABLE';
FeatureNames.AVATAR_CUSTOM = 'AVATAR_CUSTOM';
FeatureNames.GOAL_SETTING = 'GOAL_SETTING';
FeatureNames.SURVEYS = 'SURVEYS';
FeatureNames.EXIT_INTERVIEW = 'EXIT_INTERVIEW';
FeatureNames.APPOINTMENTS = 'APPOINTMENTS';
FeatureNames.SHOW_DOB = 'SHOW_DOB';
FeatureNames.SHOW_GENDER_IDENTITY = 'SHOW_GENDER_IDENTITY';