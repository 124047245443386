"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = goalsSaga;

var _normalizr = require("normalizr");

var _lodash = _interopRequireDefault(require("lodash"));

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _goals = require("./goals.types");

const getGoals = args => {
  const {
    query,
    pageNumber,
    pageSize,
    includeDeleted = true,
    includeUnpublished = true
  } = args;
  let url = `/a/goal/goals?includeDeleted=${includeDeleted}&includeUnpublished=${includeUnpublished}`;

  if (query && !!query.length) {
    url += `&query=${query}`;
  }

  if (_lodash.default.isNumber(pageNumber) && _lodash.default.isNumber(pageSize)) {
    url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  return (0, _api.default)({
    method: "get",
    url
  });
};

const getGoal = id => {
  return (0, _api.default)({
    method: "get",
    url: `/a/goal/${id}`
  });
};

const createGoal = goal => {
  return (0, _api.default)({
    method: "put",
    url: `/a/goal/create`,
    data: goal
  });
};

const updateGoal = goal => {
  const {
    id
  } = goal;
  return (0, _api.default)({
    method: "put",
    url: `/a/goal/${id}/update`,
    data: goal
  });
};

const deleteGoal = id => {
  return (0, _api.default)({
    method: "delete",
    url: `/a/goal/${id}/delete`
  });
};

function* getGoalsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getGoals, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.goals);
    const {
      goals
    } = entities;
    yield (0, _effects.put)(_goals.getGoalsAsync.success(goals));
  } catch (error) {
    yield (0, _effects.put)(_goals.getGoalsAsync.failure(error));
  }
}

function* createGoalHandler(action) {
  try {
    const response = yield (0, _effects.call)(createGoal, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.goals);
    const {
      goals
    } = entities;
    yield (0, _effects.put)(_goals.createGoalAsync.success(goals));
  } catch (error) {
    yield (0, _effects.put)(_goals.createGoalAsync.failure(error));
  }
}

function* getGoalHandler(action) {
  try {
    const response = yield (0, _effects.call)(getGoal, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.goals);
    const {
      goals
    } = entities;
    yield (0, _effects.put)(_goals.getGoalAsync.success(goals));
  } catch (error) {
    yield (0, _effects.put)(_goals.getGoalAsync.failure(error));
  }
}

function* updateGoalHandler(action) {
  try {
    const response = yield (0, _effects.call)(updateGoal, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.goals);
    const {
      goals
    } = entities;
    yield (0, _effects.put)(_goals.updateGoalAsync.success(goals));
  } catch (error) {
    yield (0, _effects.put)(_goals.updateGoalAsync.failure(error));
  }
}

function* deleteGoalHandler(action) {
  try {
    const response = yield (0, _effects.call)(deleteGoal, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.goals);
    const {
      goals
    } = entities;
    yield (0, _effects.put)(_goals.deleteGoalAsync.success(goals));
  } catch (error) {
    yield (0, _effects.put)(_goals.deleteGoalAsync.failure(error));
  }
}

function* getGoalsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_goals.getGoalsAsync.request), getGoalsHandler);
}

function* getGoalWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_goals.getGoalAsync.request), getGoalHandler);
}

function* createGoalWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_goals.createGoalAsync.request), createGoalHandler);
}

function* updateGoalWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_goals.updateGoalAsync.request), updateGoalHandler);
}

function* deleteGoalWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_goals.deleteGoalAsync.request), deleteGoalHandler);
}

function* goalsSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getGoalsWatcher), (0, _effects.fork)(getGoalWatcher), (0, _effects.fork)(createGoalWatcher), (0, _effects.fork)(updateGoalWatcher), (0, _effects.fork)(deleteGoalWatcher)]);
}