"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getFlaggedPosts = exports.getUncategorizedPosts = exports.getLastSearchResultPosts = exports.selectRecentActivityPostIds = exports.getPosts = exports.selectPosts = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _forum = require("../forum/forum.selectors");

var _participants = require("../participants/participants.selectors");

var _schema = _interopRequireDefault(require("../schema"));

const selectPosts = state => {
  return state.entities.posts;
};

exports.selectPosts = selectPosts;
const getPosts = (0, _reselect.createSelector)([selectPosts], posts => {
  if (posts && posts.allIds && posts.allIds.length > 0) {
    const {
      posts: denormalizedPosts
    } = (0, _normalizr.denormalize)({
      posts: posts.allIds
    }, _schema.default, {
      posts: posts.byId
    });
    return (0, _toArray.default)(denormalizedPosts);
  }

  return undefined;
});
exports.getPosts = getPosts;

const selectRecentActivityPostIds = state => {
  return state.ui.forums.recentActivityPostIds;
};

exports.selectRecentActivityPostIds = selectRecentActivityPostIds;
const getLastSearchResultPosts = (0, _reselect.createSelector)([selectPosts, selectRecentActivityPostIds, _participants.selectParticipants], (posts, recentActivityPostIds, participants) => {
  if (posts && posts.allIds && posts.allIds.length > 0) {
    const {
      posts: denormalizedPosts
    } = (0, _normalizr.denormalize)({
      posts: recentActivityPostIds
    }, _schema.default, {
      posts: posts.byId,
      participants: participants.byId
    });
    return (0, _toArray.default)(denormalizedPosts);
  }

  return undefined;
});
exports.getLastSearchResultPosts = getLastSearchResultPosts;
const getUncategorizedPosts = (0, _reselect.createSelector)([selectPosts, _forum.selectForumUncategorizedPostIds, _participants.selectParticipants], (posts, uncategorizedPostIds, participants) => {
  if ((posts === null || posts === void 0 ? void 0 : posts.allIds) && (uncategorizedPostIds === null || uncategorizedPostIds === void 0 ? void 0 : uncategorizedPostIds.length)) {
    const {
      posts: denormalizedPosts
    } = (0, _normalizr.denormalize)({
      posts: uncategorizedPostIds
    }, _schema.default, {
      posts: posts.byId,
      participants: participants.byId
    });

    for (let i = 0; i < denormalizedPosts.length; i++) {
      // if any element didn't normalize we're probably in the middle of a saga
      if (!denormalizedPosts[i]) return undefined;
    }

    return (0, _toArray.default)(denormalizedPosts);
  }

  return undefined;
});
exports.getUncategorizedPosts = getUncategorizedPosts;
const getFlaggedPosts = (0, _reselect.createSelector)([selectPosts, _forum.selectFlaggedPostIds, _participants.selectParticipants], (posts, flaggedPostIds, participants) => {
  if ((posts === null || posts === void 0 ? void 0 : posts.allIds) && (flaggedPostIds === null || flaggedPostIds === void 0 ? void 0 : flaggedPostIds.length)) {
    const {
      posts: denormalizedPosts
    } = (0, _normalizr.denormalize)({
      posts: flaggedPostIds
    }, _schema.default, {
      posts: posts.byId,
      participants: participants.byId
    });
    return (0, _toArray.default)(denormalizedPosts);
  }

  return undefined;
});
exports.getFlaggedPosts = getFlaggedPosts;
var _default = {
  getPosts,
  selectRecentActivityPostIds
};
exports.default = _default;