"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateParticipantsHandler = updateParticipantsHandler;
exports.default = participantsSaga;

var _normalizr = require("normalizr");

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _notification = require("../notification/notification.types");

var _payment = require("../payment/payment.types");

var _schema = _interopRequireDefault(require("../schema"));

var _testkit = require("../testkit/testkit.types");

var _userSurvey = require("../userSurvey/userSurvey.types");

var _participants = require("./participants.types");

var _posts = require("../posts/posts.types");

var selectors = _interopRequireWildcard(require("../selectors"));

var _comments = require("../comments/comments.types");

var _messages = require("../messages/messages.types");

var _form = require("../form/form.types");

var _notes = require("../notes/notes.types");

var _badges = require("../badges/badges.types");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function* updateParticipantsHandler(participants) {
  if (participants) {
    yield (0, _effects.put)((0, _participants.updateParticipants)(participants));
  }
}

const getParticipant = participantId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/participant/${participantId}`
  });
};

function* loadParticipantHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(getParticipant, participantId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.default.participants);
    const {
      participants
    } = entities;
    yield (0, _effects.call)(updateParticipantsHandler, participants);
    yield (0, _effects.call)(getParticipantNotes, participantId);
    yield (0, _effects.call)(getParticipantPosts, participantId);
    yield (0, _effects.call)(getParticipantComments, participantId);
    yield (0, _effects.call)(getParticipantThreads, participantId);
    yield (0, _effects.call)(getParticipantForms, participantId);
    yield (0, _effects.call)(getParticipantNotifications, participantId);
    yield (0, _effects.call)(getParticipantUserSurveys, participantId);
    yield (0, _effects.call)(getParticipantTestkits, participantId);
    yield (0, _effects.call)(getParticipantPayments, participantId);
    yield (0, _effects.put)(_badges.getBadgeCollectionsAsync.request());
    yield (0, _effects.put)(_badges.getParticipantBadgesAsync.request(participantId));
    yield (0, _effects.put)(_participants.loadParticipantAsync.success());
  } catch (error) {
    yield (0, _effects.put)(_participants.loadParticipantAsync.failure(error));
  }
}

function* getParticipantNotes(participantId) {
  yield (0, _effects.put)(_notes.getParticipantNotesAsync.request(participantId));
}

function* getParticipantPosts(participantId) {
  yield (0, _effects.put)(_posts.getParticipantPostsAsync.request(participantId));
}

function* getParticipantComments(participantId) {
  yield (0, _effects.put)(_comments.getParticipantCommentsAsync.request(participantId));
}

function* getParticipantThreads(participantId) {
  yield (0, _effects.put)(_messages.getParticipantThreadsAsync.request(participantId));
}

function* getParticipantNotifications(participantId) {
  yield (0, _effects.put)(_notification.getParticipantNotificationsAsync.request(participantId));
}

function* getParticipantTestkits(participantId) {
  yield (0, _effects.put)(_testkit.getParticipantsTestkitsAsync.request(participantId));
}

function* getParticipantPayments(participantId) {
  yield (0, _effects.put)(_payment.getParticipantPaymentsAsync.request(participantId));
}

function* getParticipantBadges(participantId) {
  yield (0, _effects.put)(_badges.getParticipantBadgesAsync.request(participantId));
}

function* getParticipantForms(participantId) {
  yield (0, _effects.put)(_form.getParticipantFormsAsync.request(participantId));
}

function* getParticipantUserSurveys(participantId) {
  yield (0, _effects.put)(_userSurvey.getParticipantUserSurveysAsync.request(participantId));
}

function* loadParticipantWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_participants.loadParticipantAsync.request), loadParticipantHandler);
}

function* loadRequestedParticipantHandler(action) {
  try {
    const requestedParticipantId = yield (0, _effects.select)(selectors.getRequestedParticipantId);
    const response = yield (0, _effects.call)(getParticipant, requestedParticipantId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.default.participants);
    const {
      participants
    } = entities;
    yield (0, _effects.call)(updateParticipantsHandler, participants);
    yield (0, _effects.call)(getParticipantNotes, requestedParticipantId);
    yield (0, _effects.call)(getParticipantPosts, requestedParticipantId);
    yield (0, _effects.call)(getParticipantComments, requestedParticipantId);
    yield (0, _effects.call)(getParticipantThreads, requestedParticipantId);
    yield (0, _effects.call)(getParticipantForms, requestedParticipantId);
    yield (0, _effects.call)(getParticipantNotifications, requestedParticipantId);
    yield (0, _effects.call)(getParticipantUserSurveys, requestedParticipantId);
    yield (0, _effects.call)(getParticipantTestkits, requestedParticipantId);
    yield (0, _effects.call)(getParticipantPayments, requestedParticipantId);
    yield (0, _effects.call)(getParticipantBadges, requestedParticipantId);
    yield (0, _effects.put)(_participants.loadRequestedParticipantAsync.success());
  } catch (error) {
    yield (0, _effects.put)(_participants.loadRequestedParticipantAsync.failure(error));
  }
}

function* loadRequestedParticipantWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_participants.loadRequestedParticipantAsync.request), loadRequestedParticipantHandler);
}

const saveParticipant = participant => {
  const data = (0, _cloneDeep.default)(participant);
  return (0, _api.default)({
    method: 'put',
    url: '/a/participant',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  });
};

function* saveParticipantHandler(action) {
  try {
    const participant = action.payload;
    const response = yield (0, _effects.call)(saveParticipant, participant);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.default.participants);
    const {
      participants
    } = entities;
    yield (0, _effects.put)(_participants.saveParticipantAsync.success(participants));
  } catch (error) {
    yield (0, _effects.put)(_participants.saveParticipantAsync.failure(error));
  }
}

function* saveParticipantWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_participants.saveParticipantAsync.request), saveParticipantHandler);
}

const saveParticipantActualHivStatus = (participantId, status) => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/participant/hivStatus/${participantId}/${status}`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

function* saveParticipantActualHivStatusHandler(action) {
  try {
    const {
      participantId,
      status
    } = action.payload;
    const response = yield (0, _effects.call)(saveParticipantActualHivStatus, participantId, status);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.default.participants);
    const {
      participants
    } = entities;
    yield (0, _effects.put)(_participants.saveParticipantActualHivStatusAsync.success(participants));
  } catch (error) {
    yield (0, _effects.put)(_participants.saveParticipantActualHivStatusAsync.failure(error));
  }
}

function* saveParticipantActualHivStatusWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_participants.saveParticipantActualHivStatusAsync.request), saveParticipantActualHivStatusHandler);
}

const saveParticipantInterviewSelected = (participantId, interviewSelected) => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/participant/interviewSelected/${participantId}/${interviewSelected.toString()}`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

function* saveParticipantInterviewSelectedHandler(action) {
  try {
    const {
      participantId,
      interviewSelected
    } = action.payload;
    const response = yield (0, _effects.call)(saveParticipantInterviewSelected, participantId, interviewSelected);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.default.participants);
    const {
      participants
    } = entities;
    yield (0, _effects.put)(_participants.saveParticipantInterviewSelectedAsync.success(participants));
  } catch (error) {
    yield (0, _effects.put)(_participants.saveParticipantInterviewSelectedAsync.failure(error));
  }
}

function* saveParticipantInterviewSelectedWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_participants.saveParticipantInterviewSelectedAsync.request), saveParticipantInterviewSelectedHandler);
}

const getParticipantsByStudyArm = studyArmId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/participant/participantsByStudyArm/${studyArmId}`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

function* getParticipantsByStudyArmHandler(action) {
  try {
    const response = yield (0, _effects.call)(getParticipantsByStudyArm, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.default.participants);
    const {
      participants
    } = entities;
    yield (0, _effects.put)(_participants.getParticipantsByStudyArmAsync.success(participants));
  } catch (error) {
    yield (0, _effects.put)(_participants.getParticipantsByStudyArmAsync.failure(error));
  }
}

function* getParticipantsByStudyArmWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_participants.getParticipantsByStudyArmAsync.request), getParticipantsByStudyArmHandler);
}

const getParticipantsByStudy = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/participant/participantsByStudy/${studyId}`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

function* getParticipantsByStudyHandler(action) {
  try {
    const response = yield (0, _effects.call)(getParticipantsByStudy, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.default.participants);
    const {
      participants
    } = entities;
    yield (0, _effects.put)(_participants.getParticipantsByStudyAsync.success(participants));
  } catch (error) {
    yield (0, _effects.put)(_participants.getParticipantsByStudyAsync.failure(error));
  }
}

function* getParticipantsByStudyWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_participants.getParticipantsByStudyAsync.request), getParticipantsByStudyHandler);
}

function* participantsSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(loadParticipantWatcher), (0, _effects.fork)(loadRequestedParticipantWatcher), (0, _effects.fork)(saveParticipantWatcher), (0, _effects.fork)(saveParticipantActualHivStatusWatcher), (0, _effects.fork)(saveParticipantInterviewSelectedWatcher), (0, _effects.fork)(getParticipantsByStudyArmWatcher), (0, _effects.fork)(getParticipantsByStudyWatcher)]);
}