"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadDashboardDataAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const loadDashboardDataAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_DASHBOARD_REQUEST', 'hmp/admin/LOAD_DASHBOARD_SUCCESS', 'hmp/admin/LOAD_DASHBOARD_FAILURE')();
exports.loadDashboardDataAsync = loadDashboardDataAsync;
const dashboardActions = {
  loadDashboardDataAsync
};