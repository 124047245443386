"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStudyArms = exports.updateStudies = exports.loadStudyStatsAsync = exports.loadStudyAsync = exports.loadStudiesAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const loadStudiesAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_STUDIES_REQUEST', 'hmp/admin/LOAD_STUDIES_SUCCESS', 'hmp/admin/LOAD_STUDIES_FAILURE')();
exports.loadStudiesAsync = loadStudiesAsync;
const loadStudyAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_STUDY_REQUEST', 'hmp/admin/LOAD_STUDY_SUCCESS', 'hmp/admin/LOAD_STUDY_FAILURE')();
exports.loadStudyAsync = loadStudyAsync;
const loadStudyStatsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_STUDY_STATS_REQUEST', 'hmp/admin/LOAD_STUDY_STATS_SUCCESS', 'hmp/admin/LOAD_STUDY_STATS_FAILURE')();
exports.loadStudyStatsAsync = loadStudyStatsAsync;
const updateStudies = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_STUDIES')();
exports.updateStudies = updateStudies;
const updateStudyArms = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_STUDY_ARMS')();
exports.updateStudyArms = updateStudyArms;
const studyActions = {
  loadStudiesAsync,
  loadStudyAsync,
  loadStudyStatsAsync,
  updateStudies,
  updateStudyArms
};