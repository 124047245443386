"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getQnaPostCount = exports.getQnaPosts = exports.selectQnaPosts = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = _interopRequireDefault(require("../schema"));

var _flags = require("../flags/flags.selectors");

var _thumbsups = require("../thumbsups/thumbsups.selectors");

var _comments = require("../comments/comments.selectors");

var _favorites = require("../favorites/favorites.selectors");

var _user = require("../user/user.selectors");

const selectQnaPosts = state => {
  return state.entities.qnaPosts;
};

exports.selectQnaPosts = selectQnaPosts;
const getQnaPosts = (0, _reselect.createSelector)([selectQnaPosts, _flags.getCommentFlags, _thumbsups.getQnaThumbsups, _comments.getQnaComments, _favorites.getQnaFavorites, _user.getAdmin], (posts, flags, thumbsups, comments, favorites, admins) => {
  if (posts && posts.allIds && posts.allIds.length > 0) {
    const {
      qnaPosts: denormalizedQnaPosts
    } = (0, _normalizr.denormalize)({
      qnaPosts: posts.allIds
    }, _schema.default, {
      qnaPosts: posts.byId
    });
    const mappedQnaPosts = (0, _toArray.default)(denormalizedQnaPosts).map(post => {
      if (thumbsups) {
        post.thumbsups = thumbsups ? thumbsups.filter(t => t.typeId === post.id) : [];
      }

      if (comments) {
        post.comments = comments.filter(c => c.typeId === post.id).map(c => {
          c.flags = flags ? flags.filter(f => f.typeId === c.id) : [];
          return c;
        });
      }

      if (favorites) {
        post.favorites = favorites ? favorites.filter(f => f.typeId === post.id) : [];
      }

      return post;
    });
    return mappedQnaPosts;
  }

  return undefined;
});
exports.getQnaPosts = getQnaPosts;

const getQnaPostCount = state => {
  return state.ui.qnas.postCount;
};

exports.getQnaPostCount = getQnaPostCount;
var _default = {
  getQnaPosts
};
exports.default = _default;