"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearPosts = exports.updatePosts = exports.deletePostAsync = exports.savePostAsync = exports.getFlaggedPostsAsync = exports.getUncategorizedPostsAsync = exports.getPostsAsync = exports.initialGetPostsSearch = exports.getParticipantPostsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getParticipantPostsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_PARTICIPANT_POSTS_REQUEST', 'hmp/admin/GET_PARTICIPANT_POSTS_SUCCESS', 'hmp/admin/GET_PARTICIPANT_POSTS_FAILURE')();
exports.getParticipantPostsAsync = getParticipantPostsAsync;
const initialGetPostsSearch = {
  searchText: undefined,
  participantId: undefined,
  studyArmIds: [],
  pageNumber: 0,
  pageSize: 10,
  sortBy: 'createDate',
  sortDirection: 'desc',
  previewLength: 100,
  includeDeleted: true,
  includeUnpublished: true
};
exports.initialGetPostsSearch = initialGetPostsSearch;
const getPostsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_STUDY_POSTS_REQUEST', 'hmp/admin/GET_STUDY_POSTS_SUCCESS', 'hmp/admin/GET_STUDY_POSTS_FAILURE')();
exports.getPostsAsync = getPostsAsync;
const getUncategorizedPostsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_UNCATEGORIZED_POSTS_REQUEST', 'hmp/admin/GET_UNCATEGORIZED_POSTS_SUCCESS', 'hmp/admin/GET_UNCATEGORIZED_POSTS_FAILURE')();
exports.getUncategorizedPostsAsync = getUncategorizedPostsAsync;
const getFlaggedPostsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_FLAGGED_POSTS_REQUEST', 'hmp/admin/GET_FLAGGED_POSTS_SUCCESS', 'hmp/admin/GET_FLAGGED_POSTS_FAILURE')();
exports.getFlaggedPostsAsync = getFlaggedPostsAsync;
const savePostAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/SAVE_POST_REQUEST', 'hmp/admin/SAVE_POST_SUCCESS', 'hmp/admin/SAVE_POST_FAILURE')();
exports.savePostAsync = savePostAsync;
const deletePostAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/DELETE_POST_REQUEST', 'hmp/admin/DELETE_POST_SUCCESS', 'hmp/admin/DELETE_POST_FAILURE')();
exports.deletePostAsync = deletePostAsync;
const updatePosts = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_POSTS')();
exports.updatePosts = updatePosts;
const clearPosts = (0, _typesafeActions.createStandardAction)('hmp/admin/CLEAR_POSTS')();
exports.clearPosts = clearPosts;
const postsActions = {
  getParticipantPostsAsync,
  getPostsAsync,
  getUncategorizedPostsAsync,
  getFlaggedPostsAsync,
  updatePosts,
  savePostAsync,
  deletePostAsync,
  clearPosts
};