"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = postsSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _comments = require("../comments/comments.types");

var _dashboard = require("../dashboard/dashboard.types");

var _favorites = require("../favorites/favorites.types");

var _flags = require("../flags/flags.types");

var _forum = require("../forum/forum.types");

var _schema = require("../schema");

var _thumbsups = require("../thumbsups/thumbsups.types");

var _socialUtil = require("../user/socialUtil");

var _posts = require("./posts.types");

let lastPostParams;

const getFlaggedPosts = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/forum/flagged`
  });
};

const savePost = post => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/forum/post`,
    data: post
  });
};

const getPosts = postsSearch => {
  const url = `/a/forum/posts`;
  return (0, _api.default)({
    method: 'post',
    url,
    data: postsSearch
  });
};

const getParticipantPosts = participantId => {
  return (0, _api.default)({
    method: 'post',
    url: `/a/forum/posts`,
    data: {
      participantId
    }
  });
};

const getUncategorizedPosts = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/forum/uncategorized/1`
  });
};

const deletePost = id => {
  return (0, _api.default)({
    method: 'delete',
    url: `/a/forum/post/${id}`
  });
};

function* refreshPostsHandler() {
  const refreshPostsParams = { ...lastPostParams,
    pageNumber: 0,
    pageSize: (lastPostParams.pageNumber + 1) * lastPostParams.pageSize
  };
  yield (0, _effects.put)(_posts.getPostsAsync.request(refreshPostsParams));
}

function* getPostsHandler(action) {
  try {
    lastPostParams = action.payload;
    const postsSearch = action.payload;
    const response = yield (0, _effects.call)(getPosts, postsSearch);
    const participantPosts = response.data ? response.data : [];
    const {
      entities,
      result
    } = (0, _normalizr.normalize)(participantPosts, _schema.entitySchema.posts);
    const {
      posts
    } = entities;

    if (result === null || result === void 0 ? void 0 : result.length) {
      const jsonParam = [(0, _socialUtil.createJsonParam)('post', result)];
      yield (0, _effects.put)(_flags.getFlagsAsync.request(jsonParam));
      yield (0, _effects.put)(_comments.getCommentsAsync.request(jsonParam));
      yield (0, _effects.put)(_thumbsups.getThumbsupsAsync.request(jsonParam));
      yield (0, _effects.put)(_favorites.getFavoritesAsync.request(jsonParam));
    }

    yield (0, _effects.put)(_posts.getPostsAsync.success(posts));

    if (lastPostParams.pageNumber === 0) {
      // reset the activity post ids so that duplicates are not added
      yield (0, _effects.put)((0, _forum.resetRecentActivityPostIds)());
    }

    yield (0, _effects.put)((0, _forum.updateRecentActivityPostIds)(result));
  } catch (error) {
    yield (0, _effects.put)(_posts.getPostsAsync.failure(error));
  }
}

function* getPostsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_posts.getPostsAsync.request), getPostsHandler);
}

function* getParticipantPostsHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(getParticipantPosts, participantId);
    const {
      entities,
      result
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.posts);
    const {
      posts
    } = entities;

    if (result === null || result === void 0 ? void 0 : result.length) {
      const jsonParam = [(0, _socialUtil.createJsonParam)('post', result)];
      yield (0, _effects.put)(_flags.getFlagsAsync.request(jsonParam));
      yield (0, _effects.put)(_comments.getCommentsAsync.request(jsonParam));
      yield (0, _effects.put)(_thumbsups.getThumbsupsAsync.request(jsonParam));
      yield (0, _effects.put)(_favorites.getFavoritesAsync.request(jsonParam));
    }

    yield (0, _effects.put)(_posts.getParticipantPostsAsync.success(posts));
  } catch (error) {
    yield (0, _effects.put)(_posts.getParticipantPostsAsync.failure(error));
  }
}

function* getParticipantPostsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_posts.getParticipantPostsAsync.request), getParticipantPostsHandler);
}

function* getUncategorizedPostsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getUncategorizedPosts);
    const {
      entities,
      result
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.posts);
    const {
      posts
    } = entities;

    if (result === null || result === void 0 ? void 0 : result.length) {
      const jsonParam = [(0, _socialUtil.createJsonParam)('post', result)];
      yield (0, _effects.put)(_flags.getFlagsAsync.request(jsonParam));
      yield (0, _effects.put)(_comments.getCommentsAsync.request(jsonParam));
      yield (0, _effects.put)(_thumbsups.getThumbsupsAsync.request(jsonParam));
      yield (0, _effects.put)(_favorites.getFavoritesAsync.request(jsonParam));
    }

    yield (0, _effects.put)((0, _forum.updateUncategorizedPostIds)(result));
    yield (0, _effects.put)(_posts.getUncategorizedPostsAsync.success(posts));
  } catch (error) {
    yield (0, _effects.put)(_posts.getUncategorizedPostsAsync.failure(error));
  }
}

function* savePostHandler(action) {
  try {
    const {
      payload: post
    } = action;
    const response = yield (0, _effects.call)(savePost, post);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.posts);
    const {
      posts
    } = entities;
    yield (0, _effects.put)(_posts.savePostAsync.success(posts));
    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.request()); // yield put(getUncategorizedPostsAsync.request());

    yield (0, _effects.call)(refreshPostsHandler);
  } catch (error) {
    yield (0, _effects.put)(_posts.savePostAsync.failure(error));
  }
}

function* getFlaggedPostsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getFlaggedPosts);
    const {
      entities,
      result
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.posts);
    const {
      posts
    } = entities;

    if (result === null || result === void 0 ? void 0 : result.length) {
      const jsonParam = [(0, _socialUtil.createJsonParam)('post', result)];
      yield (0, _effects.put)(_flags.getFlagsAsync.request(jsonParam));
      yield (0, _effects.put)(_comments.getCommentsAsync.request(jsonParam));
      yield (0, _effects.put)(_thumbsups.getThumbsupsAsync.request(jsonParam));
      yield (0, _effects.put)(_favorites.getFavoritesAsync.request(jsonParam));
    }

    yield (0, _effects.put)(_posts.getFlaggedPostsAsync.success(posts));
    yield (0, _effects.put)((0, _forum.updateFlaggedPostIds)(result));
  } catch (error) {
    yield (0, _effects.put)(_posts.getFlaggedPostsAsync.failure(error));
  }
}

function* deletePostHandler(action) {
  try {
    const response = yield (0, _effects.call)(deletePost, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.posts);
    const {
      posts
    } = entities;
    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.request());
    yield (0, _effects.put)(_posts.getUncategorizedPostsAsync.request());
    yield (0, _effects.put)(_posts.deletePostAsync.success(posts));
  } catch (error) {
    yield (0, _effects.put)(_posts.deletePostAsync.failure(error));
  }
}

function* getUncategorizedPostsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_posts.getUncategorizedPostsAsync.request), getUncategorizedPostsHandler);
}

function* savePostWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_posts.savePostAsync.request), savePostHandler);
}

function* getFlaggedPostsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_posts.getFlaggedPostsAsync.request), getFlaggedPostsHandler);
}

function* deletePostWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_posts.deletePostAsync.request), deletePostHandler);
}

function* postsSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getParticipantPostsWatcher), (0, _effects.fork)(getPostsWatcher), (0, _effects.fork)(getUncategorizedPostsWatcher), (0, _effects.fork)(savePostWatcher), (0, _effects.fork)(getFlaggedPostsWatcher), (0, _effects.fork)(deletePostWatcher)]);
}