"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearQnaPosts = exports.updateQnaPosts = exports.getQnaPostCountAsync = exports.deleteQnaPostAsync = exports.updateQnaPostAsync = exports.createQnaPostAsync = exports.getFlaggedQnaContentAsync = exports.getQnaPostAsync = exports.getQnaPostsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getQnaPostsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_QNA_POSTS_REQUEST', 'hmp/admin/GET_QNA_POSTS_SUCCESS', 'hmp/admin/GET_QNA_POSTS_FAILURE')();
exports.getQnaPostsAsync = getQnaPostsAsync;
const getQnaPostAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_QNA_POST_REQUEST', 'hmp/admin/GET_QNA_POST_SUCCESS', 'hmp/admin/GET_QNA_POST_FAILURE')();
exports.getQnaPostAsync = getQnaPostAsync;
const getFlaggedQnaContentAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_FLAGGED_QNA_CONTENT_REQUEST', 'hmp/admin/GET_FLAGGED_QNA_CONTENT_SUCCESS', 'hmp/admin/GET_FLAGGED_QNA_CONTENT_FAILURE')();
exports.getFlaggedQnaContentAsync = getFlaggedQnaContentAsync;
const createQnaPostAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/CREATE_QNA_POST_REQUEST', 'hmp/admin/CREATE_QNA_POST_SUCCESS', 'hmp/admin/CREATE_QNA_POST_FAILURE')();
exports.createQnaPostAsync = createQnaPostAsync;
const updateQnaPostAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPDATE_QNA_POST_REQUEST', 'hmp/admin/UPDATE_QNA_POST_SUCCESS', 'hmp/admin/UPDATE_QNA_POST_FAILURE')();
exports.updateQnaPostAsync = updateQnaPostAsync;
const deleteQnaPostAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/DELETE_QNA_POST_REQUEST', 'hmp/admin/DELETE_QNA_POST_SUCCESS', 'hmp/admin/DELETE_QNA_POST_FAILURE')();
exports.deleteQnaPostAsync = deleteQnaPostAsync;
const getQnaPostCountAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_QNA_POST_COUNT_REQUEST', 'hmp/admin/GET_QNA_POST_COUNT_SUCCESS', 'hmp/admin/GET_QNA_POST_COUNT_FAILURE')();
exports.getQnaPostCountAsync = getQnaPostCountAsync;
const updateQnaPosts = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_QNA_POSTS')();
exports.updateQnaPosts = updateQnaPosts;
const clearQnaPosts = (0, _typesafeActions.createStandardAction)('hmp/admin/CLEAR_QNA_POSTS')();
exports.clearQnaPosts = clearQnaPosts;
const qnaActions = {
  getQnaPostsAsync,
  getQnaPostAsync,
  getFlaggedQnaContentAsync,
  createQnaPostAsync,
  updateQnaPostAsync,
  deleteQnaPostAsync,
  getQnaPostCountAsync,
  updateQnaPosts
};