"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTestkits = exports.reviewTestkitAsync = exports.fulfillTestkitAsync = exports.getCompletedTestkitsAsync = exports.getNeedsReviewTestkitsAsync = exports.getAwaitingResultTestkitsAsync = exports.getRequestedTestkitsAsync = exports.getParticipantsTestkitsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getParticipantsTestkitsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_PARTICIPANTS_TESTKITS_REQUEST', 'hmp/admin/GET_PARTICIPANTS_TESTKITS_SUCCESS', 'hmp/admin/GET_PARTICIPANTS_TESTKITS_FAILURE')();
exports.getParticipantsTestkitsAsync = getParticipantsTestkitsAsync;
const getRequestedTestkitsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_REQUESTED_TESTKITS_REQUEST', 'hmp/admin/GET_REQUESTED_TESTKITS_SUCCESS', 'hmp/admin/GET_REQUESTED_TESTKITS_FAILURE')();
exports.getRequestedTestkitsAsync = getRequestedTestkitsAsync;
const getAwaitingResultTestkitsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_AwaitingResult_TESTKITS_REQUEST', 'hmp/admin/GET_AwaitingResult_TESTKITS_SUCCESS', 'hmp/admin/GET_AwaitingResult_TESTKITS_FAILURE')();
exports.getAwaitingResultTestkitsAsync = getAwaitingResultTestkitsAsync;
const getNeedsReviewTestkitsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_NeedsReview_TESTKITS_REQUEST', 'hmp/admin/GET_NeedsReview_TESTKITS_SUCCESS', 'hmp/admin/GET_NeedsReview_TESTKITS_FAILURE')();
exports.getNeedsReviewTestkitsAsync = getNeedsReviewTestkitsAsync;
const getCompletedTestkitsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_COMPLETED_TESTKITS_REQUEST', 'hmp/admin/GET_COMPLETED_TESTKITS_SUCCESS', 'hmp/admin/GET_COMPLETED_TESTKITS_FAILURE')();
exports.getCompletedTestkitsAsync = getCompletedTestkitsAsync;
const fulfillTestkitAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/FULFILL_TESTKIT_REQUEST', 'hmp/admin/FULFILL_TESTKIT_SUCCESS', 'hmp/admin/FULFILL_TESTKIT_FAILURE')();
exports.fulfillTestkitAsync = fulfillTestkitAsync;
const reviewTestkitAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/REVIEW_TESTKIT_REQUEST', 'hmp/admin/REVIEW_TESTKIT_SUCCESS', 'hmp/admin/REVIEW_TESTKIT_FAILURE')();
exports.reviewTestkitAsync = reviewTestkitAsync;
const updateTestkits = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_TESTKITS')();
exports.updateTestkits = updateTestkits;
const testkitActions = {
  getParticipantsTestkitsAsync,
  getRequestedTestkitsAsync,
  getAwaitingResultTestkitsAsync,
  getNeedsReviewTestkitsAsync,
  getCompletedTestkitsAsync,
  fulfillTestkitAsync,
  reviewTestkitAsync,
  updateTestkits
};