"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteAdminUserAsync = exports.updateAdminUserAsync = exports.updateAdminPasswordAsync = exports.getAdminRolesAsync = exports.createUserAsync = exports.getAdminAsync = exports.getUserAsync = exports.updateMobileAsync = exports.updateEmailAsync = exports.updatePasswordAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const updatePasswordAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPDATE_PASSWORD_REQUEST', 'hmp/admin/UPDATE_PASSWORD_SUCCESS', 'hmp/admin/UPDATE_PASSWORD_FAILURE')();
exports.updatePasswordAsync = updatePasswordAsync;
const updateEmailAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPDATE_EMAIL_REQUEST', 'hmp/admin/UPDATE_EMAIL_SUCCESS', 'hmp/admin/UPDATE_EMAIL_FAILURE')();
exports.updateEmailAsync = updateEmailAsync;
const updateMobileAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPDATE_PASSWORD_EMAIL_MOBILE_REQUEST', 'hmp/admin/UPDATE_PASSWORD_EMAIL_MOBILE_SUCCESS', 'hmp/admin/UPDATE_PASSWORD_EMAIL_MOBILE_FAILURE')();
exports.updateMobileAsync = updateMobileAsync;
const getUserAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/USER_REQUEST', 'hmp/admin/USER_SUCCESS', 'hmp/admin/USER_FAILURE')();
exports.getUserAsync = getUserAsync;
const getAdminAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_ADMIN_REQUEST', 'hmp/admin/GET_ADMIN_SUCCESS', 'hmp/admin/GET_ADMIN_FAILURE')();
exports.getAdminAsync = getAdminAsync;
const createUserAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/CREATE_USER_REQUEST', 'hmp/admin/CREATE_USER_SUCCESS', 'hmp/admin/CREATE_USER_FAILURE')();
exports.createUserAsync = createUserAsync;
const getAdminRolesAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_ADMIN_ROLES_REQUEST', 'hmp/admin/GET_ADMIN_ROLES_SUCCESS', 'hmp/admin/GET_ADMIN_ROLES_FAILURE')();
exports.getAdminRolesAsync = getAdminRolesAsync;
const updateAdminPasswordAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPDATE_ADMIN_PASSWORD_REQUEST', 'hmp/admin/UPDATE_ADMIN_PASSWORD_SUCCESS', 'hmp/admin/UPDATE_ADMIN_PASSWORD_FAILURE')();
exports.updateAdminPasswordAsync = updateAdminPasswordAsync;
const updateAdminUserAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPDATE_ADMIN_USER_REQUEST', 'hmp/admin/UPDATE_ADMIN_USER_SUCCESS', 'hmp/admin/UPDATE_ADMIN_USER_FAILURE')();
exports.updateAdminUserAsync = updateAdminUserAsync;
const deleteAdminUserAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/DELETE_ADMIN_USER_REQUEST', 'hmp/admin/DELETE_ADMIN_USER_SUCCESS', 'hmp/admin/DELETE_ADMIN_USER_FAILURE')();
exports.deleteAdminUserAsync = deleteAdminUserAsync;
const userActions = {
  getUserAsync,
  getAdminAsync,
  updatePasswordAsync,
  updateEmailAsync,
  updateMobileAsync,
  createUserAsync,
  getAdminRolesAsync,
  updateAdminPasswordAsync,
  updateAdminUserAsync,
  deleteAdminUserAsync
};