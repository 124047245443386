"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = favoriteSaga;

var _api = _interopRequireDefault(require("../api"));

var _effects = require("@redux-saga/core/effects");

var _normalizr = require("normalizr");

var _schema = require("../schema");

var _favorites = require("./favorites.types");

var _typesafeActions = require("typesafe-actions");

const getFavorites = jsonFavorites => {
  const param = JSON.stringify(jsonFavorites);
  return (0, _api.default)({
    method: 'get',
    url: `/a/social/favorites?favoritesJson=${encodeURIComponent(param)}`
  });
};

function* getFavoritesHandler(action) {
  try {
    const {
      payload
    } = action;
    const response = yield (0, _effects.call)(getFavorites, payload);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.favorites);
    const {
      favorites
    } = entities;
    yield (0, _effects.put)(_favorites.getFavoritesAsync.success(favorites));
  } catch (error) {
    yield (0, _effects.put)(_favorites.getFavoritesAsync.failure(error));
  }
}

function* getFavoritesWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_favorites.getFavoritesAsync.request), getFavoritesHandler);
}

function* favoriteSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getFavoritesWatcher)]);
}