"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getGoalTopics = exports.getResourceTopicsCascader = exports.getResourceTopics = exports.getQnaTopics = exports.getFlattenedForumTopics = exports.getForumTopics = exports.selectTopics = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _normalizr = require("normalizr");

var _reselect = require("reselect");

var _schema = require("../schema");

const selectTopics = state => {
  return state.entities.topics;
};

exports.selectTopics = selectTopics;
const getForumTopics = (0, _reselect.createSelector)([selectTopics], topics => {
  return filterByCollectionType(topics, "forum");
});
exports.getForumTopics = getForumTopics;
const getFlattenedForumTopics = (0, _reselect.createSelector)([selectTopics], topics => {
  return flatten(filterByCollectionType(topics, "forum"));
});
exports.getFlattenedForumTopics = getFlattenedForumTopics;
const getQnaTopics = (0, _reselect.createSelector)([selectTopics], topics => {
  return filterByCollectionType(topics, "qna");
});
exports.getQnaTopics = getQnaTopics;
const getResourceTopics = (0, _reselect.createSelector)([selectTopics], topics => {
  var _a;

  const updatedTopic = (_a = filterByCollectionType(topics, "knowledgecenter")) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
    var _a, _b;

    if (a === b) return 0;
    if (a === null || a.sortOrder === null) return 1;
    if (b === null || b.sortOrder === null) return -1;
    return ((_a = a.sortOrder) !== null && _a !== void 0 ? _a : 100000) > ((_b = b.sortOrder) !== null && _b !== void 0 ? _b : 10000) ? 1 : -1;
  });
  return updatedTopic;
});
exports.getResourceTopics = getResourceTopics;
const getResourceTopicsCascader = (0, _reselect.createSelector)([getResourceTopics], topics => {
  if (topics) {
    const options = topics.map(topic => {
      return {
        value: `${topic.id}`,
        label: topic.title,
        children: !topic.children ? [] : topic.children.map(child => {
          return {
            value: `${child.id}`,
            label: child.title
          };
        })
      };
    });
    return options;
  }

  return [];
});
exports.getResourceTopicsCascader = getResourceTopicsCascader;
const getGoalTopics = (0, _reselect.createSelector)([selectTopics], topics => {
  return filterByCollectionType(topics, "goal");
}); // stigma doesn't have deeply nested topics but our test data does and the model supports it so...

exports.getGoalTopics = getGoalTopics;

function flatten(topics) {
  if (!topics) return [];
  const flat = [];
  topics.forEach(topic => {
    flat.push(topic);

    if (topic.children && Array.isArray(topic.children) && topic.children.length > 0) {
      flat.push(...flatten(topic.children));
    }
  });
  return flat;
}

const filterByCollectionType = (topics, collectionType) => {
  if (topics && topics.allIds && topics.allIds.length > 0) {
    const {
      topics: denormalizedTopics
    } = (0, _normalizr.denormalize)({
      topics: topics.allIds
    }, _schema.entitySchema, {
      topics: topics.byId
    });
    return _lodash.default.toArray(denormalizedTopics).filter(topic => {
      return topic.collectionType === collectionType;
    });
  }

  return undefined;
};

var _default = {
  getForumTopics,
  getQnaTopics,
  getResourceTopics,
  getGoalTopics,
  selectTopics
};
exports.default = _default;