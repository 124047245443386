"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getTypes = exports.getCategories = exports.getActivities = exports.selectTypes = exports.selectCategories = exports.selectActivities = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = _interopRequireDefault(require("../schema"));

const selectActivities = state => {
  return state.entities.activities;
};

exports.selectActivities = selectActivities;

const selectCategories = state => {
  return state.entities.activityCategories;
};

exports.selectCategories = selectCategories;

const selectTypes = state => {
  return state.entities.activityTypes;
};

exports.selectTypes = selectTypes;
const getActivities = (0, _reselect.createSelector)([selectActivities], activities => {
  if (activities && activities.allIds && activities.allIds.length > 0) {
    const {
      activities: denormalizedActivities
    } = (0, _normalizr.denormalize)({
      activities: activities.allIds
    }, _schema.default, {
      activities: activities.byId
    });
    return (0, _toArray.default)(denormalizedActivities);
  }

  return undefined;
});
exports.getActivities = getActivities;
const getCategories = (0, _reselect.createSelector)([selectCategories], categories => {
  if (categories && categories.allIds && categories.allIds.length > 0) {
    const {
      activityCategories: denormalizedCategories
    } = (0, _normalizr.denormalize)({
      activityCategories: categories.allIds
    }, _schema.default, {
      activityCategories: categories.byId
    });
    return (0, _toArray.default)(denormalizedCategories);
  }

  return undefined;
});
exports.getCategories = getCategories;
const getTypes = (0, _reselect.createSelector)([selectTypes], types => {
  if (types && types.allIds && types.allIds.length > 0) {
    const {
      activityTypes: denormalizedTypes
    } = (0, _normalizr.denormalize)({
      activityTypes: types.allIds
    }, _schema.default, {
      activityTypes: types.byId
    });
    return (0, _toArray.default)(denormalizedTypes);
  }

  return undefined;
});
exports.getTypes = getTypes;
var _default = {
  getActivities
};
exports.default = _default;