"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = badgeSaga;

var _effects = require("redux-saga/effects");

var _normalizr = require("normalizr");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _badges = require("./badges.types");

const getBadgeCollections = () => {
  return (0, _api.default)({
    method: 'get',
    url: '/a/badge/collections'
  });
};

const getBadgeGroups = () => {
  return (0, _api.default)({
    method: 'get',
    url: '/a/badge/groups'
  });
};

const getBadgeCollectionTypes = () => {
  return (0, _api.default)({
    method: 'get',
    url: '/a/badge/collectionTypes'
  });
};

const deleteBadgeCollection = badgeCollectionId => {
  return (0, _api.default)({
    method: 'delete',
    url: `/a/badge/collections/${badgeCollectionId}`
  });
};

const deleteBadgeGroup = badgeGroupId => {
  return (0, _api.default)({
    method: 'delete',
    url: `/a/badge/groups/${badgeGroupId}`
  });
};

const undeleteBadgeGroup = badgeGroupId => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/badge/groups/undelete/${badgeGroupId}`
  });
};

const undeleteBadgeCollection = badgeCollectionId => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/badge/collections/undelete/${badgeCollectionId}`
  });
};

const saveBadgeGroup = badgeGroup => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/badge/groups`,
    data: badgeGroup
  });
};

const saveBadgeCollection = badgeCollection => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/badge/collections`,
    data: badgeCollection
  });
};

const getParticipantBadges = participantId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/badge/getBadgesForParticipant?participantId=${participantId}`
  });
};

const awardParticipantBadge = (participantId, badgeCollectionId) => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/badge/awardBadgeToParticipant/${badgeCollectionId}/${participantId}`
  });
};

function* getBadgeCollectionsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getBadgeCollections);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.badgeCollections);
    const {
      badgeCollections
    } = entities;
    yield (0, _effects.put)(_badges.getBadgeCollectionsAsync.success(badgeCollections));
  } catch (error) {
    yield (0, _effects.put)(_badges.getBadgeCollectionsAsync.failure(error));
  }
}

function* getBadgeGroupsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getBadgeGroups);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.badgeGroups);
    const {
      badgeGroups
    } = entities;
    yield (0, _effects.put)(_badges.getBadgeGroupsAsync.success(badgeGroups));
  } catch (error) {
    yield (0, _effects.put)(_badges.getBadgeGroupsAsync.failure(error));
  }
}

function* getBadgeCollectionTypesHandler(action) {
  try {
    const response = yield (0, _effects.call)(getBadgeCollectionTypes);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.badgeCollectionTypes);
    const {
      badgeCollectionTypes
    } = entities;
    yield (0, _effects.put)(_badges.getBadgeCollectionTypesAsync.success(badgeCollectionTypes));
  } catch (error) {
    yield (0, _effects.put)(_badges.getBadgeCollectionTypesAsync.failure(error));
  }
}

function* deleteBadgeCollectionHandler(action) {
  try {
    const badgeCollectionId = action.payload;
    const response = yield (0, _effects.call)(deleteBadgeCollection, badgeCollectionId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.badges);
    const {
      badges
    } = entities;
    yield (0, _effects.put)(_badges.deleteBadgeCollectionAsync.success(badges));
  } catch (error) {
    yield (0, _effects.put)(_badges.deleteBadgeCollectionAsync.failure(error));
  }
}

function* deleteBadgeGroupHandler(action) {
  try {
    const badgeGroupId = action.payload;
    const response = yield (0, _effects.call)(deleteBadgeGroup, badgeGroupId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.badgeGroups);
    const {
      badgeGroups
    } = entities;
    yield (0, _effects.put)(_badges.deleteBadgeGroupAsync.success(badgeGroups));
  } catch (error) {
    yield (0, _effects.put)(_badges.deleteBadgeGroupAsync.failure(error));
  }
}

function* undeleteBadgeGroupHandler(action) {
  try {
    const badgeGroupId = action.payload;
    const response = yield (0, _effects.call)(undeleteBadgeGroup, badgeGroupId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.badgeGroups);
    const {
      badgeGroups
    } = entities;
    yield (0, _effects.put)(_badges.undeleteBadgeGroupAsync.success(badgeGroups));
  } catch (error) {
    yield (0, _effects.put)(_badges.undeleteBadgeGroupAsync.failure(error));
  }
}

function* undeleteBadgeCollectionHandler(action) {
  try {
    const badgeCollectionId = action.payload;
    const response = yield (0, _effects.call)(undeleteBadgeCollection, badgeCollectionId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.badges);
    const {
      badges
    } = entities;
    yield (0, _effects.put)(_badges.undeleteBadgeCollectionAsync.success(badges));
  } catch (error) {
    yield (0, _effects.put)(_badges.undeleteBadgeCollectionAsync.failure(error));
  }
}

function* saveBadgeGroupHandler(action) {
  try {
    const badgeGroup = action.payload;
    const response = yield (0, _effects.call)(saveBadgeGroup, badgeGroup);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.badgeGroups);
    const {
      badgeGroups
    } = entities;
    yield (0, _effects.put)(_badges.saveBadgeGroupAsync.success(badgeGroups));
  } catch (error) {
    yield (0, _effects.put)(_badges.saveBadgeGroupAsync.failure(error));
  }
}

function* saveBadgeCollectionHandler(action) {
  try {
    const badgeCollection = action.payload;
    const response = yield (0, _effects.call)(saveBadgeCollection, badgeCollection);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.badgeCollections);
    const {
      badgeCollections
    } = entities;
    yield (0, _effects.put)(_badges.saveBadgeCollectionAsync.success(badgeCollections));
  } catch (error) {
    yield (0, _effects.put)(_badges.saveBadgeCollectionAsync.failure(error));
  }
}

function* getParticipantBadgesHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(getParticipantBadges, participantId);
    const participantBadgesResponse = response.data ? response.data : [];
    const {
      entities
    } = (0, _normalizr.normalize)(participantBadgesResponse, _schema.entitySchema.participantBadges);
    const {
      participantBadges
    } = entities;
    yield (0, _effects.put)(_badges.getParticipantBadgesAsync.success(participantBadges));
  } catch (error) {
    yield (0, _effects.put)(_badges.getParticipantBadgesAsync.failure(error));
  }
}

function* awardParticipantBadgeHandler(action) {
  try {
    const {
      participantId,
      badgeCollectionId
    } = action.payload;
    const response = yield (0, _effects.call)(awardParticipantBadge, participantId, badgeCollectionId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.badges);
    const {
      badges
    } = entities; // yield put(awardParticipantBadgeAsync.success(badges));

    yield (0, _effects.put)(_badges.getParticipantBadgesAsync.request(participantId));
  } catch (error) {
    yield (0, _effects.put)(_badges.awardParticipantBadgeAsync.failure(error));
  }
}

function* getBadgeCollectionsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_badges.getBadgeCollectionsAsync.request), getBadgeCollectionsHandler);
}

function* getBadgeGroupsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_badges.getBadgeGroupsAsync.request), getBadgeGroupsHandler);
}

function* getBadgeCollectionTypesWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_badges.getBadgeCollectionTypesAsync.request), getBadgeCollectionTypesHandler);
}

function* deleteBadgeCollectionWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_badges.deleteBadgeCollectionAsync.request), deleteBadgeCollectionHandler);
}

function* deleteBadgeGroupWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_badges.deleteBadgeGroupAsync.request), deleteBadgeGroupHandler);
}

function* undeleteBadgeGroupWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_badges.undeleteBadgeGroupAsync.request), undeleteBadgeGroupHandler);
}

function* undeleteBadgeCollectionWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_badges.undeleteBadgeCollectionAsync.request), undeleteBadgeCollectionHandler);
}

function* saveBadgeGroupWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_badges.saveBadgeGroupAsync.request), saveBadgeGroupHandler);
}

function* saveBadgeCollectionWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_badges.saveBadgeCollectionAsync.request), saveBadgeCollectionHandler);
}

function* getParticipantBadgesWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_badges.getParticipantBadgesAsync.request), getParticipantBadgesHandler);
}

function* awardParticipantBadgeWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_badges.awardParticipantBadgeAsync.request), awardParticipantBadgeHandler);
}

function* badgeSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getBadgeCollectionsWatcher), (0, _effects.fork)(getBadgeGroupsWatcher), (0, _effects.fork)(getBadgeCollectionTypesWatcher), (0, _effects.fork)(deleteBadgeCollectionWatcher), (0, _effects.fork)(deleteBadgeGroupWatcher), (0, _effects.fork)(undeleteBadgeGroupWatcher), (0, _effects.fork)(undeleteBadgeCollectionWatcher), (0, _effects.fork)(saveBadgeGroupWatcher), (0, _effects.fork)(saveBadgeCollectionWatcher), (0, _effects.fork)(getParticipantBadgesWatcher), (0, _effects.fork)(awardParticipantBadgeWatcher)]);
}