"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.appointmentsReducer = void 0;

var _immer = _interopRequireDefault(require("immer"));

var _lodash = require("lodash");

var _typesafeActions = require("typesafe-actions");

var _state = require("../../types/state.types");

var _appointments = require("./appointments.types");

const updateAppointmentsReducer = (0, _immer.default)((draft, appointments) => {
  if (!appointments) {
    return;
  }

  draft.allIds = _lodash._.map(_lodash._.keys(appointments));
  const ids = Object.keys(appointments);
  const draftByIdKeys = Object.keys(draft.byId);
  draftByIdKeys.forEach(byId => {
    if (draft.allIds.findIndex(a => a === byId) === -1) {
      delete draft.byId[byId];
    }
  });

  for (const id of ids) {
    if (appointments[id].deleteDate) {
      draft.allIds = draft.allIds.filter(allId => allId !== id);
      delete draft.byId[id];
    } else {
      draft.byId[id] = appointments[id];
    }
  }
});
const deleteAppointmentsReducer = (0, _immer.default)((draft, appointments) => {
  if (!appointments) {
    return;
  }

  const ids = Object.keys(appointments);

  for (const idToDelete of ids) {
    draft.allIds = draft.allIds.filter(id => id !== idToDelete);
    delete draft.byId[idToDelete];
  }
});

const appointmentsReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_appointments.getAppointmentsAsync.success):
    case (0, _typesafeActions.getType)(_appointments.createAppointmentAsync.success):
    case (0, _typesafeActions.getType)(_appointments.updateAppointmentAsync.success):
    case (0, _typesafeActions.getType)(_appointments.updateAppointments):
      return updateAppointmentsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_appointments.deleteAppointmentAsync.success):
      return deleteAppointmentsReducer(state, action.payload);

    default:
      return state;
  }
};

exports.appointmentsReducer = appointmentsReducer;
var _default = appointmentsReducer;
exports.default = _default;