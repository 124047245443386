"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialUserState = exports.initialNormalizedState = void 0;
const initialNormalizedState = {
  allIds: [],
  byId: {}
};
exports.initialNormalizedState = initialNormalizedState;
const initialUserState = {
  id: -1,
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  roles: []
};
exports.initialUserState = initialUserState;