"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = notesSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _notes = require("./notes.types");

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

const getParticipantNotes = participantId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/note/${participantId}`
  });
};

function* getParticipantNotesHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(getParticipantNotes, participantId);
    const participantNotes = response.data ? response.data : [];
    const {
      entities
    } = (0, _normalizr.normalize)(participantNotes, _schema.entitySchema.notes);
    const {
      notes
    } = entities;
    yield (0, _effects.put)(_notes.getParticipantNotesAsync.success(notes));
  } catch (error) {
    yield (0, _effects.put)(_notes.getParticipantNotesAsync.failure(error));
  }
}

function* getParticipantNotesWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_notes.getParticipantNotesAsync.request), getParticipantNotesHandler);
}

const saveParticipantNote = note => {
  const data = (0, _cloneDeep.default)(note);
  return (0, _api.default)({
    method: 'put',
    url: `/a/note/${note.participantId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data
  });
};

function* saveParticipantNoteHandler(action) {
  try {
    const note = action.payload;
    const response = yield (0, _effects.call)(saveParticipantNote, note);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.notes);
    const {
      notes
    } = entities;
    yield (0, _effects.put)(_notes.saveParticipantNoteAsync.success(notes));
  } catch (error) {
    yield (0, _effects.put)(_notes.saveParticipantNoteAsync.failure(error));
  }
}

function* saveParticipantNoteWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_notes.saveParticipantNoteAsync.request), saveParticipantNoteHandler);
}

function* notesSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getParticipantNotesWatcher), (0, _effects.fork)(saveParticipantNoteWatcher)]);
}