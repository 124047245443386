"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = commentsSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _favorites = require("../favorites/favorites.types");

var _flags = require("../flags/flags.types");

var _schema = require("../schema");

var _thumbsups = require("../thumbsups/thumbsups.types");

var _socialUtil = require("../user/socialUtil");

var _comments = require("./comments.types");

const getParticipantComments = participantId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/social/commentsByParticipant/${participantId}`
  });
};

const getComments = jsonComments => {
  const param = JSON.stringify(jsonComments);
  return (0, _api.default)({
    method: 'get',
    url: `/a/social/comments?commentsJson=${encodeURIComponent(param)}`
  });
};

const createComment = param => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/social/comment/${param.type}/${param.typeId}?participantId=${param.participantId}`,
    data: {
      body: param.comment,
      parentCommentId: param.parentCommentId,
      createDate: param.createDate
    }
  });
};

const updateComment = comment => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/social/updateComment/${comment.id}`,
    data: comment
  });
};

function* getParticipantCommentsHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(getParticipantComments, participantId);
    const participantComments = response.data ? response.data : [];
    const {
      entities
    } = (0, _normalizr.normalize)(participantComments, _schema.entitySchema.comments);
    const {
      comments
    } = entities;
    yield (0, _effects.put)(_comments.getParticipantCommentsAsync.success(comments));
  } catch (error) {
    yield (0, _effects.put)(_comments.getParticipantCommentsAsync.failure(error));
  }
}

function* getParticipantCommentsWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_comments.getParticipantCommentsAsync.request), getParticipantCommentsHandler);
}

function* getCommentsHandler(action) {
  try {
    const commentsJsonParam = action.payload;
    const response = yield (0, _effects.call)(getComments, commentsJsonParam);
    const {
      entities,
      result
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.comments);
    const {
      comments
    } = entities;

    if (result === null || result === void 0 ? void 0 : result.length) {
      const jsonParam = [(0, _socialUtil.createJsonParam)('comment', result)];
      yield (0, _effects.put)(_flags.getFlagsAsync.request(jsonParam));
      yield (0, _effects.put)(_thumbsups.getThumbsupsAsync.request(jsonParam));
      yield (0, _effects.put)(_favorites.getFavoritesAsync.request(jsonParam));
    }

    yield (0, _effects.put)(_comments.getCommentsAsync.success(comments));
  } catch (error) {
    yield (0, _effects.put)(_comments.getCommentsAsync.failure(error));
  }
}

function* createCommentHandler(action) {
  try {
    const createCommentParam = action.payload;
    const response = yield (0, _effects.call)(createComment, createCommentParam);
    const {
      entities,
      result
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.comments);
    const {
      comments
    } = entities;

    if (result === null || result === void 0 ? void 0 : result.length) {
      const jsonParam = [(0, _socialUtil.createJsonParam)('comment', result)];
      yield (0, _effects.put)(_flags.getFlagsAsync.request(jsonParam));
      yield (0, _effects.put)(_thumbsups.getThumbsupsAsync.request(jsonParam));
      yield (0, _effects.put)(_favorites.getFavoritesAsync.request(jsonParam));
    }

    yield (0, _effects.put)(_comments.getCommentsAsync.success(comments));
  } catch (error) {
    yield (0, _effects.put)(_comments.getCommentsAsync.failure(error));
  }
}

function* updateCommentHandler(action) {
  try {
    const updateCommentParam = action.payload;
    const response = yield (0, _effects.call)(updateComment, updateCommentParam);
    const {
      entities,
      result
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.comments);
    const {
      comments
    } = entities;

    if (result === null || result === void 0 ? void 0 : result.length) {
      const jsonParam = [(0, _socialUtil.createJsonParam)('comment', result)];
      yield (0, _effects.put)(_flags.getFlagsAsync.request(jsonParam));
      yield (0, _effects.put)(_thumbsups.getThumbsupsAsync.request(jsonParam));
      yield (0, _effects.put)(_favorites.getFavoritesAsync.request(jsonParam));
    }

    yield (0, _effects.put)(_comments.getCommentsAsync.success(comments));
  } catch (error) {
    yield (0, _effects.put)(_comments.getCommentsAsync.failure(error));
  }
}

function* getCommentsWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_comments.getCommentsAsync.request), getCommentsHandler);
}

function* createCommentWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_comments.createCommentAsync.request), createCommentHandler);
}

function* updateCommentWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_comments.updateCommentAsync.request), updateCommentHandler);
}

function* commentsSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getParticipantCommentsWatcher), (0, _effects.fork)(getCommentsWatcher), (0, _effects.fork)(createCommentWatcher), (0, _effects.fork)(updateCommentWatcher)]);
}