"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.forumUIReducer = exports.initialForumUIState = void 0;

var _typesafeActions = require("typesafe-actions");

var _forum = require("./forum.types");

const initialForumUIState = {
  recentActivityPostIds: [],
  uncategorizedPostIds: [],
  flaggedPostIds: [],
  recentActivity: {
    scrollTop: 0,
    selectedPostId: undefined,
    selectedQnaPostId: undefined
  },
  flaggedPosts: {
    scrollTop: 0,
    selectedPostId: undefined
  },
  uncategorizedPosts: {
    scrollTop: 0,
    selectedPostId: undefined
  }
};
exports.initialForumUIState = initialForumUIState;

const forumUIReducer = (state = initialForumUIState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_forum.resetRecentActivityPostIds):
      return { ...state,
        recentActivityPostIds: []
      };

    case (0, _typesafeActions.getType)(_forum.updateRecentActivityPostIds):
      return { ...state,
        recentActivityPostIds: state.recentActivityPostIds.concat(action.payload)
      };

    case (0, _typesafeActions.getType)(_forum.updateUncategorizedPostIds):
      return { ...state,
        uncategorizedPostIds: action.payload
      };

    case (0, _typesafeActions.getType)(_forum.updateFlaggedPostIds):
      return { ...state,
        flaggedPostIds: action.payload
      };

    case (0, _typesafeActions.getType)(_forum.updateRecentActivityScroll):
      return { ...state,
        recentActivity: {
          scrollTop: action.payload,
          selectedPostId: state.recentActivity.selectedPostId
        }
      };

    case (0, _typesafeActions.getType)(_forum.updateFlaggedScroll):
      return { ...state,
        flaggedPosts: {
          scrollTop: action.payload,
          selectedPostId: state.flaggedPosts.selectedPostId
        }
      };

    case (0, _typesafeActions.getType)(_forum.updateUncategorizedScroll):
      return { ...state,
        uncategorizedPosts: {
          scrollTop: action.payload,
          selectedPostId: state.uncategorizedPosts.selectedPostId
        }
      };

    case (0, _typesafeActions.getType)(_forum.updateRecentActivitySelectedPostId):
      return { ...state,
        recentActivity: {
          scrollTop: state.recentActivity.scrollTop,
          selectedPostId: action.payload
        }
      };

    case (0, _typesafeActions.getType)(_forum.updateRecentActivitySelectedQnaPostId):
      return { ...state,
        recentActivity: {
          scrollTop: state.recentActivity.scrollTop,
          selectedQnaPostId: action.payload
        }
      };

    case (0, _typesafeActions.getType)(_forum.resetRecentActivitySelectedQnaPostId):
      return { ...state,
        recentActivity: {
          scrollTop: state.recentActivity.scrollTop,
          selectedQnaPostId: undefined
        }
      };

    case (0, _typesafeActions.getType)(_forum.updateFlaggedSelectedPostId):
      return { ...state,
        flaggedPosts: {
          scrollTop: state.flaggedPosts.scrollTop,
          selectedPostId: action.payload
        }
      };

    case (0, _typesafeActions.getType)(_forum.updateUncategorizedSelectedPostId):
      return { ...state,
        uncategorizedPosts: {
          scrollTop: state.uncategorizedPosts.scrollTop,
          selectedPostId: action.payload
        }
      };

    default:
      return state;
  }
};

exports.forumUIReducer = forumUIReducer;
var _default = forumUIReducer;
exports.default = _default;