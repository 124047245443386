"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getForumDetailsByStudyIdAsync = exports.updateUncategorizedSelectedPostId = exports.updateFlaggedSelectedPostId = exports.resetRecentActivitySelectedQnaPostId = exports.updateRecentActivitySelectedQnaPostId = exports.updateRecentActivitySelectedPostId = exports.updateUncategorizedScroll = exports.updateFlaggedScroll = exports.updateRecentActivityScroll = exports.updateFlaggedPostIds = exports.updateUncategorizedPostIds = exports.updateRecentActivityPostIds = exports.resetRecentActivityPostIds = void 0;

var _typesafeActions = require("typesafe-actions");

const resetRecentActivityPostIds = (0, _typesafeActions.createStandardAction)('hmp/admin/RESET_RECENT_ACTIVITY_POST_IDS')();
exports.resetRecentActivityPostIds = resetRecentActivityPostIds;
const updateRecentActivityPostIds = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_RECENT_ACTIVITY_POST_IDS')();
exports.updateRecentActivityPostIds = updateRecentActivityPostIds;
const updateUncategorizedPostIds = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_UNCATEGORIZED_POST_IDS')();
exports.updateUncategorizedPostIds = updateUncategorizedPostIds;
const updateFlaggedPostIds = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_FLAGGED_POST_IDS')();
exports.updateFlaggedPostIds = updateFlaggedPostIds;
const updateRecentActivityScroll = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_RECENT_ACTIVITY_SCROLL')();
exports.updateRecentActivityScroll = updateRecentActivityScroll;
const updateFlaggedScroll = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_FLAGGED_SCROLL')();
exports.updateFlaggedScroll = updateFlaggedScroll;
const updateUncategorizedScroll = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_UNCATEGORIZED_SCROLL')();
exports.updateUncategorizedScroll = updateUncategorizedScroll;
const updateRecentActivitySelectedPostId = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_RECENT_ACTIVITY_POST_ID')();
exports.updateRecentActivitySelectedPostId = updateRecentActivitySelectedPostId;
const updateRecentActivitySelectedQnaPostId = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_RECENT_QNA_ACTIVITY_POST_ID')();
exports.updateRecentActivitySelectedQnaPostId = updateRecentActivitySelectedQnaPostId;
const resetRecentActivitySelectedQnaPostId = (0, _typesafeActions.createStandardAction)('hmp/admin/RESET_RECENT_QNA_ACTIVITY_POST_ID')();
exports.resetRecentActivitySelectedQnaPostId = resetRecentActivitySelectedQnaPostId;
const updateFlaggedSelectedPostId = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_FLAGGED_POST_ID')();
exports.updateFlaggedSelectedPostId = updateFlaggedSelectedPostId;
const updateUncategorizedSelectedPostId = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_UNCATEGORIZED_POST_ID')();
exports.updateUncategorizedSelectedPostId = updateUncategorizedSelectedPostId;
const getForumDetailsByStudyIdAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_FORUM_DETAILS_BY_STUDY_ID_REQUEST', 'hmp/admin/GET_FORUM_DETAILS_BY_STUDY_ID_SUCCESS', 'hmp/admin/GET_FORUM_DETAILS_BY_STUDY_ID_FAILURE')();
exports.getForumDetailsByStudyIdAsync = getForumDetailsByStudyIdAsync;
const forumActions = {
  resetRecentActivityPostIds,
  updateRecentActivityPostIds,
  updateUncategorizedPostIds,
  updateFlaggedPostIds,
  updateRecentActivityScroll,
  updateFlaggedScroll,
  updateUncategorizedScroll,
  updateRecentActivitySelectedPostId,
  updateRecentActivitySelectedQnaPostId,
  updateFlaggedSelectedPostId,
  updateUncategorizedSelectedPostId,
  resetRecentActivitySelectedQnaPostId,
  getForumDetailsByStudyIdAsync
};