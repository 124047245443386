"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getAvailabilities = exports.selectAvailabilities = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = _interopRequireDefault(require("../schema"));

const selectAvailabilities = state => {
  return state.entities.availabilities;
};

exports.selectAvailabilities = selectAvailabilities;
const getAvailabilities = (0, _reselect.createSelector)([selectAvailabilities], availabilities => {
  if (availabilities && availabilities.allIds && availabilities.allIds.length > 0) {
    const {
      availabilities: denormalizedAvailabilities
    } = (0, _normalizr.denormalize)({
      availabilities: availabilities.allIds
    }, _schema.default, {
      availabilities: availabilities.byId
    });
    return (0, _toArray.default)(denormalizedAvailabilities);
  }

  return undefined;
});
exports.getAvailabilities = getAvailabilities;
var _default = {
  getAvailabilities
};
exports.default = _default;