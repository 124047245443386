"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getCommentFavorites = exports.getQnaFavorites = exports.getFavorites = exports.selectFavorites = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _participants = require("../participants/participants.selectors");

var _schema = require("../schema");

const selectFavorites = state => {
  return state.entities.favorites;
};

exports.selectFavorites = selectFavorites;
const getFavorites = (0, _reselect.createSelector)([selectFavorites, _participants.selectParticipants], (favorites, participants) => {
  if (favorites && favorites.allIds && favorites.allIds.length > 0) {
    const {
      favorites: denormalizedFavorites
    } = (0, _normalizr.denormalize)({
      favorites: favorites.allIds
    }, _schema.entitySchema, {
      favorites: favorites.byId,
      participants: participants.byId
    });
    return (0, _toArray.default)(denormalizedFavorites);
  }

  return undefined;
});
exports.getFavorites = getFavorites;
const getQnaFavorites = (0, _reselect.createSelector)([selectFavorites, _participants.selectParticipants], (favorites, participants) => {
  if (favorites && favorites.allIds && favorites.allIds.length > 0) {
    const {
      favorites: denormalizedFavorites
    } = (0, _normalizr.denormalize)({
      favorites: favorites.allIds
    }, _schema.entitySchema, {
      favorites: favorites.byId,
      participants: participants.byId
    });
    const qnaFavorites = (0, _toArray.default)(denormalizedFavorites).filter(f => f.type === 'qna');
    return qnaFavorites;
  }

  return undefined;
});
exports.getQnaFavorites = getQnaFavorites;
const getCommentFavorites = (0, _reselect.createSelector)([selectFavorites, _participants.selectParticipants], (favorites, participants) => {
  if (favorites && favorites.allIds && favorites.allIds.length > 0) {
    const {
      favorites: denormalizedFavorites
    } = (0, _normalizr.denormalize)({
      favorites: favorites.allIds
    }, _schema.entitySchema, {
      favorites: favorites.byId,
      participants: participants.byId
    });
    const qnaFavorites = (0, _toArray.default)(denormalizedFavorites).filter(f => f.type === 'comment');
    return qnaFavorites;
  }

  return undefined;
});
exports.getCommentFavorites = getCommentFavorites;
var _default = {
  getFavorites
};
exports.default = _default;