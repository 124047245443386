"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formsSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _form = require("./form.types");

const getForms = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/form/study/${studyId}`
  });
};

function* getFormsHandler(action) {
  try {
    const studyId = action.payload;
    const response = yield (0, _effects.call)(getForms, studyId);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.forms);
    const {
      forms
    } = entities;
    yield (0, _effects.put)(_form.getFormsAsync.success(forms));
  } catch (error) {
    yield (0, _effects.put)(_form.getFormsAsync.failure(error));
  }
}

function* getFormsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_form.getFormsAsync.request), getFormsHandler);
}

const getParticipantForms = participantId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/form/participant/${participantId}`
  });
};

function* getParticipantFormsHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(getParticipantForms, participantId);
    const participantForms = response.data ? response.data : [];
    const {
      entities
    } = (0, _normalizr.normalize)(participantForms, _schema.entitySchema.forms);
    const {
      forms
    } = entities;
    yield (0, _effects.put)(_form.getParticipantFormsAsync.success(forms));
  } catch (error) {
    yield (0, _effects.put)(_form.getParticipantFormsAsync.failure(error));
  }
}

function* getParticipantFormsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_form.getParticipantFormsAsync.request), getParticipantFormsHandler);
}

const saveForm = form => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/form/${form.formType}?participantId=${form.participantId}`,
    data: {
      isResolved: form.isResolved,
      ...form.formData
    }
  });
};

function* saveFormHandler(action) {
  try {
    const form = action.payload;
    const response = yield (0, _effects.call)(saveForm, form);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.forms);
    const {
      forms
    } = entities;
    yield (0, _effects.put)(_form.saveFormAsync.success(forms));
  } catch (error) {
    yield (0, _effects.put)(_form.saveFormAsync.failure(error));
  }
}

function* saveFormWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_form.saveFormAsync.request), saveFormHandler);
}

const editForm = form => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/form/${form.formType}/${form.id}/?participantId=${form.participantId}`,
    data: {
      isResolved: form.isResolved,
      ...form.formData
    }
  });
};

function* editFormHandler(action) {
  try {
    const form = action.payload;
    const response = yield (0, _effects.call)(editForm, form);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.forms);
    const {
      forms
    } = entities;
    yield (0, _effects.put)(_form.updateFormAsync.success(forms));
  } catch (error) {
    yield (0, _effects.put)(_form.updateFormAsync.failure(error));
  }
}

function* editFormWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_form.updateFormAsync.request), editFormHandler);
}

function* formsSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getFormsWatcher), (0, _effects.fork)(getParticipantFormsWatcher), (0, _effects.fork)(saveFormWatcher), (0, _effects.fork)(editFormWatcher)]);
}