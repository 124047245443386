"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFavoritesAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getFavoritesAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_FAVORITES_REQUEST', 'hmp/admin/GET_FAVORITES_SUCCESS', 'hmp/admin/GET_FAVORITES_FAILURE')();
exports.getFavoritesAsync = getFavoritesAsync;
const thumbsupsActions = {
  getFavoritesAsync
};