"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getRequestedStudyParticipantBadges = exports.getBadgeCollectionTypes = exports.getRequestedBadgeGroup = exports.getBadgeGroups = exports.getRequestedBadgeCollection = exports.getBadgeCollections = exports.getBadges = exports.selectBadgeCollectionTypes = exports.selectBadgeGroups = exports.selectBadgeCollections = exports.selectBadges = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = require("../schema");

var _router = require("../router/router.selectors");

var _baseSelectors = require("../baseSelectors");

const selectBadges = state => {
  return state.entities.badges;
};

exports.selectBadges = selectBadges;

const selectBadgeCollections = state => {
  return state.entities.badgeCollections;
};

exports.selectBadgeCollections = selectBadgeCollections;

const selectBadgeGroups = state => {
  return state.entities.badgeGroups;
};

exports.selectBadgeGroups = selectBadgeGroups;

const selectBadgeCollectionTypes = state => {
  return state.entities.badgeCollectionTypes;
};

exports.selectBadgeCollectionTypes = selectBadgeCollectionTypes;
const getBadges = (0, _reselect.createSelector)([selectBadges], badges => {
  if (badges && badges.allIds && badges.allIds.length > 0) {
    const {
      badges: denormalizedBadges
    } = (0, _normalizr.denormalize)({
      badges: badges.allIds
    }, _schema.entitySchema, {
      badges: badges.byId
    });
    return (0, _toArray.default)(denormalizedBadges);
  }

  return undefined;
});
exports.getBadges = getBadges;
const getBadgeCollections = (0, _reselect.createSelector)([selectBadgeCollections], badgeCollections => {
  var _a;

  if (((_a = badgeCollections === null || badgeCollections === void 0 ? void 0 : badgeCollections.allIds) === null || _a === void 0 ? void 0 : _a.length) > 0) {
    const {
      badgeCollections: denormalizedBadgeCollections
    } = (0, _normalizr.denormalize)({
      badgeCollections: badgeCollections.allIds
    }, _schema.entitySchema, {
      badgeCollections: badgeCollections.byId
    });
    return (0, _toArray.default)(denormalizedBadgeCollections);
  }

  return undefined;
});
exports.getBadgeCollections = getBadgeCollections;
const getRequestedBadgeCollection = (0, _reselect.createSelector)([getBadgeCollections, _router.getUrlNewOrEditId], (badgeCollections, badgeCollectionIdPathParam) => {
  if ((badgeCollections === null || badgeCollections === void 0 ? void 0 : badgeCollections.length) && badgeCollectionIdPathParam) {
    const badgeCollectionId = parseInt(badgeCollectionIdPathParam);
    return badgeCollections.find(bg => bg.id === badgeCollectionId);
  }

  return undefined;
});
exports.getRequestedBadgeCollection = getRequestedBadgeCollection;
const getBadgeGroups = (0, _reselect.createSelector)([selectBadgeGroups], badgeGroups => {
  if (badgeGroups && badgeGroups.allIds && badgeGroups.allIds.length > 0) {
    const x = (0, _normalizr.denormalize)({
      badgeGroups: badgeGroups.allIds
    }, _schema.entitySchema, {
      badgeGroups: badgeGroups.byId
    });
    const {
      badgeGroups: denormalizedBadgeGroups
    } = x;
    return (0, _toArray.default)(denormalizedBadgeGroups);
  }

  return undefined;
});
exports.getBadgeGroups = getBadgeGroups;
const getRequestedBadgeGroup = (0, _reselect.createSelector)([getBadgeGroups, _router.getUrlNewOrEditId], (badgeGroups, badgeGroupIdPathParam) => {
  if ((badgeGroups === null || badgeGroups === void 0 ? void 0 : badgeGroups.length) && badgeGroupIdPathParam) {
    const badgeGroupId = parseInt(badgeGroupIdPathParam);
    return badgeGroups.find(bg => bg.id === badgeGroupId);
  }

  return undefined;
});
exports.getRequestedBadgeGroup = getRequestedBadgeGroup;
const getBadgeCollectionTypes = (0, _reselect.createSelector)([selectBadgeCollectionTypes], badgeCollectionTypes => {
  if (badgeCollectionTypes && badgeCollectionTypes.allIds && badgeCollectionTypes.allIds.length > 0) {
    const x = (0, _normalizr.denormalize)({
      badgeCollectionTypes: badgeCollectionTypes.allIds
    }, _schema.entitySchema, {
      badgeCollectionTypes: badgeCollectionTypes.byId
    });
    const {
      badgeCollectionTypes: denormalizedBadgeCollectionTypes
    } = x;
    return (0, _toArray.default)(denormalizedBadgeCollectionTypes);
  }

  return undefined;
});
exports.getBadgeCollectionTypes = getBadgeCollectionTypes;
const getRequestedStudyParticipantBadges = (0, _reselect.createSelector)([getBadges, _baseSelectors.getRequestedStudyParticipant], (badges, participant) => {
  if ((badges === null || badges === void 0 ? void 0 : badges.length) && participant) {
    return badges.filter(b => b.participantId === participant.id);
  }

  return [];
});
exports.getRequestedStudyParticipantBadges = getRequestedStudyParticipantBadges;
var _default = {
  getBadges,
  getBadgeGroups
};
exports.default = _default;