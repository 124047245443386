"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.sideBarReducer = exports.initialSideBarState = void 0;

var _typesafeActions = require("typesafe-actions");

var _sidebar = require("./sidebar.types");

const initialSideBarState = {
  collapsed: false
};
exports.initialSideBarState = initialSideBarState;

const sideBarReducer = (state = initialSideBarState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_sidebar.toggleSideBar):
      return { ...state,
        collapsed: !state.collapsed
      };

    default:
      return { ...state
      };
  }
};

exports.sideBarReducer = sideBarReducer;
var _default = sideBarReducer;
exports.default = _default;