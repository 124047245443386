"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateParticipants = exports.getParticipantsByStudyAsync = exports.getParticipantsByStudyArmAsync = exports.saveParticipantInterviewSelectedAsync = exports.saveParticipantActualHivStatusAsync = exports.saveParticipantAsync = exports.loadRequestedParticipantAsync = exports.loadParticipantAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const loadParticipantAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_PARTICIPANT_REQUEST', 'hmp/admin/LOAD_PARTICIPANT_SUCCESS', 'hmp/admin/LOAD_PARTICIPANT_FAILURE')();
exports.loadParticipantAsync = loadParticipantAsync;
const loadRequestedParticipantAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_REQUESTED_PARTICIPANT_REQUEST', 'hmp/admin/LOAD_REQUESTED_PARTICIPANT_SUCCESS', 'hmp/admin/LOAD_REQUESTED_PARTICIPANT_FAILURE')();
exports.loadRequestedParticipantAsync = loadRequestedParticipantAsync;
const saveParticipantAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/SAVE_PARTICIPANT_REQUEST', 'hmp/admin/SAVE_PARTICIPANT_SUCCESS', 'hmp/admin/SAVE_PARTICIPANT_FAILURE')();
exports.saveParticipantAsync = saveParticipantAsync;
const saveParticipantActualHivStatusAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/SAVE_PARTICIPANT_STATUS_REQUEST', 'hmp/admin/SAVE_PARTICIPANT_STATUS_SUCCESS', 'hmp/admin/SAVE_PARTICIPANT_STATUS_FAILURE')();
exports.saveParticipantActualHivStatusAsync = saveParticipantActualHivStatusAsync;
const saveParticipantInterviewSelectedAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/SAVE_PARTICIPANT_INTERVIEW_SELECTED_REQUEST', 'hmp/admin/SAVE_PARTICIPANT_INTERVIEW_SELECTED_SUCCESS', 'hmp/admin/SAVE_PARTICIPANT_INTERVIEW_SELECTED_FAILURE')();
exports.saveParticipantInterviewSelectedAsync = saveParticipantInterviewSelectedAsync;
const getParticipantsByStudyArmAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/GET_PARTICIPANTS_BY_STUDY_ARM_REQUEST", "hmp/admin/GET_PARTICIPANTS_BY_STUDY_ARM_SUCCESS", "hmp/admin/GET_PARTICIPANTS_BY_STUDY_ARM_FAILURE")();
exports.getParticipantsByStudyArmAsync = getParticipantsByStudyArmAsync;
const getParticipantsByStudyAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/GET_PARTICIPANTS_BY_STUDY_REQUEST", "hmp/admin/GET_PARTICIPANTS_BY_STUDY_SUCCESS", "hmp/admin/GET_PARTICIPANTS_BY_STUDY_FAILURE")();
exports.getParticipantsByStudyAsync = getParticipantsByStudyAsync;
const updateParticipants = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_PARTICIPANTS')();
exports.updateParticipants = updateParticipants;
const participantActions = {
  loadParticipantAsync,
  loadRequestedParticipantAsync,
  saveParticipantAsync,
  saveParticipantActualHivStatusAsync,
  saveParticipantInterviewSelectedAsync,
  getParticipantsByStudyArmAsync,
  updateParticipants,
  getParticipantsByStudyAsync
};