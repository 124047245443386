"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TopicCollectionIds = void 0;
var TopicCollectionIds;
exports.TopicCollectionIds = TopicCollectionIds;

(function (TopicCollectionIds) {
  TopicCollectionIds[TopicCollectionIds["FORUM"] = 1] = "FORUM";
  TopicCollectionIds[TopicCollectionIds["KNOWLEDGE_CENTER"] = 2] = "KNOWLEDGE_CENTER";
  TopicCollectionIds[TopicCollectionIds["QNA"] = 3] = "QNA";
  TopicCollectionIds[TopicCollectionIds["GOAL"] = 5] = "GOAL";
})(TopicCollectionIds || (exports.TopicCollectionIds = TopicCollectionIds = {}));