"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.articleUIReducer = exports.initialArticleUIState = void 0;

var _typesafeActions = require("typesafe-actions");

var _article = require("./article.types");

const initialArticleUIState = {
  articleIds: [],
  newArticleId: -1,
  selectedTopicIds: [],
  includeDeleted: true,
  searchTerm: ""
};
exports.initialArticleUIState = initialArticleUIState;

const articleUIReducer = (state = initialArticleUIState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_article.updateSelectedArticleIds):
      return { ...state,
        articleIds: action.payload
      };

    case (0, _typesafeActions.getType)(_article.updateNewArticleId):
      return { ...state,
        newArticleId: action.payload
      };

    case (0, _typesafeActions.getType)(_article.updateSelectedTopicIds):
      return { ...state,
        selectedTopicIds: action.payload
      };

    case (0, _typesafeActions.getType)(_article.updateIncludeDeleted):
      return { ...state,
        includeDeleted: action.payload
      };

    case (0, _typesafeActions.getType)(_article.updateSearchTerm):
      return { ...state,
        searchTerm: action.payload
      };

    default:
      return { ...state
      };
  }
};

exports.articleUIReducer = articleUIReducer;
var _default = articleUIReducer;
exports.default = _default;