"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.adminRoleReducer = exports.adminUserReducer = exports.userReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _state = require("../../types/state.types");

var _user = require("./user.types");

const updateAdminReducer = (0, _immer.default)((draft, admin) => {
  if (!admin) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(admin), Number));
  const ids = Object.keys(admin);

  for (const id of ids) {
    draft.byId[id] = admin[id];
  }
});
const updateAdminRolesReducer = (0, _immer.default)((draft, adminRoles) => {
  if (!adminRoles) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(adminRoles), Number));
  const ids = Object.keys(adminRoles);

  for (const id of ids) {
    draft.byId[id] = adminRoles[id];
  }
});

const userReducer = (state = _state.initialUserState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_user.getUserAsync.request):
      {
        return _state.initialUserState;
      }

    case (0, _typesafeActions.getType)(_user.updatePasswordAsync.success):
    case (0, _typesafeActions.getType)(_user.updateEmailAsync.success):
    case (0, _typesafeActions.getType)(_user.updateMobileAsync.success):
    case (0, _typesafeActions.getType)(_user.getUserAsync.success):
      {
        return action.payload;
      }

    case (0, _typesafeActions.getType)(_user.getUserAsync.failure):
      {
        return _state.initialUserState;
      }

    default:
      {
        return state;
      }
  }
};

exports.userReducer = userReducer;

const adminUserReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_user.getAdminAsync.success):
    case (0, _typesafeActions.getType)(_user.updateAdminUserAsync.success):
      return updateAdminReducer(state, action.payload);

    default:
      {
        return state;
      }
  }
};

exports.adminUserReducer = adminUserReducer;

const adminRoleReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_user.getAdminRolesAsync.success):
      return updateAdminRolesReducer(state, action.payload);

    default:
      {
        return state;
      }
  }
};

exports.adminRoleReducer = adminRoleReducer;
var _default = userReducer;
exports.default = _default;