"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = paymentSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _payment = require("./payment.types");

const EXIT_INTERVIEW_PAYMENT_ID = 9;
const AD_HOC_PAYMENT_ID = 16;

const requestPayment = (participantId, incentiveId) => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/incentive/request/${participantId}/${incentiveId}`
  });
};

function* requestExitInterviewPaymentHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(requestPayment, participantId, EXIT_INTERVIEW_PAYMENT_ID);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.payments);
    const {
      payments
    } = entities;
    yield (0, _effects.put)(_payment.requestExitInterviewPaymentAsync.success(payments));
  } catch (error) {
    yield (0, _effects.put)(_payment.requestExitInterviewPaymentAsync.failure(error));
  }
}

function* requestExitInterviewPaymentWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_payment.requestExitInterviewPaymentAsync.request), requestExitInterviewPaymentHandler);
}

function* requestAdHocPaymentHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(requestPayment, participantId, AD_HOC_PAYMENT_ID);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.payments);
    const {
      payments
    } = entities;
    yield (0, _effects.put)(_payment.requestAdHocPaymentAsync.success(payments));
  } catch (error) {
    yield (0, _effects.put)(_payment.requestAdHocPaymentAsync.failure(error));
  }
}

function* requestAdHocPaymentWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_payment.requestAdHocPaymentAsync.request), requestAdHocPaymentHandler);
}

const getParticipantPayments = participantId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/incentive/getPayments?participantId=${participantId}`
  });
};

function* getParticipantPaymentsHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(getParticipantPayments, participantId);
    const participantPayments = response.data ? response.data : [];
    const {
      entities
    } = (0, _normalizr.normalize)(participantPayments, _schema.entitySchema.payments);
    const {
      payments
    } = entities;
    yield (0, _effects.put)(_payment.getParticipantPaymentsAsync.success(payments));
  } catch (error) {
    yield (0, _effects.put)(_payment.getParticipantPaymentsAsync.failure(error));
  }
}

function* getParticipantPaymentsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_payment.getParticipantPaymentsAsync.request), getParticipantPaymentsHandler);
}

const getPayments = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/incentive/getPayments`
  });
};

function* getPaymentsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getPayments);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.payments);
    const {
      payments
    } = entities;
    yield (0, _effects.put)(_payment.getPaymentsAsync.success(payments));
  } catch (error) {
    yield (0, _effects.put)(_payment.getPaymentsAsync.failure(error));
  }
}

function* getPaymentsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_payment.getPaymentsAsync.request), getPaymentsHandler);
}

const getPaymentsReport = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/incentive/getPayments?includeStudyIds=true`
  });
};

function* getPaymentsReportHandler(action) {
  try {
    const response = yield (0, _effects.call)(getPaymentsReport);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.payments);
    const {
      payments
    } = entities;
    yield (0, _effects.put)(_payment.getPaymentsReportAsync.success(payments));
  } catch (error) {
    yield (0, _effects.put)(_payment.getPaymentsReportAsync.failure(error));
  }
}

function* getPaymentsReportWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_payment.getPaymentsReportAsync.request), getPaymentsReportHandler);
}

const markPaid = markPaidObj => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/incentive/markPaid`,
    data: markPaidObj
  });
};

function* markPaidHandler(action) {
  try {
    const markPaidObj = action.payload;
    const response = yield (0, _effects.call)(markPaid, markPaidObj);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.payments);
    const {
      payments
    } = entities;
    yield (0, _effects.put)(_payment.markPaidAsync.success(payments));
  } catch (error) {
    yield (0, _effects.put)(_payment.markPaidAsync.failure(error));
  }
}

function* markPaidWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_payment.markPaidAsync.request), markPaidHandler);
}

function* paymentSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getPaymentsWatcher), (0, _effects.fork)(getPaymentsReportWatcher), (0, _effects.fork)(getParticipantPaymentsWatcher), (0, _effects.fork)(requestExitInterviewPaymentWatcher), (0, _effects.fork)(requestAdHocPaymentWatcher), (0, _effects.fork)(markPaidWatcher)]);
}