"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserSurveysAsync = exports.getParticipantUserSurveysAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getParticipantUserSurveysAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_PARTICIPANT_USER_SURVEYS_REQUEST', 'hmp/admin/GET_PARTICIPANT_USER_SURVEYS_SUCCESS', 'hmp/admin/GET_PARTICIPANT_USER_SURVEYS_FAILURE')();
exports.getParticipantUserSurveysAsync = getParticipantUserSurveysAsync;
const getUserSurveysAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_USER_SURVEYS_REQUEST', 'hmp/admin/GET_USER_SURVEYS_SUCCESS', 'hmp/admin/GET_USER_SURVEYS_FAILURE')();
exports.getUserSurveysAsync = getUserSurveysAsync;
const surveyActions = {
  getParticipantUserSurveysAsync: getParticipantUserSurveysAsync,
  getUserSurveysAsync: getUserSurveysAsync
};