"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = userSurveySaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _userSurvey = require("./userSurvey.types");

const getUserSurveysByParticipant = participantId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/survey/${participantId}`
  });
};

function* getUserSurveysByParticipantHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(getUserSurveysByParticipant, participantId);
    const participantUserSurvey = response.data ? response.data : [];
    const {
      entities
    } = (0, _normalizr.normalize)(participantUserSurvey, _schema.entitySchema.userSurveys);
    const {
      userSurveys
    } = entities;
    yield (0, _effects.put)(_userSurvey.getParticipantUserSurveysAsync.success(userSurveys));
  } catch (error) {
    yield (0, _effects.put)(_userSurvey.getParticipantUserSurveysAsync.failure(error));
  }
}

function* getUserSurveysByParticipantWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_userSurvey.getParticipantUserSurveysAsync.request), getUserSurveysByParticipantHandler);
}

const getUserSurveys = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/survey`
  });
};

function* getUserSurveysHandler(action) {
  try {
    const response = yield (0, _effects.call)(getUserSurveys);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.userSurveys);
    const {
      userSurveys
    } = entities;
    yield (0, _effects.put)(_userSurvey.getUserSurveysAsync.success(userSurveys));
  } catch (error) {
    yield (0, _effects.put)(_userSurvey.getUserSurveysAsync.failure(error));
  }
}

function* getUserSurveysWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_userSurvey.getUserSurveysAsync.request), getUserSurveysHandler);
}

function* userSurveySaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getUserSurveysByParticipantWatcher), (0, _effects.fork)(getUserSurveysWatcher)]);
}