"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.availabilitiesReducer = void 0;

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _typesafeActions = require("typesafe-actions");

var _state = require("../../types/state.types");

var _availability = require("./availability.types");

const updateAvailabilitiesReducer = (0, _immer.default)((draft, availabilities) => {
  if (!availabilities) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(availabilities), String));
  const ids = Object.keys(availabilities);

  for (const id of ids) {
    draft.byId[id] = availabilities[id];
  }
});
const deleteAvailabilityReducer = (0, _immer.default)((draft, availabilityIdToDelete) => {
  if (!availabilityIdToDelete) {
    return;
  }

  draft.allIds = draft.allIds.filter(id => id !== availabilityIdToDelete.toString());
  delete draft.byId[availabilityIdToDelete];
});

const availabilitiesReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_availability.getAvailabilitiesAsync.success):
    case (0, _typesafeActions.getType)(_availability.createAvailabilityAsync.success):
      return updateAvailabilitiesReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_availability.deleteAvailabilityAsync.success):
      return deleteAvailabilityReducer(state, action.payload);

    default:
      return state;
  }
};

exports.availabilitiesReducer = availabilitiesReducer;
var _default = availabilitiesReducer;
exports.default = _default;