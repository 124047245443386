"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearStatus = void 0;

var _typesafeActions = require("typesafe-actions");

const clearStatus = (0, _typesafeActions.createStandardAction)('hmp/admin/CLEAR_STATUS')();
exports.clearStatus = clearStatus;
const apiActions = {
  clearStatus
};