"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.messagesReducer = exports.messageResponsesReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _moment = _interopRequireDefault(require("moment"));

var _state = require("../../types/state.types");

var _messages = require("./messages.types");

const updateMessageResponsesReducer = (0, _immer.default)((draft, messageResponses) => {
  if (!messageResponses) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(messageResponses), Number));
  const ids = Object.keys(messageResponses);

  for (const id of ids) {
    if (draft.byId[id]) {
      const previousMessages = draft.byId[id].messages;
      messageResponses[id].messages = (0, _union.default)(previousMessages, messageResponses[id].messages);
      messageResponses[id].messages = messageResponses[id].messages.sort((a, b) => (0, _moment.default)(a.createDate).unix() - (0, _moment.default)(b.createDate).unix());
    }

    draft.byId[id] = messageResponses[id];
  }
});
const updateMessagesReducer = (0, _immer.default)((draft, messages) => {
  if (!messages) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(messages), Number));
  const ids = Object.keys(messages);

  for (const id of ids) {
    draft.byId[id] = messages[id];
  }
});

const messageResponsesReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_messages.getMessagesAsync.success):
      return updateMessageResponsesReducer(state, action.payload);

    default:
      return state;
  }
};

exports.messageResponsesReducer = messageResponsesReducer;

const messagesReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_messages.updateMessages):
      return updateMessagesReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_messages.sendMessageAsync.success):
      return updateMessagesReducer(state, action.payload);

    default:
      return state;
  }
};

exports.messagesReducer = messagesReducer;
var _default = messagesReducer;
exports.default = _default;