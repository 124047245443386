"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getQnaComments = exports.getCommentComments = exports.getComments = exports.selectComments = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _participants = require("../participants/participants.selectors");

var _schema = _interopRequireDefault(require("../schema"));

var _avatar = require("../avatar/avatar.selectors");

var _flags = require("../flags/flags.selectors");

var _favorites = require("../favorites/favorites.selectors");

var _thumbsups = require("../thumbsups/thumbsups.selectors");

const selectComments = state => {
  return state.entities.comments;
};

exports.selectComments = selectComments;
const getComments = (0, _reselect.createSelector)([selectComments, _participants.selectParticipants], (comments, participants) => {
  if (comments && comments.allIds && comments.allIds.length > 0) {
    const {
      comments: denormalizedComments
    } = (0, _normalizr.denormalize)({
      comments: comments.allIds
    }, _schema.default, {
      comments: comments.byId,
      participants: participants.byId
    });
    return (0, _toArray.default)(denormalizedComments);
  }

  return undefined;
});
exports.getComments = getComments;
const getCommentComments = (0, _reselect.createSelector)([selectComments, _participants.selectParticipants, _avatar.selectAvatars], (comments, participants, avatars) => {
  if (comments && comments.allIds && comments.allIds.length > 0) {
    const {
      comments: denormalizedComments
    } = (0, _normalizr.denormalize)({
      comments: comments.allIds
    }, _schema.default, {
      comments: comments.byId,
      participants: participants.byId,
      avatars: avatars.byId
    });
    const qnaComments = (0, _toArray.default)(denormalizedComments).filter(c => c.type === 'qna' && c.parentCommentId);
    return qnaComments;
  }

  return undefined;
});
exports.getCommentComments = getCommentComments;
const getQnaComments = (0, _reselect.createSelector)([selectComments, _participants.selectParticipants, _avatar.selectAvatars, getCommentComments, _favorites.getCommentFavorites, _flags.getCommentFlags, _thumbsups.getCommentThumbsups], (comments, participants, avatars, childComments, favorites, flags, thumbsups) => {
  if (comments && comments.allIds && comments.allIds.length > 0) {
    const {
      comments: denormalizedComments
    } = (0, _normalizr.denormalize)({
      comments: comments.allIds
    }, _schema.default, {
      comments: comments.byId,
      participants: participants.byId,
      avatars: avatars.byId
    });
    const qnaComments = (0, _toArray.default)(denormalizedComments).filter(c => c.type === 'qna' && !c.parentCommentId);
    const mappedComments = qnaComments.map(comment => {
      if (childComments) {
        comment.comments = childComments.filter(c => c.parentCommentId === comment.id).map(c => {
          c.flags = flags ? flags.filter(f => f.typeId === c.id) : [];
          c.thumbsups = thumbsups ? thumbsups.filter(t => t.typeId === c.id) : [];
          c.favorites = favorites ? favorites.filter(f => f.typeId === c.id) : [];
          return c;
        });
      }

      if (favorites) {
        comment.favorites = favorites.filter(f => f.typeId === comment.id);
      }

      if (flags) {
        comment.flags = flags.filter(f => f.typeId === comment.id);
      }

      if (thumbsups) {
        comment.thumbsups = thumbsups.filter(t => t.typeId === comment.id);
      }

      return comment;
    });
    return mappedComments;
  }

  return undefined;
});
exports.getQnaComments = getQnaComments;
var _default = {
  getComments
};
exports.default = _default;