"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.qnaReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _qna = require("./qna.types");

var _state = require("../../types/state.types");

const updateQnaPostsReducer = (0, _immer.default)((draft, qnaPosts) => {
  if (!qnaPosts) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(qnaPosts), Number));
  const ids = Object.keys(qnaPosts);

  for (const id of ids) {
    draft.byId[id] = qnaPosts[id];
  }
});

const qnaReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_qna.getQnaPostsAsync.success):
      return updateQnaPostsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_qna.getQnaPostAsync.success):
      return updateQnaPostsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_qna.createQnaPostAsync.success):
      return updateQnaPostsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_qna.updateQnaPostAsync.success):
      return updateQnaPostsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_qna.deleteQnaPostAsync.success):
      return updateQnaPostsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_qna.getFlaggedQnaContentAsync.success):
      return updateQnaPostsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_qna.clearQnaPosts):
      return {
        allIds: [],
        byId: {}
      };

    default:
      return state;
  }
};

exports.qnaReducer = qnaReducer;
var _default = qnaReducer;
exports.default = _default;