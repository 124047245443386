"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRequestedEditGoalId = exports.getRequestedEditAppointmentId = exports.getRequestedEditResourceId = exports.getRequestedResourceId = exports.getRequestedParticipantId = exports.getRequestedTestkitId = exports.getRequestedForumsTab = exports.getRequestedParticipantTab = exports.getRequestedForumPostId = exports.getRequestedInboxView = exports.getRequestedInboxFilterBy = exports.getUrlNewOrEditId = exports.getUrlRouteNewOrEdit = exports.getUrlRouteSubpage = exports.getUrlRoutePage = exports.getRequestedAppointmentTab = exports.getRequestedBadgeTab = exports.getRequestedTestkitTab = exports.getRequestedStudyId = exports.selectRouterLocationQueryParams = exports.selectRouterLocationPathname = exports.selectRouterLocation = exports.selectRouter = void 0;

var _ = _interopRequireWildcard(require("lodash"));

var _reactRouterDom = require("react-router-dom");

var _reselect = require("reselect");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const URL_PATTERN = '/study/:studyId/:page/:subpage?/:newOrEdit?/:id?';

const selectRouter = state => {
  return state.router;
};

exports.selectRouter = selectRouter;
const selectRouterLocation = (0, _reselect.createSelector)([selectRouter], router => {
  return router === null || router === void 0 ? void 0 : router.location;
});
exports.selectRouterLocation = selectRouterLocation;
const selectRouterLocationPathname = (0, _reselect.createSelector)([selectRouterLocation], location => {
  return location ? location.pathname : undefined;
});
exports.selectRouterLocationPathname = selectRouterLocationPathname;
const selectRouterLocationQueryParams = (0, _reselect.createSelector)([selectRouterLocation], location => {
  return location === null || location === void 0 ? void 0 : location.query;
});
exports.selectRouterLocationQueryParams = selectRouterLocationQueryParams;
const getRequestedStudyId = (0, _reselect.createSelector)([selectRouterLocationPathname], pathname => {
  if (!pathname) {
    return -1;
  }

  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: '/study/:studyId'
  });

  if (match && match.params && match.params.studyId) {
    if (!isNaN(parseInt(match.params.studyId, 10))) {
      return parseInt(match.params.studyId, 10);
    }
  }

  return -1;
});
exports.getRequestedStudyId = getRequestedStudyId;
const getRequestedTestkitTab = (0, _reselect.createSelector)([selectRouterLocationPathname], pathname => {
  if (!pathname) {
    return '';
  }

  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: '/study/:studyId/testkits/:testkitSubpage'
  });

  if (match && match.params && match.params.testkitSubpage) {
    return match.params.testkitSubpage;
  }

  return '';
});
exports.getRequestedTestkitTab = getRequestedTestkitTab;
const getRequestedBadgeTab = (0, _reselect.createSelector)([selectRouterLocationPathname], pathname => {
  if (!pathname) {
    return '';
  }

  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: '/study/:studyId/badges/:badgeSubpage'
  });

  if (match && match.params && match.params.badgeSubpage) {
    return match.params.badgeSubpage;
  }

  return '';
});
exports.getRequestedBadgeTab = getRequestedBadgeTab;
const getRequestedAppointmentTab = (0, _reselect.createSelector)([selectRouterLocationPathname], pathname => {
  if (!pathname) {
    return '';
  }

  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: '/study/:studyId/appointments/:appointmentSubpage'
  });

  if (match && match.params && match.params.appointmentSubpage) {
    return match.params.appointmentSubpage;
  }

  return '';
});
exports.getRequestedAppointmentTab = getRequestedAppointmentTab;
const getRequestedUrlParams = (0, _reselect.createSelector)([selectRouterLocationPathname], pathname => {
  if (!pathname) {
    return undefined;
  }

  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: URL_PATTERN
  });
  return match === null || match === void 0 ? void 0 : match.params;
});
const getUrlRoutePage = (0, _reselect.createSelector)([getRequestedUrlParams], params => {
  return params === null || params === void 0 ? void 0 : params.page;
});
exports.getUrlRoutePage = getUrlRoutePage;
const getUrlRouteSubpage = (0, _reselect.createSelector)([getRequestedUrlParams], params => {
  return params === null || params === void 0 ? void 0 : params.subpage;
});
exports.getUrlRouteSubpage = getUrlRouteSubpage;
const getUrlRouteNewOrEdit = (0, _reselect.createSelector)([getRequestedUrlParams], params => {
  return params === null || params === void 0 ? void 0 : params.newOrEdit;
});
exports.getUrlRouteNewOrEdit = getUrlRouteNewOrEdit;
const getUrlNewOrEditId = (0, _reselect.createSelector)([getRequestedUrlParams], params => {
  return params === null || params === void 0 ? void 0 : params.id;
});
exports.getUrlNewOrEditId = getUrlNewOrEditId;
const getRequestedInboxFilterBy = (0, _reselect.createSelector)([selectRouterLocationQueryParams], queryParams => {
  return _.get(queryParams, 'filterBy');
});
exports.getRequestedInboxFilterBy = getRequestedInboxFilterBy;
const getRequestedInboxView = (0, _reselect.createSelector)([selectRouterLocationQueryParams], queryParams => {
  return _.get(queryParams, 'view');
});
exports.getRequestedInboxView = getRequestedInboxView;
const getRequestedForumPostId = (0, _reselect.createSelector)([selectRouterLocationPathname], pathname => {
  if (!pathname) {
    return undefined;
  }

  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: '/study/:studyId/forums/:forumSubpage/:postId'
  });

  if (match && match.params && match.params.postId) {
    return parseInt(match.params.postId);
  }

  return undefined;
});
exports.getRequestedForumPostId = getRequestedForumPostId;
const getRequestedParticipantTab = (0, _reselect.createSelector)([selectRouterLocationPathname], pathname => {
  if (!pathname) {
    return '';
  }

  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: '/study/:studyId/participants/:participantId/:participantSubpage'
  });

  if (match && match.params && match.params.participantSubpage) {
    return match.params.participantSubpage;
  }

  return '';
});
exports.getRequestedParticipantTab = getRequestedParticipantTab;
const getRequestedForumsTab = (0, _reselect.createSelector)([selectRouterLocationPathname], pathname => {
  if (!pathname) {
    return '';
  }

  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: '/study/:studyId/forums/:forumsSubpage'
  });

  if (match && match.params && match.params.forumsSubpage) {
    return match.params.forumsSubpage;
  }

  return '';
});
exports.getRequestedForumsTab = getRequestedForumsTab;
const getRequestedTestkitId = (0, _reselect.createSelector)([selectRouterLocationPathname], pathname => {
  if (!pathname) {
    return -1;
  }

  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: '/testkit/:testkitId'
  });

  if (match && match.params && match.params.testkitId) {
    if (!isNaN(parseInt(match.params.testkitId, 10))) {
      return parseInt(match.params.testkitId, 10);
    }
  }

  return -1;
});
exports.getRequestedTestkitId = getRequestedTestkitId;

const getRequestedParticipantId = state => {
  const {
    pathname
  } = state.router.location;
  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: '/study/:studyId/participants/:participantId'
  });

  if (match && match.params && match.params.participantId) {
    if (!isNaN(parseInt(match.params.participantId, 10))) {
      return parseInt(match.params.participantId, 10);
    }
  }

  return -1;
};

exports.getRequestedParticipantId = getRequestedParticipantId;

const getRequestedResourceId = state => {
  const {
    pathname
  } = state.router.location;
  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: '/study/:studyId/resources/:resourceId'
  });

  if (match && match.params && match.params.resourceId) {
    if (!isNaN(parseInt(match.params.resourceId, 10))) {
      return parseInt(match.params.resourceId, 10);
    }
  }

  return -1;
};

exports.getRequestedResourceId = getRequestedResourceId;

const getRequestedEditResourceId = state => {
  const {
    pathname
  } = state.router.location;
  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: '/study/:studyId/resources/edit/:resourceId'
  });

  if (match && match.params && match.params.resourceId) {
    if (!isNaN(parseInt(match.params.resourceId, 10))) {
      return parseInt(match.params.resourceId, 10);
    }
  }

  return -1;
};

exports.getRequestedEditResourceId = getRequestedEditResourceId;

const getRequestedEditAppointmentId = state => {
  const {
    pathname
  } = state.router.location;
  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: "/study/:studyId/appointments/:appointmentId"
  });

  if (match && match.params && match.params.appointmentId) {
    return match.params.appointmentId;
  }

  return "";
};

exports.getRequestedEditAppointmentId = getRequestedEditAppointmentId;

const getRequestedEditGoalId = state => {
  const {
    pathname
  } = state.router.location;
  const match = (0, _reactRouterDom.matchPath)(pathname, {
    path: "/study/:studyId/goals/:goalId"
  });

  if (match && match.params && match.params.goalId) {
    return match.params.goalId;
  }

  return "";
};

exports.getRequestedEditGoalId = getRequestedEditGoalId;