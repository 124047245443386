"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.saveParticipantNoteAsync = exports.getParticipantNotesAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getParticipantNotesAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_PARTICIPANT_NOTES_REQUEST', 'hmp/admin/GET_PARTICIPANT_NOTES_SUCCESS', 'hmp/admin/GET_PARTICIPANT_NOTES_FAILURE')();
exports.getParticipantNotesAsync = getParticipantNotesAsync;
const saveParticipantNoteAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/SAVE_PARTICIPANT_NOTE_REQUEST', 'hmp/admin/SAVE_PARTICIPANT_NOTE_SUCCESS', 'hmp/admin/SAVE_PARTICIPANT_NOTE_FAILURE')();
exports.saveParticipantNoteAsync = saveParticipantNoteAsync;
const notesActions = {
  getParticipantNotesAsync,
  saveParticipantNoteAsync
};