"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("../../redux/api"));

class TopicService {
  static async updateTopic(topic) {
    await (0, _api.default)({
      method: "put",
      url: `/a/topic`,
      data: {
        topic
      }
    });
  }

  static async updateSort(topics) {
    await (0, _api.default)({
      method: "put",
      url: `/a/topic/sort`,
      data: {
        topics
      }
    });
  }

  static async deleteTopic(topicId) {
    await (0, _api.default)({
      method: "delete",
      url: `/a/topic/${topicId}`
    });
  }

}

var _default = TopicService;
exports.default = _default;