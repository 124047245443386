"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.badgesReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _badges = require("./badges.types");

var _state = require("../../types/state.types");

const updateBadgesReducer = (0, _immer.default)((draft, badges) => {
  if (!badges) {
    return;
  } // fully resetting each time, so this will only hold the badges for the most recently selected participant


  draft.byId = [];
  draft.allIds = (0, _keys.default)(badges);
  const ids = Object.keys(badges);

  for (const id of ids) {
    draft.byId[id] = badges[id];
  }
});

const badgesReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_badges.getParticipantBadgesAsync.success):
    case (0, _typesafeActions.getType)(_badges.awardParticipantBadgeAsync.success):
      return updateBadgesReducer(state, action.payload);

    default:
      return state;
  }
};

exports.badgesReducer = badgesReducer;
var _default = badgesReducer;
exports.default = _default;