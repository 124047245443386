"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = forumSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _forum = require("./forum.types");

const getForumDetailsByStudyId = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/forum/${studyId}/details`
  });
};

function* getForumDetailsByStudyIdHandler(action) {
  try {
    const studyId = action.payload;
    const response = yield (0, _effects.call)(getForumDetailsByStudyId, studyId);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.forums);
    const {
      forums
    } = entities;
    yield (0, _effects.put)(_forum.getForumDetailsByStudyIdAsync.success(forums));
  } catch (error) {
    yield (0, _effects.put)(_forum.getForumDetailsByStudyIdAsync.failure(error));
  }
}

function* getForumDetailsByStudyIdWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_forum.getForumDetailsByStudyIdAsync.request), getForumDetailsByStudyIdHandler);
}

function* forumSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getForumDetailsByStudyIdWatcher)]);
}