"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.messageUIReducer = exports.initialMessageUIState = void 0;

var _lodash = require("lodash");

var _typesafeActions = require("typesafe-actions");

var _messages = require("./messages.types");

const initialMessageUIState = {
  threadCount: 0,
  isTypingIndicators: new Map()
};
exports.initialMessageUIState = initialMessageUIState;

const messageUIReducer = (state = initialMessageUIState, action) => {
  let isTypingIndicators;

  switch (action.type) {
    case (0, _typesafeActions.getType)(_messages.updateMessageThreadCount):
      return { ...state,
        threadCount: action.payload
      };

    case (0, _typesafeActions.getType)(_messages.addToIsTypingIndicators):
      isTypingIndicators = _lodash._.clone(state.isTypingIndicators);

      if (!isTypingIndicators.has(action.payload.typingIndicator.threadId)) {
        isTypingIndicators.set(action.payload.typingIndicator.threadId, new Set());
      }

      isTypingIndicators.get(action.payload.typingIndicator.threadId).add(action.payload.typingIndicator.username);
      return { ...state,
        isTypingIndicators
      };

    case (0, _typesafeActions.getType)(_messages.removeFromIsTypingIndicators):
      isTypingIndicators = _lodash._.clone(state.isTypingIndicators);

      if (isTypingIndicators.has(action.payload.typingIndicator.threadId)) {
        isTypingIndicators.get(action.payload.typingIndicator.threadId).delete(action.payload.typingIndicator.username);
      }

      return { ...state,
        isTypingIndicators
      };

    default:
      return state;
  }
};

exports.messageUIReducer = messageUIReducer;
var _default = messageUIReducer;
exports.default = _default;