"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.userSurveyReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _state = require("../../types/state.types");

var _userSurvey = require("./userSurvey.types");

const updateUserSurveysReducer = (0, _immer.default)((draft, userSurveys) => {
  if (!userSurveys) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(userSurveys), Number));
  const ids = Object.keys(userSurveys);

  for (const id of ids) {
    draft.byId[id] = userSurveys[id];
  }
});

const userSurveyReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_userSurvey.getParticipantUserSurveysAsync.success):
      return updateUserSurveysReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_userSurvey.getUserSurveysAsync.success):
      return updateUserSurveysReducer(state, action.payload);

    default:
      return state;
  }
};

exports.userSurveyReducer = userSurveyReducer;
var _default = userSurveyReducer;
exports.default = _default;