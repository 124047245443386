"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = thumbsupSaga;

var _api = _interopRequireDefault(require("../api"));

var _effects = require("@redux-saga/core/effects");

var _normalizr = require("normalizr");

var _schema = require("../schema");

var _thumbsups = require("./thumbsups.types");

var _typesafeActions = require("typesafe-actions");

const getThumbsups = jsonThumbups => {
  const param = JSON.stringify(jsonThumbups);
  return (0, _api.default)({
    method: 'get',
    url: `/a/social/thumbsups?thumbsupsJson=${encodeURIComponent(param)}`
  });
};

function* getThumbsupsHandler(action) {
  try {
    const {
      payload
    } = action;
    const response = yield (0, _effects.call)(getThumbsups, payload);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.thumbsups);
    const {
      thumbsups
    } = entities;
    yield (0, _effects.put)(_thumbsups.getThumbsupsAsync.success(thumbsups));
  } catch (error) {
    yield (0, _effects.put)(_thumbsups.getThumbsupsAsync.failure(error));
  }
}

function* getThumbsupsWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_thumbsups.getThumbsupsAsync.request), getThumbsupsHandler);
}

function* thumbsupSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getThumbsupsWatcher)]);
}