"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAnnouncements = exports.updateAnnouncementAsync = exports.createAnnouncementAsync = exports.getAnnouncementsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getAnnouncementsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_ANNOUNCEMENTS_REQUEST', 'hmp/admin/GET_ANNOUNCEMENTS_SUCCESS', 'hmp/admin/GET_ANNOUNCEMENTS_FAILURE')();
exports.getAnnouncementsAsync = getAnnouncementsAsync;
const createAnnouncementAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/CREATE_ANNOUNCEMENTS_REQUEST', 'hmp/admin/CREATE_ANNOUNCEMENTS_SUCCESS', 'hmp/admin/CREATE_ANNOUNCEMENTS_FAILURE')();
exports.createAnnouncementAsync = createAnnouncementAsync;
const updateAnnouncementAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPDATE_ANNOUNCEMENTS_REQUEST', 'hmp/admin/UPDATE_ANNOUNCEMENTS_SUCCESS', 'hmp/admin/UPDATE_ANNOUNCEMENTS_FAILURE')();
exports.updateAnnouncementAsync = updateAnnouncementAsync;
const updateAnnouncements = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_ANNOUNCEMENTS')();
exports.updateAnnouncements = updateAnnouncements;
const activitiesActions = {
  getAnnouncementsAsync,
  createAnnouncementAsync,
  updateAnnouncementAsync
};