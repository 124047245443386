"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.announcementsReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _announcements = require("./announcements.types");

var _state = require("../../types/state.types");

const updateAnnouncementsReducer = (0, _immer.default)((draft, announcements) => {
  if (!announcements) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(announcements), Number));
  const ids = Object.keys(announcements);

  for (const id of ids) {
    draft.byId[id] = announcements[id];
  }
});

const announcementsReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_announcements.getAnnouncementsAsync.success):
      return updateAnnouncementsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_announcements.createAnnouncementAsync.success):
      return updateAnnouncementsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_announcements.updateAnnouncementAsync.success):
      return updateAnnouncementsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_announcements.updateAnnouncements):
      return updateAnnouncementsReducer(state, action.payload);

    default:
      return state;
  }
};

exports.announcementsReducer = announcementsReducer;
var _default = announcementsReducer;
exports.default = _default;