"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getUserSurveys = exports.selectUserSurveys = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _participants = require("../participants/participants.selectors");

var _schema = _interopRequireDefault(require("../schema"));

const selectUserSurveys = state => {
  return state.entities.userSurveys;
};

exports.selectUserSurveys = selectUserSurveys;
const getUserSurveys = (0, _reselect.createSelector)([selectUserSurveys, _participants.selectParticipants], (userSurveys, participants) => {
  var _a;

  if (((_a = userSurveys === null || userSurveys === void 0 ? void 0 : userSurveys.allIds) === null || _a === void 0 ? void 0 : _a.length) > 0) {
    const {
      userSurveys: denormalizedUserSurveys
    } = (0, _normalizr.denormalize)({
      userSurveys: userSurveys.allIds
    }, _schema.default, {
      userSurveys: userSurveys.byId,
      participants: participants.byId
    });
    return (0, _toArray.default)(denormalizedUserSurveys);
  }

  return undefined;
});
exports.getUserSurveys = getUserSurveys;
var _default = {
  getUserSurveys
};
exports.default = _default;