"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _activities = require("./activities/activities.selectors");

Object.keys(_activities).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _activities[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _activities[key];
    }
  });
});

var _analytics = require("./analitycs/analytics.selectors");

Object.keys(_analytics).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _analytics[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _analytics[key];
    }
  });
});

var _announcements = require("./announcements/announcements.selectors");

Object.keys(_announcements).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _announcements[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _announcements[key];
    }
  });
});

var _api = require("./api/api.selectors");

Object.keys(_api).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _api[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _api[key];
    }
  });
});

var _appointments = require("./appointments/appointments.selectors");

Object.keys(_appointments).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _appointments[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _appointments[key];
    }
  });
});

var _article = require("./article/article.selectors");

Object.keys(_article).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _article[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _article[key];
    }
  });
});

var _availability = require("./availabilty/availability.selectors");

Object.keys(_availability).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _availability[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _availability[key];
    }
  });
});

var _avatar = require("./avatar/avatar.selectors");

Object.keys(_avatar).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _avatar[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _avatar[key];
    }
  });
});

var _badges = require("./badges/badges.selectors");

Object.keys(_badges).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _badges[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _badges[key];
    }
  });
});

var _baseSelectors = require("./baseSelectors");

Object.keys(_baseSelectors).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _baseSelectors[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _baseSelectors[key];
    }
  });
});

var _comments = require("./comments/comments.selectors");

Object.keys(_comments).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _comments[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _comments[key];
    }
  });
});

var _dashboard = require("./dashboard/dashboard.selectors");

Object.keys(_dashboard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _dashboard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _dashboard[key];
    }
  });
});

var _favorites = require("./favorites/favorites.selectors");

Object.keys(_favorites).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _favorites[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _favorites[key];
    }
  });
});

var _flags = require("./flags/flags.selectors");

Object.keys(_flags).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _flags[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _flags[key];
    }
  });
});

var _forum = require("./forum/forum.selectors");

Object.keys(_forum).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _forum[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _forum[key];
    }
  });
});

var _goals = require("./goals/goals.selectors");

Object.keys(_goals).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _goals[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _goals[key];
    }
  });
});

var _messages = require("./messages/messages.selectors");

Object.keys(_messages).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _messages[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _messages[key];
    }
  });
});

var _notes = require("./notes/notes.selectors");

Object.keys(_notes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _notes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _notes[key];
    }
  });
});

var _notification = require("./notification/notification.selectors");

Object.keys(_notification).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _notification[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _notification[key];
    }
  });
});

var _participants = require("./participants/participants.selectors");

Object.keys(_participants).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _participants[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _participants[key];
    }
  });
});

var _payment = require("./payment/payment.selectors");

Object.keys(_payment).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _payment[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _payment[key];
    }
  });
});

var _posts = require("./posts/posts.selectors");

Object.keys(_posts).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _posts[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _posts[key];
    }
  });
});

var _qna = require("./qna/qna.selectors");

Object.keys(_qna).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _qna[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _qna[key];
    }
  });
});

var _router = require("./router/router.selectors");

Object.keys(_router).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _router[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _router[key];
    }
  });
});

var _sidebar = require("./sidebar/sidebar.selectors");

Object.keys(_sidebar).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _sidebar[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _sidebar[key];
    }
  });
});

var _study = require("./study/study.selectors");

Object.keys(_study).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _study[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _study[key];
    }
  });
});

var _testkit = require("./testkit/testkit.selectors");

Object.keys(_testkit).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _testkit[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _testkit[key];
    }
  });
});

var _thumbsups = require("./thumbsups/thumbsups.selectors");

Object.keys(_thumbsups).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _thumbsups[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _thumbsups[key];
    }
  });
});

var _topic = require("./topics/topic.selectors");

Object.keys(_topic).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _topic[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _topic[key];
    }
  });
});

var _user = require("./user/user.selectors");

Object.keys(_user).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _user[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _user[key];
    }
  });
});

var _userSurvey = require("./userSurvey/userSurvey.selectors");

Object.keys(_userSurvey).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _userSurvey[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _userSurvey[key];
    }
  });
});