"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getMessageIsTypingIndicators = exports.getMessageThreadCount = exports.getMessages = exports.getMessageResponses = exports.getMessageThreads = exports.selectMessageResponses = exports.selectMessages = exports.selectThreads = void 0;

var _lodash = require("lodash");

var _normalizr = require("normalizr");

var _reselect = require("reselect");

var _avatar = require("../../redux/avatar/avatar.selectors");

var _participants = require("../../redux/participants/participants.selectors");

var _schema = _interopRequireDefault(require("../schema"));

const selectThreads = state => {
  return state.entities.threads;
};

exports.selectThreads = selectThreads;

const selectMessages = state => {
  return state.entities.messages;
};

exports.selectMessages = selectMessages;

const selectMessageResponses = state => {
  return state.entities.messageResponses;
};

exports.selectMessageResponses = selectMessageResponses;
const getMessageThreads = (0, _reselect.createSelector)([selectThreads, _participants.selectParticipants, _avatar.selectAvatars], (threads, participants, avatars) => {
  if (threads && threads.allIds && threads.allIds.length > 0) {
    const {
      threads: denormalizedThreads
    } = (0, _normalizr.denormalize)({
      threads: threads.allIds
    }, _schema.default, {
      threads: threads.byId,
      participants: participants.byId,
      avatars: avatars.byId
    });
    return _lodash._.toArray(denormalizedThreads);
  }

  return undefined;
});
exports.getMessageThreads = getMessageThreads;
const getMessageResponses = (0, _reselect.createSelector)([selectMessageResponses, selectMessages], (messageResponses, messages) => {
  if (messageResponses && messageResponses.allIds && messageResponses.allIds.length > 0) {
    const denormalization = (0, _normalizr.denormalize)({
      messageResponses: messageResponses.allIds
    }, _schema.default, {
      messageResponses: messageResponses.byId,
      messages: messages.byId
    });
    const {
      messageResponses: denormalizedMessages
    } = denormalization;
    return _lodash._.toArray(denormalizedMessages);
  }

  return undefined;
});
exports.getMessageResponses = getMessageResponses;
const getMessages = (0, _reselect.createSelector)([selectMessages], messages => {
  if (messages && messages.allIds && messages.allIds.length > 0) {
    const denormalization = (0, _normalizr.denormalize)({
      messages: messages.allIds
    }, _schema.default, {
      messages: messages.byId
    });
    const {
      messages: denormalizedMessages
    } = denormalization;
    return _lodash._.toArray(denormalizedMessages);
  }

  return undefined;
});
exports.getMessages = getMessages;

const getMessageThreadCount = state => {
  return state.ui.messages.threadCount;
};

exports.getMessageThreadCount = getMessageThreadCount;

const getMessageIsTypingIndicators = state => {
  return state.ui.messages.isTypingIndicators;
};

exports.getMessageIsTypingIndicators = getMessageIsTypingIndicators;
var _default = {
  getMessageThreads,
  getMessageResponses
};
exports.default = _default;