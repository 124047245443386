"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = topicSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _topic = require("./topic.types");

// const getTopics = (topicType: {type: string, typeId: number}) => {
//   return axios({
//     method: 'get',
//     url: `/a/topic/${topicType.type}/${topicType.typeId}/topicStructure`
//   });
// };
const getResourceTopics = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/topic/knowledgecenter/1/topicStructure`
  });
};

const getForumTopics = forumId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/topic/forum/${forumId}/topicStructure`
  });
};

const getQnaTopics = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/topic/qna/1/topicStructure`
  });
};

const getGoalTopics = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/topic/goal/1/topicStructure`
  });
};

function* loadResourceTopicsHandler(action) {
  try {
    // const topicType: any = action.payload;
    // const response: AxiosResponse = (yield call(getTopics, topicType)) as AxiosResponse;
    const response = yield (0, _effects.call)(getResourceTopics);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.topics);
    const {
      topics
    } = entities;
    yield (0, _effects.put)(_topic.loadResourceTopicsAsync.success(topics));
  } catch (error) {
    yield (0, _effects.put)(_topic.loadResourceTopicsAsync.failure(error));
  }
}

function* loadForumTopicsHandler(action) {
  try {
    const forumId = action.payload;
    const response = yield (0, _effects.call)(getForumTopics, forumId);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.topics);
    const {
      topics
    } = entities;
    yield (0, _effects.put)(_topic.loadForumTopicsAsync.success(topics));
  } catch (error) {
    yield (0, _effects.put)(_topic.loadForumTopicsAsync.failure(error));
  }
}

function* loadQnaTopicsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getQnaTopics);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.topics);
    const {
      topics
    } = entities;
    yield (0, _effects.put)(_topic.loadQnaTopicsAsync.success(topics));
  } catch (error) {
    yield (0, _effects.put)(_topic.loadQnaTopicsAsync.failure(error));
  }
}

function* loadGoalTopicsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getGoalTopics);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.topics);
    const {
      topics
    } = entities;
    yield (0, _effects.put)(_topic.loadGoalTopicsAsync.success(topics));
  } catch (error) {
    yield (0, _effects.put)(_topic.loadGoalTopicsAsync.failure(error));
  }
}

function* loadResourceTopicsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_topic.loadResourceTopicsAsync.request), loadResourceTopicsHandler);
}

function* loadForumTopicsWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_topic.loadForumTopicsAsync.request), loadForumTopicsHandler);
}

function* loadQnaTopicsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_topic.loadQnaTopicsAsync.request), loadQnaTopicsHandler);
}

function* loadGoalTopicsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_topic.loadGoalTopicsAsync.request), loadGoalTopicsHandler);
}

function* topicSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(loadResourceTopicsWatcher)]);
  yield (0, _effects.all)([(0, _effects.fork)(loadForumTopicsWatcher)]);
  yield (0, _effects.all)([(0, _effects.fork)(loadQnaTopicsWatcher)]);
  yield (0, _effects.all)([(0, _effects.fork)(loadGoalTopicsWatcher)]);
}