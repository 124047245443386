"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDashboardData = exports.selectDashboard = void 0;

var _reselect = require("reselect");

const selectDashboard = state => {
  return state.dashboard;
};

exports.selectDashboard = selectDashboard;
const getDashboardData = (0, _reselect.createSelector)([selectDashboard], dashboardData => {
  return dashboardData;
});
exports.getDashboardData = getDashboardData;