"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAppointments = exports.deleteAppointmentAsync = exports.updateAppointmentAsync = exports.createAppointmentAsync = exports.getAppointmentsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getAppointmentsAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/GET_APPOINTMENTS_REQUEST", "hmp/admin/GET_APPOINTMENTS_SUCCESS", "hmp/admin/GET_APPOINTMENTS_FAILURE")();
exports.getAppointmentsAsync = getAppointmentsAsync;
const createAppointmentAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/CREATE_APPOINTMENTS_REQUEST", "hmp/admin/CREATE_APPOINTMENTS_SUCCESS", "hmp/admin/CREATE_APPOINTMENTS_FAILURE")();
exports.createAppointmentAsync = createAppointmentAsync;
const updateAppointmentAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/UPDATE_APPOINTMENTS_REQUEST", "hmp/admin/UPDATE_APPOINTMENTS_SUCCESS", "hmp/admin/UPDATE_APPOINTMENTS_FAILURE")();
exports.updateAppointmentAsync = updateAppointmentAsync;
const deleteAppointmentAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/DELETE_APPOINTMENTS_REQUEST", "hmp/admin/DELETE_APPOINTMENTS_SUCCESS", "hmp/admin/DELETE_APPOINTMENTS_FAILURE")();
exports.deleteAppointmentAsync = deleteAppointmentAsync;
const updateAppointments = (0, _typesafeActions.createStandardAction)("hmp/admin/UPDATE_APPOINTMENTS")();
exports.updateAppointments = updateAppointments;
const activitiesActions = {
  getAppointmentsAsync,
  createAppointmentAsync,
  updateAppointmentAsync,
  deleteAppointmentAsync
};