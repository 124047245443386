"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getNotifications = exports.selectNotifications = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _participants = require("../participants/participants.selectors");

var _schema = _interopRequireDefault(require("../schema"));

const selectNotifications = state => {
  return state.entities.notifications;
};

exports.selectNotifications = selectNotifications;
const getNotifications = (0, _reselect.createSelector)([selectNotifications, _participants.selectParticipants], (notifications, participants) => {
  if (notifications && notifications.allIds && notifications.allIds.length > 0) {
    const {
      notifications: denormalizedNotifications
    } = (0, _normalizr.denormalize)({
      notifications: notifications.allIds
    }, _schema.default, {
      notifications: notifications.byId,
      participants: participants.byId
    });
    return (0, _toArray.default)(denormalizedNotifications);
  }

  return undefined;
});
exports.getNotifications = getNotifications;
var _default = {
  getNotifications
};
exports.default = _default;