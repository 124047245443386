"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getAsyncActionName = void 0;

/**
 * This utility function will extract the base action name from an asynchronous action creator like...
 * 'hmp/admin/USER_REQUEST', 'hmp/admin/USER_SUCCESS', 'hmp/admin/USER_FAILURE', and return 'USER'
 *
 * For synchronous action creators like 'hmp/admin/SIDEBAR_TOGGLE' and non 'hmp/admin/' actions like
 * '@@INIT' or 'redux-oidc/LOADING_USER' undefined will be returned.
 *
 * @param type the Redux Action type string
 */
const getAsyncActionName = type => {
  if (!type) {
    return undefined;
  }

  const pattern = /^hmp\/admin\/(.*)\_(REQUEST|SUCCESS|FAILURE|CANCEL)$/;

  if (type.match(pattern)) {
    return type.replace(pattern, '$1');
  }

  return undefined;
};

exports.getAsyncActionName = getAsyncActionName;
var _default = getAsyncActionName;
exports.default = _default;