"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getStatus = void 0;

var _lodash = require("lodash");

var _api = _interopRequireDefault(require("./api.util"));

const getStatus = (state, type) => {
  const actionName = (0, _api.default)(type);

  if (!actionName) {
    return undefined;
  }

  return (0, _lodash.get)(state, `api.${actionName}`);
};

exports.getStatus = getStatus;
var _default = getStatus;
exports.default = _default;