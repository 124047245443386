"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.createJsonParamFromPosts = exports.createJsonParam = void 0;

var _lodash = require("lodash");

const createJsonParam = (type, ids) => {
  return {
    type,
    ids
  };
};

exports.createJsonParam = createJsonParam;

const createJsonParamFromPosts = posts => {
  return createJsonParam('post', _lodash._.map(posts, 'id'));
};

exports.createJsonParamFromPosts = createJsonParamFromPosts;
var _default = createJsonParamFromPosts;
exports.default = _default;