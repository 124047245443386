"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.dashboardReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _dashboard = require("./dashboard.types");

const initialDashboardData = {
  testkits: {
    requestedCount: -1,
    needsReviewCount: -1,
    seroconversionCount: -1,
    awaitingResultCount: -1,
    completedCount: -1
  },
  payments: {
    unpaid: -1
  },
  forum: {
    uncategorized: -1,
    flagged: -1
  },
  inbox: {
    unreadThreads: -1,
    unreadMyThreads: -1,
    unreadAssignedThreads: -1
  },
  qnas: {
    unansweredCount: -1
  },
  forms: {
    openFormsCount: -1
  }
};

const dashboardReducer = (state = initialDashboardData, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_dashboard.loadDashboardDataAsync.success):
      return action.payload;

    default:
      return state;
  }
};

exports.dashboardReducer = dashboardReducer;
var _default = dashboardReducer;
exports.default = _default;