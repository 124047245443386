"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.flagsReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _state = require("../../types/state.types");

var _flags = require("./flags.types");

const updateFlagsReducer = (0, _immer.default)((draft, flags) => {
  if (!flags) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(flags), Number));
  const ids = Object.keys(flags);

  for (const id of ids) {
    draft.byId[id] = flags[id];
  }
});

const flagsReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_flags.getFlagsAsync.success):
      return updateFlagsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_flags.resolveFlagsAsync.success):
      return updateFlagsReducer(state, action.payload);

    default:
      return state;
  }
};

exports.flagsReducer = flagsReducer;
var _default = flagsReducer;
exports.default = _default;