"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _messages = require("./messages/messages.types");

var WebSocketClient = require('websocket').w3cwebsocket;

const wsApiUrl = process.env.WS_API_URL || `wss://${window.location.host}`;
const DEBUG = false;
const MILLIS_WAIT_ON_SERVER_TO_RESPOND = 5000; // a longer timeout to wait on server to respond

const MILLIS_WAIT_ON_TOKEN = 250; // a short interval for checking to see if token exists

class ClientWebSocketManager {
  constructor(store) {
    ClientWebSocketManager.store = store;
  }

  static registerObservingMessageThread(threadId) {
    if (DEBUG) console.log(`registerObservingMessageThread ${threadId}`);
    const msg = {
      type: 'observe-thread',
      meta: {
        threadId
      }
    };
    ClientWebSocketManager.sendMessage(msg);
  }

  static unregisterObservingMessageThread(threadId) {
    if (DEBUG) console.log(`unregisterObservingMessageThread ${threadId}`);
    const msg = {
      type: 'unobserve-thread',
      meta: {
        threadId
      }
    };
    ClientWebSocketManager.sendMessage(msg);
  }

  static sendTypingIndicator(threadId) {
    if (DEBUG) console.log(`sendTypingIndicator ${threadId}`);
    const msg = {
      type: 'is-typing',
      meta: {
        threadId
      }
    };
    ClientWebSocketManager.sendMessage(msg);
  }

  static sendNotTypingIndicator(threadId) {
    if (DEBUG) console.log(`sendNotTypingIndicator ${threadId}`);
    const msg = {
      type: 'is-not-typing',
      meta: {
        threadId
      }
    };
    ClientWebSocketManager.sendMessage(msg);
  }

  static sendMessage(msg) {
    ClientWebSocketManager.client.send(JSON.stringify(msg));
  }

} // First we'll loop this init interval until we have a token


ClientWebSocketManager.init = () => {
  if (DEBUG) console.log("initialize websocket");
  clearInterval(ClientWebSocketManager.initIntervalObj);
  ClientWebSocketManager.initIntervalObj = setInterval(async () => {
    var _a, _b;

    const token = (_b = (_a = ClientWebSocketManager.store.getState().oidc) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id_token;

    if (!token) {
      if (DEBUG) console.log('No token, return');
      return; //  if no token do nothing, wait for next interval
    }

    if (ClientWebSocketManager.retrySemaphore) {
      // a connection is in progress, wait for next interval
      if (DEBUG) console.log(`retrySemaphore is on, return`);
      return;
    }

    ClientWebSocketManager.createConnection();
  }, MILLIS_WAIT_ON_TOKEN);
}; // Next we loop this createConnection interval until we have a connection


ClientWebSocketManager.createConnection = () => {
  if (DEBUG) console.log("create websocket connection");

  if (ClientWebSocketManager.retrySemaphore) {
    // a connection is in progress, wait for next interval
    if (DEBUG) console.log("retrySemaphore is on, return");
    return;
  }

  if (DEBUG) console.log("create new websocket client");
  ClientWebSocketManager.client = new WebSocketClient(wsApiUrl, 'echo-protocol');
  ClientWebSocketManager.retrySemaphore = setTimeout(async () => {
    if (DEBUG) console.log(`MILLIS_WAIT_ON_SERVER_TO_RESPOND (${MILLIS_WAIT_ON_SERVER_TO_RESPOND}) hit, delete retrySemaphore obj`);
    ClientWebSocketManager.retrySemaphore = undefined;
  }, MILLIS_WAIT_ON_SERVER_TO_RESPOND);

  ClientWebSocketManager.client.onopen = () => {
    var _a, _b;

    if (DEBUG) console.log("websocket connection onopen");
    clearInterval(ClientWebSocketManager.initIntervalObj);
    const token = (_b = (_a = ClientWebSocketManager.store.getState().oidc) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id_token;

    if (token) {
      ClientWebSocketManager.sendMessage({
        type: 'auth',
        meta: {
          token
        }
      });
    } else {
      //shouldn't get here, we established that we had a token before calling, but if we did get here then start over
      ClientWebSocketManager.init();
    }
  };

  ClientWebSocketManager.client.onmessage = ({
    data
  }) => {
    const msg = JSON.parse(data);
    ClientWebSocketManager.handleMessage(msg);
  };

  ClientWebSocketManager.client.onclose = () => {
    if (DEBUG) console.log("websocket connection onclose");
    ClientWebSocketManager.closeAndReInit();
  };
};

ClientWebSocketManager.closeAndReInit = () => {
  if (DEBUG) console.log('close websocket and reinitialize');
  clearTimeout(ClientWebSocketManager.retrySemaphore);
  ClientWebSocketManager.retrySemaphore = null;
  ClientWebSocketManager.init();
};

ClientWebSocketManager.handleMessage = message => {
  if (DEBUG) console.log('handle websocket message', message);

  if (message.type === 'auth-success' || message.type === 'connect-success') {//noop
  } else if (message.type === 'new-inbox-message') {
    const threadId = parseInt(message.meta.threadId);
    ClientWebSocketManager.store.dispatch((0, _messages.refreshInboxAndThread)(threadId));
  } else if (message.type === 'new-inbox-thread') {
    ClientWebSocketManager.store.dispatch((0, _messages.refreshInbox)());
  } else if (message.type === 'is-typing') {
    const typingIndicator = message.meta;
    ClientWebSocketManager.store.dispatch((0, _messages.addToIsTypingIndicators)({
      typingIndicator
    }));
  } else if (message.type === 'is-not-typing') {
    const typingIndicator = message.meta;
    ClientWebSocketManager.store.dispatch((0, _messages.removeFromIsTypingIndicators)({
      typingIndicator
    }));
  } else {
    throw Error(`Unrecognized websocket message type: ${JSON.stringify(message)}`);
  }
};

var _default = ClientWebSocketManager;
exports.default = _default;