"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.isSidebarCollapsed = exports.selectSidebarState = void 0;

var _reselect = require("reselect");

const selectSidebarState = state => {
  return state.ui.sidebar;
};

exports.selectSidebarState = selectSidebarState;
const isSidebarCollapsed = (0, _reselect.createSelector)([selectSidebarState], sidebarState => {
  if (sidebarState) {
    return sidebarState.collapsed;
  }

  return false;
});
exports.isSidebarCollapsed = isSidebarCollapsed;
var _default = selectSidebarState;
exports.default = _default;