"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateGoalsUIState = exports.clearGoals = exports.updateGoals = exports.deleteGoalAsync = exports.updateGoalAsync = exports.createGoalAsync = exports.getGoalAsync = exports.getGoalsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getGoalsAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/GET_GOALS_REQUEST", "hmp/admin/GET_GOALS_SUCCESS", "hmp/admin/GET_GOALS_FAILURE")();
exports.getGoalsAsync = getGoalsAsync;
const getGoalAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/GET_GOAL_REQUEST", "hmp/admin/GET_GOAL_SUCCESS", "hmp/admin/GET_GOAL_FAILURE")();
exports.getGoalAsync = getGoalAsync;
const createGoalAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/CREATE_GOAL_REQUEST", "hmp/admin/CREATE_GOAL_SUCCESS", "hmp/admin/CREATE_GOAL_FAILURE")();
exports.createGoalAsync = createGoalAsync;
const updateGoalAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/UPDATE_GOAL_REQUEST", "hmp/admin/UPDATE_GOAL_SUCCESS", "hmp/admin/UPDATE_GOAL_FAILURE")();
exports.updateGoalAsync = updateGoalAsync;
const deleteGoalAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/DELETE_GOAL_REQUEST", "hmp/admin/DELETE_GOAL_SUCCESS", "hmp/admin/DELETE_GOAL_FAILURE")();
exports.deleteGoalAsync = deleteGoalAsync;
const updateGoals = (0, _typesafeActions.createStandardAction)("hmp/admin/UPDATE_GOALS")();
exports.updateGoals = updateGoals;
const clearGoals = (0, _typesafeActions.createStandardAction)("hmp/admin/CLEAR_GOALS")();
exports.clearGoals = clearGoals;
const updateGoalsUIState = (0, _typesafeActions.createStandardAction)("hmp/admin/UPDATE_GOAL_UI_STATE")();
exports.updateGoalsUIState = updateGoalsUIState;
const goalsActions = {
  getGoalsAsync,
  getGoalAsync,
  createGoalAsync,
  updateGoalAsync,
  deleteGoalAsync,
  updateGoals,
  clearGoals,
  updateGoalsUIState
};