"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _connectedReactRouter = require("connected-react-router");

var _reduxOidc = require("redux-oidc");

var _redux = require("redux");

var _analyticsUi = _interopRequireDefault(require("./analitycs/analytics.ui.reducer"));

var _api = require("./api/api.reducer");

var _article = require("./article/article.reducer");

var _articleUi = _interopRequireDefault(require("./article/article.ui.reducer"));

var _avatar = require("./avatar/avatar.reducer");

var _dashboard = _interopRequireDefault(require("./dashboard/dashboard.reducer"));

var _notification = require("./notification/notification.reducer");

var _participants = require("./participants/participants.reducer");

var _payment = _interopRequireDefault(require("./payment/payment.reducer"));

var _posts = require("./posts/posts.reducer");

var _forumUi = _interopRequireDefault(require("./forum/forum.ui.reducer"));

var _studyUi = _interopRequireDefault(require("./study/study.ui.reducer"));

var _sidebar = require("./sidebar/sidebar.reducer");

var _study = _interopRequireDefault(require("./study/study.reducer"));

var _studyArms = _interopRequireDefault(require("./study/studyArms.reducer"));

var _topic = _interopRequireDefault(require("./topics/topic.reducer"));

var _user = require("./user/user.reducer");

var _form = _interopRequireDefault(require("./form/form.reducer"));

var _comments = _interopRequireDefault(require("./comments/comments.reducer"));

var _messages = require("./messages/messages.reducer");

var _threads = _interopRequireDefault(require("./messages/threads.reducer"));

var _testkit = _interopRequireDefault(require("./testkit/testkit.reducer"));

var _flags = _interopRequireDefault(require("./flags/flags.reducer"));

var _thumbsups = _interopRequireDefault(require("./thumbsups/thumbsups.reducer"));

var _favorites = _interopRequireDefault(require("./favorites/favorites.reducer"));

var _notes = _interopRequireDefault(require("./notes/notes.reducer"));

var _activities = require("./activities/activities.reducer");

var _announcements = _interopRequireDefault(require("./announcements/announcements.reducer"));

var _userSurvey = _interopRequireDefault(require("./userSurvey/userSurvey.reducer"));

var _qna = _interopRequireDefault(require("./qna/qna.reducer"));

var _qnaUi = _interopRequireDefault(require("./qna/qna.ui.reducer"));

var _messagesUi = _interopRequireDefault(require("./messages/messages.ui.reducer"));

var _forum = _interopRequireDefault(require("./forum/forum.reducer"));

var _appointments = _interopRequireDefault(require("./appointments/appointments.reducer"));

var _goals = _interopRequireDefault(require("./goals/goals.reducer"));

var _goalsUi = _interopRequireDefault(require("./goals/goals.ui.reducer"));

var _badgeGroups = _interopRequireDefault(require("./badges/badgeGroups.reducer"));

var _badgeCollectionTypes = _interopRequireDefault(require("./badges/badgeCollectionTypes.reducer"));

var _badgeCollections = _interopRequireDefault(require("./badges/badgeCollections.reducer"));

var _badges = _interopRequireDefault(require("./badges/badges.reducer"));

var _availability = _interopRequireDefault(require("./availabilty/availability.reducer"));

const rootReducer = history => {
  return (0, _redux.combineReducers)({
    api: _api.apiReducer,
    dashboard: _dashboard.default,
    auth: (0, _redux.combineReducers)({
      user: _user.userReducer
    }),
    entities: (0, _redux.combineReducers)({
      activities: _activities.activitiesReducer,
      activityCategories: _activities.activityCategoriesReducer,
      activityTypes: _activities.activityTypesReducer,
      admin: _user.adminUserReducer,
      avatars: _avatar.avatarReducer,
      articles: _article.articleReducer,
      comments: _comments.default,
      messages: _messages.messagesReducer,
      messageResponses: _messages.messageResponsesReducer,
      threads: _threads.default,
      participants: _participants.participantsReducer,
      notes: _notes.default,
      notifications: _notification.notificationsReducer,
      posts: _posts.postsReducer,
      studies: _study.default,
      studyArms: _studyArms.default,
      testkits: _testkit.default,
      topics: _topic.default,
      forms: _form.default,
      badges: _badges.default,
      badgeGroups: _badgeGroups.default,
      badgeCollections: _badgeCollections.default,
      badgeCollectionTypes: _badgeCollectionTypes.default,
      flags: _flags.default,
      thumbsups: _thumbsups.default,
      favorites: _favorites.default,
      payments: _payment.default,
      announcements: _announcements.default,
      userSurveys: _userSurvey.default,
      qnaPosts: _qna.default,
      adminRoles: _user.adminRoleReducer,
      forums: _forum.default,
      appointments: _appointments.default,
      availabilities: _availability.default,
      goals: _goals.default
    }),
    router: (0, _connectedReactRouter.connectRouter)(history),
    oidc: _reduxOidc.reducer,
    ui: (0, _redux.combineReducers)({
      articles: _articleUi.default,
      sidebar: _sidebar.sideBarReducer,
      forums: _forumUi.default,
      study: _studyUi.default,
      qnas: _qnaUi.default,
      messages: _messagesUi.default,
      reports: (0, _redux.combineReducers)({
        analytics: _analyticsUi.default
      }),
      goals: _goalsUi.default
    })
  });
};

var _default = rootReducer;
exports.default = _default;