"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getPayments = exports.selectPayments = void 0;

var _lodash = require("lodash");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _reselect = require("reselect");

var _participants = require("../participants/participants.selectors");

var _schema = require("../schema");

const selectPayments = state => {
  return state.entities.payments;
};

exports.selectPayments = selectPayments;
const getPayments = (0, _reselect.createSelector)([selectPayments, _participants.selectParticipants], (payments, participants) => {
  var _a, _b;

  if (((_a = payments === null || payments === void 0 ? void 0 : payments.allIds) === null || _a === void 0 ? void 0 : _a.length) && ((_b = participants === null || participants === void 0 ? void 0 : participants.allIds) === null || _b === void 0 ? void 0 : _b.length)) {
    const {
      payments: denormalizedPayments
    } = (0, _normalizr.denormalize)({
      payments: payments.allIds
    }, _schema.entitySchema, {
      payments: payments.byId
    });

    _lodash._.forEach(denormalizedPayments, payment => {
      payment.participant = participants.byId[payment.participantId];
    });

    return (0, _toArray.default)(denormalizedPayments);
  }

  return [];
});
exports.getPayments = getPayments;
var _default = {
  getPayments,
  selectPayments
};
exports.default = _default;