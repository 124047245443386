"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateComments = exports.updateCommentAsync = exports.createCommentAsync = exports.getCommentsAsync = exports.getParticipantCommentsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getParticipantCommentsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_PARTICIPANT_COMMENTS_REQUEST', 'hmp/admin/GET_PARTICIPANT_COMMENTS_SUCCESS', 'hmp/admin/GET_PARTICIPANT_COMMENTS_FAILURE')();
exports.getParticipantCommentsAsync = getParticipantCommentsAsync;
const getCommentsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_COMMENTS_REQUEST', 'hmp/admin/GET_COMMENTS_SUCCESS', 'hmp/admin/GET_COMMENTS_FAILURE')();
exports.getCommentsAsync = getCommentsAsync;
const createCommentAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/CREATE_COMMENT_REQUEST', 'hmp/admin/CREATE_COMMENT_SUCCESS', 'hmp/admin/CREATE_COMMENT_FAILURE')();
exports.createCommentAsync = createCommentAsync;
const updateCommentAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPDATE_COMMENT_REQUEST', 'hmp/admin/UPDATE_COMMENT_SUCCESS', 'hmp/admin/UPDATE_COMMENT_FAILURE')();
exports.updateCommentAsync = updateCommentAsync;
const updateComments = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_COMMENTS')();
exports.updateComments = updateComments;
const commentsActions = {
  getParticipantCommentsAsync,
  getCommentsAsync,
  createCommentAsync,
  updateCommentAsync,
  updateComments
};