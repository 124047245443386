"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unhideContentAsync = exports.hideContentAsync = exports.resolveFlagsAsync = exports.getFlagsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getFlagsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_FLAGS_REQUEST', 'hmp/admin/GET_FLAGS_SUCCESS', 'hmp/admin/GET_FLAGS_FAILURE')();
exports.getFlagsAsync = getFlagsAsync;
const resolveFlagsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/RESOLVE_FLAG_REQUEST', 'hmp/admin/RESOLVE_FLAG_SUCCESS', 'hmp/admin/RESOLVE_FLAG_FAILURE')();
exports.resolveFlagsAsync = resolveFlagsAsync;
const hideContentAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/HIDE_CONTENT_REQUEST', 'hmp/admin/HIDE_CONTENT_SUCCESS', 'hmp/admin/HIDE_CONTENT_FAILURE')();
exports.hideContentAsync = hideContentAsync;
const unhideContentAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UNHIDE_CONTENT_REQUEST', 'hmp/admin/UNHIDE_CONTENT_SUCCESS', 'hmp/admin/UNHIDE_CONTENT_FAILURE')();
exports.unhideContentAsync = unhideContentAsync;
const flagsActions = {
  getFlagsAsync,
  resolveFlagsAsync,
  hideContentAsync,
  unhideContentAsync
};