"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.notificationsReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _state = require("../../types/state.types");

var _notification = require("./notification.types");

const updateNotificationsReducer = (0, _immer.default)((draft, notifications) => {
  if (!notifications) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(notifications), Number));
  const ids = Object.keys(notifications);

  for (const id of ids) {
    draft.byId[id] = notifications[id];
  }
});

const notificationsReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_notification.getParticipantNotificationsAsync.success):
      return updateNotificationsReducer(state, action.payload);

    default:
      return state;
  }
};

exports.notificationsReducer = notificationsReducer;
var _default = notificationsReducer;
exports.default = _default;