"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = dashbordSaga;

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _dashboard = require("./dashboard.types");

const getDashboardData = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/dashboard/1`
  });
};

function* loadDashboardDataHandler(action) {
  try {
    const response = yield (0, _effects.call)(getDashboardData);
    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.success(response.data));
  } catch (error) {
    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.failure(error));
  }
}

function* loadDashboardDataWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_dashboard.loadDashboardDataAsync.request), loadDashboardDataHandler);
}

function* dashbordSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(loadDashboardDataWatcher)]);
}