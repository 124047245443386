"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.qnaUIReducer = exports.initialQnaUIState = void 0;

var _typesafeActions = require("typesafe-actions");

var _qna = require("./qna.types");

const initialQnaUIState = {
  postCount: 0
};
exports.initialQnaUIState = initialQnaUIState;

const qnaUIReducer = (state = initialQnaUIState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_qna.getQnaPostCountAsync.success):
      return { ...state,
        postCount: action.payload
      };

    default:
      return state;
  }
};

exports.qnaUIReducer = qnaUIReducer;
var _default = qnaUIReducer;
exports.default = _default;