"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadGoalTopicsAsync = exports.loadQnaTopicsAsync = exports.loadForumTopicsAsync = exports.loadResourceTopicsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const loadResourceTopicsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_RESOURCE_TOPICS_REQUEST', 'hmp/admin/LOAD_RESOURCE_TOPICS_SUCCESS', 'hmp/admin/LOAD_RESOURCE_TOPICS_FAILURE')();
exports.loadResourceTopicsAsync = loadResourceTopicsAsync;
const loadForumTopicsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_FORUM_TOPICS_REQUEST', 'hmp/admin/LOAD_FORUM_TOPICS_SUCCESS', 'hmp/admin/LOAD_FORUM_TOPICS_FAILURE')();
exports.loadForumTopicsAsync = loadForumTopicsAsync;
const loadQnaTopicsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_QNA_TOPICS_REQUEST', 'hmp/admin/LOAD_QNA_TOPICS_SUCCESS', 'hmp/admin/LOAD_QNA_TOPICS_FAILURE')();
exports.loadQnaTopicsAsync = loadQnaTopicsAsync;
const loadGoalTopicsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_GOAL_TOPICS_REQUEST', 'hmp/admin/LOAD_GOAL_TOPICS_SUCCESS', 'hmp/admin/LOAD_GOAL_TOPICS_FAILURE')();
exports.loadGoalTopicsAsync = loadGoalTopicsAsync;
const topicActions = {
  loadResourceTopicsAsync,
  loadForumTopicsAsync,
  loadQnaTopicsAsync,
  loadGoalTopicsAsync
};