"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getAppointments = exports.selectAppointments = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = _interopRequireDefault(require("../schema"));

const selectAppointments = state => {
  return state.entities.appointments;
};

exports.selectAppointments = selectAppointments;
const getAppointments = (0, _reselect.createSelector)([selectAppointments], appointments => {
  if (appointments && appointments.allIds && appointments.allIds.length > 0) {
    const {
      appointments: denormalizedAppointments
    } = (0, _normalizr.denormalize)({
      appointments: appointments.allIds
    }, _schema.default, {
      appointments: appointments.byId
    });
    return (0, _toArray.default)(denormalizedAppointments);
  }

  return undefined;
});
exports.getAppointments = getAppointments;
var _default = {
  getAppointments
};
exports.default = _default;