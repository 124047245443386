"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = userSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _user = require("./user.types");

var _participants = require("../participants/participants.types");

const updatePassword = args => {
  const {
    password,
    currentPassword,
    updatePseudoParticipants
  } = args;
  return (0, _api.default)({
    method: 'put',
    url: `/a/usmg/updatePassword?updatePseudoParticipants=${updatePseudoParticipants}`,
    data: {
      password,
      currentPassword
    }
  });
};

const updateMobile = mobile => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/usmg/updateMobile/`,
    data: {
      mobile
    }
  });
};

const updateEmail = email => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/usmg/updateEmail/`,
    data: {
      email
    }
  });
};

const getUser = id => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/usmg/user/${id}`
  });
};

const getAdmin = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/usmg/admin`
  });
};

const createUser = args => {
  return (0, _api.default)({
    method: 'put',
    url: `a/usmg/user?sendEmail=${args.sendEmail}&createPseudos=${args.createPseudos}`,
    data: args.user
  });
};

const getAdminRoles = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/usmg/admin/roles`
  });
};

const updateAdminPassword = args => {
  const {
    password,
    userIds,
    updatePseudoParticipants
  } = args;
  return (0, _api.default)({
    method: 'put',
    url: `/a/usmg/updatePasswordStudyManager?updatePseudoParticipants=${updatePseudoParticipants}`,
    data: {
      userIds,
      password
    }
  });
};

const updateAdminUser = args => {
  const {
    user,
    roleIds
  } = args;
  return (0, _api.default)({
    method: 'put',
    url: `/a/usmg/admin/${user.id}/update`,
    data: {
      user,
      roleIds
    }
  });
};

const deleteAdminUser = args => {
  const {
    userIds,
    deleteDate
  } = args;
  return (0, _api.default)({
    method: 'delete',
    url: `/a/usmg/admin/`,
    data: {
      userIds
    },
    params: {
      deleteDate
    }
  });
};

function* updatePasswordHandler(action) {
  try {
    const response = yield (0, _effects.call)(updatePassword, action.payload);
    yield (0, _effects.put)(_user.updatePasswordAsync.success(response.data));
  } catch (error) {
    yield (0, _effects.put)(_user.updatePasswordAsync.failure(error));
  }
}

function* updateMobileHandler(action) {
  const mobile = action.payload;

  try {
    const response = yield (0, _effects.call)(updateMobile, mobile);
    yield (0, _effects.put)(_user.updateMobileAsync.success(response.data));
  } catch (error) {
    yield (0, _effects.put)(_user.updateMobileAsync.failure(error));
  }
}

function* updateEmailHandler(action) {
  const password = action.payload;

  try {
    const response = yield (0, _effects.call)(updateEmail, password);
    yield (0, _effects.put)(_user.updateEmailAsync.success(response.data));
  } catch (error) {
    yield (0, _effects.put)(_user.updateEmailAsync.failure(error));
  }
}

function* userHandler(action) {
  const id = action.payload;

  try {
    const response = yield (0, _effects.call)(getUser, id);
    yield (0, _effects.put)(_user.getUserAsync.success(response.data));
  } catch (error) {
    yield (0, _effects.put)(_user.getUserAsync.failure(error));
  }
}

function* getAdminHandler(action) {
  try {
    const response = yield (0, _effects.call)(getAdmin);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.admin);
    const {
      admin
    } = entities;
    yield (0, _effects.put)(_participants.getParticipantsByStudyAsync.request(1));
    yield (0, _effects.put)(_user.getAdminAsync.success(admin));
  } catch (error) {
    yield (0, _effects.put)(_user.getAdminAsync.failure(error));
  }
}

function* createUserHandler(action) {
  try {
    yield (0, _effects.call)(createUser, action.payload);
    yield (0, _effects.put)(_user.createUserAsync.success());
  } catch (error) {
    yield (0, _effects.put)(_user.createUserAsync.failure(error));
  }
}

function* getAdminRolesHandler(action) {
  try {
    const response = yield (0, _effects.call)(getAdminRoles);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.adminRoles);
    const {
      adminRoles
    } = entities;
    yield (0, _effects.put)(_user.getAdminRolesAsync.success(adminRoles));
  } catch (error) {
    yield (0, _effects.put)(_user.getAdminRolesAsync.failure(error));
  }
}

function* updateAdminPasswordHandler(action) {
  try {
    yield (0, _effects.call)(updateAdminPassword, action.payload);
    yield (0, _effects.put)(_user.updateAdminPasswordAsync.success()); // Refresh admin data

    yield (0, _effects.put)(_user.getAdminAsync.request());
  } catch (error) {
    yield (0, _effects.put)(_user.updateAdminPasswordAsync.failure(error));
  }
}

function* updateAdminUserHandler(action) {
  try {
    const response = yield (0, _effects.call)(updateAdminUser, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.admin);
    const {
      admin
    } = entities;
    yield (0, _effects.put)(_user.updateAdminUserAsync.success(admin));
  } catch (error) {
    yield (0, _effects.put)(_user.updateAdminUserAsync.failure(error));
  }
}

function* deleteAdminUserHandler(action) {
  try {
    yield (0, _effects.call)(deleteAdminUser, action.payload);
    yield (0, _effects.put)(_user.deleteAdminUserAsync.success()); // Refresh admin data

    yield (0, _effects.put)(_user.getAdminAsync.request());
  } catch (error) {
    yield (0, _effects.put)(_user.deleteAdminUserAsync.failure(error));
  }
}

function* updatePasswordWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_user.updatePasswordAsync.request), updatePasswordHandler);
}

function* updateMobileWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_user.updateMobileAsync.request), updateMobileHandler);
}

function* updateEmailWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_user.updateEmailAsync.request), updateEmailHandler);
}

function* userWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_user.getUserAsync.request), userHandler);
}

function* getAdminWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_user.getAdminAsync.request), getAdminHandler);
}

function* createUserWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_user.createUserAsync.request), createUserHandler);
}

function* getAdminRolesWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_user.getAdminRolesAsync.request), getAdminRolesHandler);
}

function* updateAdminPasswordWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_user.updateAdminPasswordAsync.request), updateAdminPasswordHandler);
}

function* updateAdminUserWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_user.updateAdminUserAsync.request), updateAdminUserHandler);
}

function* deleteAdminUserWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_user.deleteAdminUserAsync.request), deleteAdminUserHandler);
}

function* userSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(userWatcher), (0, _effects.fork)(getAdminWatcher), (0, _effects.fork)(updatePasswordWatcher), (0, _effects.fork)(updateEmailWatcher), (0, _effects.fork)(updateMobileWatcher), (0, _effects.fork)(createUserWatcher), (0, _effects.fork)(getAdminRolesWatcher), (0, _effects.fork)(updateAdminPasswordWatcher), (0, _effects.fork)(updateAdminUserWatcher), (0, _effects.fork)(deleteAdminUserWatcher)]);
}