"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.refreshInbox = exports.refreshInboxAndThread = exports.removeFromIsTypingIndicators = exports.addToIsTypingIndicators = exports.updateMessageThreadCount = exports.clearMessages = exports.updateMessages = exports.unassignThreadAsync = exports.assignThreadAsync = exports.markThreadReadAsync = exports.markThreadUnreadAsync = exports.unmarkThreadImportantAsync = exports.markThreadImportantAsync = exports.unarchiveThreadAsync = exports.archiveThreadAsync = exports.sendMessageAsync = exports.createThreadAsync = exports.getMessagesAsync = exports.getMessageThreadCountAsync = exports.getMessageInboxAsync = exports.getParticipantThreadsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getParticipantThreadsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_PARTICIPANT_MESSAGES_REQUEST', 'hmp/admin/GET_PARTICIPANT_MESSAGES_SUCCESS', 'hmp/admin/GET_PARTICIPANT_MESSAGES_FAILURE')();
exports.getParticipantThreadsAsync = getParticipantThreadsAsync;
const getMessageInboxAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_MESSAGE_INBOX_REQUEST', 'hmp/admin/GET_MESSAGE_INBOX_SUCCESS', 'hmp/admin/GET_MESSAGE_INBOX_FAILURE')();
exports.getMessageInboxAsync = getMessageInboxAsync;
const getMessageThreadCountAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_MESSAGE_THREAD_COUNT_REQUEST', 'hmp/admin/GET_MESSAGE_THREAD_COUNT_SUCCESS', 'hmp/admin/GET_MESSAGE_THREAD_COUNT_FAILURE')();
exports.getMessageThreadCountAsync = getMessageThreadCountAsync;
const getMessagesAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_MESSAGES_REQUEST', 'hmp/admin/GET_MESSAGES_SUCCESS', 'hmp/admin/GET_MESSAGES_FAILURE')();
exports.getMessagesAsync = getMessagesAsync;
const createThreadAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/CREATE_THREAD_REQUEST', 'hmp/admin/CREATE_THREAD_SUCCESS', 'hmp/admin/CREATE_THREAD_FAILURE')();
exports.createThreadAsync = createThreadAsync;
const sendMessageAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/SEND_MESSAGE_REQUEST', 'hmp/admin/SEND_MESSAGE_SUCCESS', 'hmp/admin/SEND_MESSAGE_FAILURE')();
exports.sendMessageAsync = sendMessageAsync;
const archiveThreadAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/ARCHIVE_THREAD_REQUEST', 'hmp/admin/ARCHIVE_THREAD_SUCCESS', 'hmp/admin/ARCHIVE_THREAD_FAILURE')();
exports.archiveThreadAsync = archiveThreadAsync;
const unarchiveThreadAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UNARCHIVE_THREAD_REQUEST', 'hmp/admin/UNARCHIVE_THREAD_SUCCESS', 'hmp/admin/UNARCHIVE_THREAD_FAILURE')();
exports.unarchiveThreadAsync = unarchiveThreadAsync;
const markThreadImportantAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/MARK_THREAD_IMPORTANT_REQUEST', 'hmp/admin/MARK_THREAD_IMPORTANT_SUCCESS', 'hmp/admin/MARK_THREAD_IMPORTANT_FAILURE')();
exports.markThreadImportantAsync = markThreadImportantAsync;
const unmarkThreadImportantAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UNMARK_THREAD_IMPORTANT_REQUEST', 'hmp/admin/UNMARK_THREAD_IMPORTANT_SUCCESS', 'hmp/admin/UNMARK_THREAD_IMPORTANT_FAILURE')();
exports.unmarkThreadImportantAsync = unmarkThreadImportantAsync;
const markThreadUnreadAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/MARK_THREAD_UNREAD_REQUEST', 'hmp/admin/MARK_THREAD_UNREAD_SUCCESS', 'hmp/admin/MARK_THREAD_UNREAD_FAILURE')();
exports.markThreadUnreadAsync = markThreadUnreadAsync;
const markThreadReadAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/MARK_THREAD_READ_REQUEST', 'hmp/admin/MARK_THREAD_READ_SUCCESS', 'hmp/admin/MARK_THREAD_READ_FAILURE')();
exports.markThreadReadAsync = markThreadReadAsync;
const assignThreadAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/ASSIGN_THREAD_REQUEST', 'hmp/admin/ASSIGN_THREAD_SUCCESS', 'hmp/admin/ASSIGN_THREAD_FAILURE')();
exports.assignThreadAsync = assignThreadAsync;
const unassignThreadAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UNASSIGN_THREAD_REQUEST', 'hmp/admin/UNASSIGN_THREAD_SUCCESS', 'hmp/admin/UNASSIGN_THREAD_FAILURE')();
exports.unassignThreadAsync = unassignThreadAsync;
const updateMessages = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_MESSAGES')();
exports.updateMessages = updateMessages;
const clearMessages = (0, _typesafeActions.createStandardAction)('hmp/admin/CLEAR_MESSAGES')();
exports.clearMessages = clearMessages;
const updateMessageThreadCount = (0, _typesafeActions.createStandardAction)("hmp/admin/UPDATE_MESSAGE_THREAD_COUNT")();
exports.updateMessageThreadCount = updateMessageThreadCount;
const addToIsTypingIndicators = (0, _typesafeActions.createStandardAction)("hmp/admin/ADD_TO_IS_TYPING_INDICATORS")();
exports.addToIsTypingIndicators = addToIsTypingIndicators;
const removeFromIsTypingIndicators = (0, _typesafeActions.createStandardAction)("hmp/admin/REMOVE_FROM_IS_TYPING_INDICATORS")();
exports.removeFromIsTypingIndicators = removeFromIsTypingIndicators;
const refreshInboxAndThread = (0, _typesafeActions.createStandardAction)('hmp/admin/REFRESH_INBOX_AND_THREAD')();
exports.refreshInboxAndThread = refreshInboxAndThread;
const refreshInbox = (0, _typesafeActions.createStandardAction)('hmp/admin/REFRESH_INBOX')();
exports.refreshInbox = refreshInbox;
const messagesActions = {
  getParticipantThreadsAsync,
  getMessageInboxAsync,
  getMessagesAsync,
  updateMessages,
  clearMessages,
  createThreadAsync,
  sendMessageAsync,
  archiveThreadAsync,
  unarchiveThreadAsync,
  markThreadImportantAsync,
  unmarkThreadImportantAsync,
  markThreadUnreadAsync,
  assignThreadAsync,
  unassignThreadAsync,
  getMessageThreadCountAsync,
  updateMessageThreadCount,
  refreshInboxAndThread,
  addToIsTypingIndicators,
  removeFromIsTypingIndicators
};