"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = studySaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _dashboard = require("../dashboard/dashboard.types");

var _schema = require("../schema");

var _testkit = require("./testkit.types");

const getParticipantsTestkits = participantId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/testkit/${participantId}/participantTestKits`
  });
};

function* getParticipantsTestkitsHandler(action) {
  try {
    const studyId = action.payload;
    const response = yield (0, _effects.call)(getParticipantsTestkits, studyId);
    const participantTestkits = response.data ? response.data : [];
    const {
      entities
    } = (0, _normalizr.normalize)(participantTestkits, _schema.entitySchema.testkits);
    const {
      testkits
    } = entities;
    yield (0, _effects.put)(_testkit.getParticipantsTestkitsAsync.success(testkits));
  } catch (error) {
    yield (0, _effects.put)(_testkit.getParticipantsTestkitsAsync.failure(error));
  }
}

function* getParticipantsTestkitsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_testkit.getParticipantsTestkitsAsync.request), getParticipantsTestkitsHandler);
}

const getRequestedTestkits = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/testkit/${studyId}/requested`
  });
};

function* getRequestedTestkitsHandler(action) {
  try {
    const studyId = action.payload;
    const response = yield (0, _effects.call)(getRequestedTestkits, studyId);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.testkits);
    const {
      testkits
    } = entities;
    yield (0, _effects.put)(_testkit.getRequestedTestkitsAsync.success(testkits));
  } catch (error) {
    yield (0, _effects.put)(_testkit.getRequestedTestkitsAsync.failure(error));
  }
}

function* getRequestedTestkitsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_testkit.getRequestedTestkitsAsync.request), getRequestedTestkitsHandler);
}

const getNeedsReviewTestkits = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/testkit/${studyId}/needsReview`
  });
};

function* getNeedsReviewTestkitsHandler(action) {
  try {
    const {
      studyId
    } = action.payload;
    const response = yield (0, _effects.call)(getNeedsReviewTestkits, studyId);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.testkits);
    const {
      testkits
    } = entities;
    yield (0, _effects.put)(_testkit.getNeedsReviewTestkitsAsync.success(testkits));
  } catch (error) {
    yield (0, _effects.put)(_testkit.getNeedsReviewTestkitsAsync.failure(error));
  }
}

function* getNeedsReviewTestkitsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_testkit.getNeedsReviewTestkitsAsync.request), getNeedsReviewTestkitsHandler);
}

const getAwaitingResultTestkits = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/testkit/${studyId}/awaitingResult`
  });
};

function* getAwaitingResultTestkitsHandler(action) {
  try {
    const {
      studyId
    } = action.payload;
    const response = yield (0, _effects.call)(getAwaitingResultTestkits, studyId);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.testkits);
    const {
      testkits
    } = entities;
    yield (0, _effects.put)(_testkit.getAwaitingResultTestkitsAsync.success(testkits));
  } catch (error) {
    yield (0, _effects.put)(_testkit.getAwaitingResultTestkitsAsync.failure(error));
  }
}

function* getAwaitingResultTestkitsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_testkit.getAwaitingResultTestkitsAsync.request), getAwaitingResultTestkitsHandler);
}

const getCompletedTestkits = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/testkit/${studyId}/completed`
  });
};

function* getCompletedTestkitsHandler(action) {
  try {
    const {
      studyId
    } = action.payload;
    const response = yield (0, _effects.call)(getCompletedTestkits, studyId);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.testkits);
    const {
      testkits
    } = entities;
    yield (0, _effects.put)(_testkit.getCompletedTestkitsAsync.success(testkits));
  } catch (error) {
    yield (0, _effects.put)(_testkit.getCompletedTestkitsAsync.failure(error));
  }
}

function* getCompletedTestkitsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_testkit.getCompletedTestkitsAsync.request), getCompletedTestkitsHandler);
}

const fulfillTestkit = testkit => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/testkit/${testkit.id}/fulfill`,
    data: {
      trackingNumber: testkit.trackingNumber,
      estimatedDeliveryDate: testkit.estimatedDeliveryDate
    }
  });
};

function* fulfillTestkitHandler(action) {
  try {
    const testkit = action.payload;
    const response = yield (0, _effects.call)(fulfillTestkit, testkit);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.testkits);
    const {
      testkits
    } = entities;
    yield (0, _effects.put)(_testkit.fulfillTestkitAsync.success(testkits));
    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.request());
  } catch (error) {
    yield (0, _effects.put)(_testkit.fulfillTestkitAsync.failure(error));
  }
}

function* fulfillTestkitWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_testkit.fulfillTestkitAsync.request), fulfillTestkitHandler);
}

const reviewTestkit = testkit => {
  let url = `/a/testkit/${testkit.id}/review`;

  if (testkit.actualResult && testkit.reportDate) {
    url += `?reportDate=${testkit.reportDate}&actualResult=${testkit.actualResult}`;
  } else if (testkit.actualResult) {
    url += `?actualResult=${testkit.actualResult}`;
  } else if (testkit.reportDate) {
    url += `?reportDate=${testkit.reportDate}`;
  }

  return (0, _api.default)({
    method: 'put',
    url
  });
};

function* reviewTestkitHandler(action) {
  try {
    const testkit = action.payload;
    const response = yield (0, _effects.call)(reviewTestkit, testkit);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.testkits);
    const {
      testkits
    } = entities;
    yield (0, _effects.put)(_testkit.reviewTestkitAsync.success(testkits));
    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.request());
  } catch (error) {
    yield (0, _effects.put)(_testkit.reviewTestkitAsync.failure(error));
  }
}

function* reviewTestkitWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_testkit.reviewTestkitAsync.request), reviewTestkitHandler);
}

function* studySaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getParticipantsTestkitsWatcher), (0, _effects.fork)(getRequestedTestkitsWatcher), (0, _effects.fork)(getAwaitingResultTestkitsWatcher), (0, _effects.fork)(getNeedsReviewTestkitsWatcher), (0, _effects.fork)(getCompletedTestkitsWatcher), (0, _effects.fork)(fulfillTestkitWatcher), (0, _effects.fork)(reviewTestkitWatcher)]);
}