"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = rootSaga;

var _effects = require("redux-saga/effects");

var _analytics = _interopRequireDefault(require("./analitycs/analytics.sagas"));

var _app = _interopRequireDefault(require("./app/app.sagas"));

var _appointments = _interopRequireDefault(require("./appointments/appointments.sagas"));

var _article = _interopRequireDefault(require("./article/article.sagas"));

var _avatar = _interopRequireDefault(require("./avatar/avatar.sagas"));

var _dashboard = _interopRequireDefault(require("./dashboard/dashboard.sagas"));

var _notifiication = _interopRequireDefault(require("./notification/notifiication.sagas"));

var _participants = _interopRequireDefault(require("./participants/participants.sagas"));

var _payment = _interopRequireDefault(require("./payment/payment.sagas"));

var _posts = _interopRequireDefault(require("./posts/posts.sagas"));

var _comments = _interopRequireDefault(require("./comments/comments.sagas"));

var _messages = _interopRequireDefault(require("./messages/messages.sagas"));

var _study = _interopRequireDefault(require("./study/study.sagas"));

var _user = _interopRequireDefault(require("./user/user.sagas"));

var _form = _interopRequireDefault(require("./form/form.sagas"));

var _badges = _interopRequireDefault(require("./badges/badges.sagas"));

var _topic = _interopRequireDefault(require("./topics/topic.sagas"));

var _testkit = _interopRequireDefault(require("./testkit/testkit.sagas"));

var _notes = _interopRequireDefault(require("./notes/notes.sagas"));

var _flags = _interopRequireDefault(require("./flags/flags.sagas"));

var _thumbsups = _interopRequireDefault(require("./thumbsups/thumbsups.sagas"));

var _favorites = _interopRequireDefault(require("./favorites/favorites.sagas"));

var _activities = _interopRequireDefault(require("./activities/activities.sagas"));

var _announcements = _interopRequireDefault(require("./announcements/announcements.sagas"));

var _userSurvey = _interopRequireDefault(require("./userSurvey/userSurvey.sagas"));

var _qna = _interopRequireDefault(require("./qna/qna.sagas"));

var _forum = _interopRequireDefault(require("./forum/forum.sagas"));

var _goals = _interopRequireDefault(require("./goals/goals.sagas"));

var _availability = _interopRequireDefault(require("./availabilty/availability.sagas"));

function* rootSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(_app.default), (0, _effects.fork)(_announcements.default), (0, _effects.fork)(_availability.default), (0, _effects.fork)(_appointments.default), (0, _effects.fork)(_analytics.default), (0, _effects.fork)(_article.default), (0, _effects.fork)(_avatar.default), (0, _effects.fork)(_participants.default), (0, _effects.fork)(_notes.default), (0, _effects.fork)(_notifiication.default), (0, _effects.fork)(_posts.default), (0, _effects.fork)(_comments.default), (0, _effects.fork)(_dashboard.default), (0, _effects.fork)(_messages.default), (0, _effects.fork)(_study.default), (0, _effects.fork)(_user.default), (0, _effects.fork)(_form.default), (0, _effects.fork)(_topic.default), (0, _effects.fork)(_badges.default), (0, _effects.fork)(_testkit.default), (0, _effects.fork)(_flags.default), (0, _effects.fork)(_thumbsups.default), (0, _effects.fork)(_favorites.default), (0, _effects.fork)(_activities.default), (0, _effects.fork)(_payment.default), (0, _effects.fork)(_userSurvey.default), (0, _effects.fork)(_qna.default), (0, _effects.fork)(_forum.default), (0, _effects.fork)(_goals.default)]);
}