"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.threadsReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _state = require("../../types/state.types");

var _messages = require("./messages.types");

const updateThreadsReducer = (0, _immer.default)((draft, threads) => {
  if (!threads) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(threads), Number));
  const ids = Object.keys(threads);

  for (const id of ids) {
    draft.byId[id] = threads[id];
  }
});

const threadsReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_messages.getParticipantThreadsAsync.success):
      return updateThreadsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_messages.getMessageInboxAsync.success):
      return updateThreadsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_messages.createThreadAsync.success):
      return updateThreadsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_messages.archiveThreadAsync.success):
      return updateThreadsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_messages.unarchiveThreadAsync.success):
      return updateThreadsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_messages.markThreadImportantAsync.success):
      return updateThreadsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_messages.unmarkThreadImportantAsync.success):
      return updateThreadsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_messages.markThreadUnreadAsync.success):
      return updateThreadsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_messages.assignThreadAsync.success):
      return updateThreadsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_messages.unassignThreadAsync.success):
      return updateThreadsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_messages.clearMessages):
      return {
        byId: {},
        allIds: []
      };

    default:
      return state;
  }
};

exports.threadsReducer = threadsReducer;
var _default = threadsReducer;
exports.default = _default;