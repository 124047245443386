"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.goalUIReducer = exports.initialGoalUIState = void 0;

var _typesafeActions = require("typesafe-actions");

var _goals = require("./goals.types");

const initialGoalUIState = {
  query: "",
  includeDeleted: true,
  includeUnpublished: true
};
exports.initialGoalUIState = initialGoalUIState;

const goalUIReducer = (state = initialGoalUIState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_goals.updateGoalsUIState):
      return { ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

exports.goalUIReducer = goalUIReducer;
var _default = goalUIReducer;
exports.default = _default;