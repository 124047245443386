"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateForms = exports.updateFormAsync = exports.saveFormAsync = exports.loadRequestedFormsAsync = exports.loadFormsAsync = exports.getParticipantFormsAsync = exports.getFormsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getFormsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_FORMS_REQUEST', 'hmp/admin/GET_FORMS_SUCCESS', 'hmp/admin/GET_FORMS_FAILURE')();
exports.getFormsAsync = getFormsAsync;
const getParticipantFormsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_PARTICIPANT_FORMS_REQUEST', 'hmp/admin/GET_PARTICIPANT_FORMS_SUCCESS', 'hmp/admin/GET_PARTICIPANT_FORMS_FAILURE')();
exports.getParticipantFormsAsync = getParticipantFormsAsync;
const loadFormsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_FORMS_REQUEST', 'hmp/admin/LOAD_FORMS_SUCCESS', 'hmp/admin/LOAD_FORMS_FAILURE')();
exports.loadFormsAsync = loadFormsAsync;
const loadRequestedFormsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_REQUESTED_FORMS_REQUEST', 'hmp/admin/LOAD_REQUESTED_FORMS_SUCCESS', 'hmp/admin/LOAD_REQUESTED_FORMS_FAILURE')();
exports.loadRequestedFormsAsync = loadRequestedFormsAsync;
const saveFormAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/SAVE_FORM_REQUEST', 'hmp/admin/SAVE_FORM_SUCCESS', 'hmp/admin/SAVE_FORM_FAILURE')();
exports.saveFormAsync = saveFormAsync;
const updateFormAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPDATE_FORM_REQUEST', 'hmp/admin/UPDATE_FORM_SUCCESS', 'hmp/admin/UPDATE_FORM_FAILURE')();
exports.updateFormAsync = updateFormAsync;
const updateForms = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_FORMS')();
exports.updateForms = updateForms;
const formsActions = {
  getFormsAsync,
  getParticipantFormsAsync,
  loadRequestedFormsAsync,
  loadFormsAsync,
  saveFormAsync,
  updateForms,
  updateFormAsync
};