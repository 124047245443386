"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.studyReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _study = require("./study.types");

var _state = require("../../types/state.types");

const updateStudiesReducer = (0, _immer.default)((draft, studies) => {
  if (!studies) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(studies), Number));
  const ids = Object.keys(studies);

  for (const id of ids) {
    draft.byId[id] = studies[id];
  }
});

const studyReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_study.updateStudies):
      return updateStudiesReducer(state, action.payload);

    default:
      return state;
  }
};

exports.studyReducer = studyReducer;
var _default = studyReducer;
exports.default = _default;