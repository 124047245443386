"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getCommentThumbsups = exports.getQnaThumbsups = exports.getThumbsups = exports.selectThumbsups = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _participants = require("../participants/participants.selectors");

var _schema = require("../schema");

const selectThumbsups = state => {
  return state.entities.thumbsups;
};

exports.selectThumbsups = selectThumbsups;
const getThumbsups = (0, _reselect.createSelector)([selectThumbsups, _participants.selectParticipants], (thumbsups, participants) => {
  if (thumbsups && thumbsups.allIds && thumbsups.allIds.length > 0) {
    const {
      thumbsups: denormalizedThumbsups
    } = (0, _normalizr.denormalize)({
      thumbsups: thumbsups.allIds
    }, _schema.entitySchema, {
      thumbsups: thumbsups.byId,
      participants: participants.byId
    });
    return (0, _toArray.default)(denormalizedThumbsups);
  }

  return undefined;
});
exports.getThumbsups = getThumbsups;
const getQnaThumbsups = (0, _reselect.createSelector)([selectThumbsups, _participants.selectParticipants], (thumbsups, participants) => {
  if (thumbsups && thumbsups.allIds && thumbsups.allIds.length > 0) {
    const {
      thumbsups: denormalizedThumbsups
    } = (0, _normalizr.denormalize)({
      thumbsups: thumbsups.allIds
    }, _schema.entitySchema, {
      thumbsups: thumbsups.byId,
      participants: participants.byId
    });
    const qnaThumbsups = (0, _toArray.default)(denormalizedThumbsups).filter(t => t.type === 'qna');
    return qnaThumbsups;
  }

  return undefined;
});
exports.getQnaThumbsups = getQnaThumbsups;
const getCommentThumbsups = (0, _reselect.createSelector)([selectThumbsups, _participants.selectParticipants], (thumbsups, participants) => {
  if (thumbsups && thumbsups.allIds && thumbsups.allIds.length > 0) {
    const {
      thumbsups: denormalizedThumbsups
    } = (0, _normalizr.denormalize)({
      thumbsups: thumbsups.allIds
    }, _schema.entitySchema, {
      thumbsups: thumbsups.byId,
      participants: participants.byId
    });
    const qnaThumbsups = (0, _toArray.default)(denormalizedThumbsups).filter(t => t.type === 'comment');
    return qnaThumbsups;
  }

  return undefined;
});
exports.getCommentThumbsups = getCommentThumbsups;
var _default = {
  getThumbsups
};
exports.default = _default;