"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = availabilitySaga;

var _moment = _interopRequireDefault(require("moment"));

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _availability = require("./availability.types");

const getAvailabilities = queryParams => {
  if (queryParams) {
    const {
      startDate,
      endDate
    } = queryParams;
    let url = `/a/appointment/availability?startDate=${(0, _moment.default)(startDate).toISOString()}`;

    if (endDate) {
      url += `&endDate=${(0, _moment.default)(endDate).toISOString()}`;
    }

    return (0, _api.default)({
      method: 'get',
      url
    });
  }
};

const createAvailability = availability => {
  return (0, _api.default)({
    method: "put",
    url: `/a/appointment/availability`,
    data: availability
  });
};

const deleteAvailability = id => {
  return (0, _api.default)({
    method: "delete",
    url: `/a/appointment/availability/${id}`
  });
};

let lastGetAvailabilitiesParam;

function* refreshAvailabilityHandler() {
  yield (0, _effects.put)(_availability.getAvailabilitiesAsync.request(lastGetAvailabilitiesParam));
}

function* getAvailabilitiesHandler(action) {
  try {
    lastGetAvailabilitiesParam = action.payload;
    const response = yield (0, _effects.call)(getAvailabilities, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.availabilities);
    const {
      availabilities
    } = entities;
    yield (0, _effects.put)(_availability.getAvailabilitiesAsync.success(availabilities));
  } catch (error) {
    yield (0, _effects.put)(_availability.getAvailabilitiesAsync.failure(error));
  }
}

function* createAvailabilityHandler(action) {
  try {
    const response = yield (0, _effects.call)(createAvailability, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.availabilities);
    const {
      availabilities
    } = entities;
    yield (0, _effects.put)(_availability.createAvailabilityAsync.success(availabilities));
    yield (0, _effects.call)(refreshAvailabilityHandler);
  } catch (error) {
    yield (0, _effects.put)(_availability.createAvailabilityAsync.failure(error));
  }
}

function* deleteAvailabilityHandler(action) {
  try {
    yield (0, _effects.call)(deleteAvailability, action.payload);
    yield (0, _effects.put)(_availability.deleteAvailabilityAsync.success(action.payload));
    yield (0, _effects.call)(refreshAvailabilityHandler);
  } catch (error) {
    yield (0, _effects.put)(_availability.deleteAvailabilityAsync.failure(error));
  }
}

function* getAvailabilitiesWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_availability.getAvailabilitiesAsync.request), getAvailabilitiesHandler);
}

function* createAvailabilityWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_availability.createAvailabilityAsync.request), createAvailabilityHandler);
}

function* deleteAvailabilityWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_availability.deleteAvailabilityAsync.request), deleteAvailabilityHandler);
}

function* availabilitySaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getAvailabilitiesWatcher), (0, _effects.fork)(createAvailabilityWatcher), (0, _effects.fork)(deleteAvailabilityWatcher)]);
}