"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logout = void 0;

var _typesafeActions = require("typesafe-actions");

const logout = (0, _typesafeActions.createStandardAction)('hmp/admin/LOGOUT')();
exports.logout = logout;
const appActions = {
  logout
};