"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getThumbsupsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getThumbsupsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_THUMBSUPS_REQUEST', 'hmp/admin/GET_THUMBSUPS_SUCCESS', 'hmp/admin/GET_THUMBSUPS_FAILURE')();
exports.getThumbsupsAsync = getThumbsupsAsync;
const thumbsupsActions = {
  getThumbsupsAsync
};