"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.awardParticipantBadgeAsync = exports.getParticipantBadgesAsync = exports.getBadgeCollectionTypesAsync = exports.undeleteBadgeGroupAsync = exports.deleteBadgeGroupAsync = exports.saveBadgeCollectionAsync = exports.saveBadgeGroupAsync = exports.getBadgeGroupsAsync = exports.undeleteBadgeCollectionAsync = exports.deleteBadgeCollectionAsync = exports.getBadgeCollectionsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getBadgeCollectionsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_BADGE_COLLECTIONS_REQUEST', 'hmp/admin/GET_BADGE_COLLECTIONS_SUCCESS', 'hmp/admin/GET_BADGE_COLLECTIONS_FAILURE')();
exports.getBadgeCollectionsAsync = getBadgeCollectionsAsync;
const deleteBadgeCollectionAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/DELETE_BADGE_COLLECTIONS_REQUEST', 'hmp/admin/DELETE_BADGE_COLLECTIONS_SUCCESS', 'hmp/admin/DELETE_BADGE_COLLECTIONS_FAILURE')();
exports.deleteBadgeCollectionAsync = deleteBadgeCollectionAsync;
const undeleteBadgeCollectionAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UNDELETE_BADGES_REQUEST', 'hmp/admin/UNDELETE_BADGES_SUCCESS', 'hmp/admin/UNDELETE_BADGES_FAILURE')();
exports.undeleteBadgeCollectionAsync = undeleteBadgeCollectionAsync;
const getBadgeGroupsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_BADGE_GROUPS_REQUEST', 'hmp/admin/GET_BADGE_GROUPS_SUCCESS', 'hmp/admin/GET_BADGE_GROUPS_FAILURE')();
exports.getBadgeGroupsAsync = getBadgeGroupsAsync;
const saveBadgeGroupAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/SAVE_BADGE_GROUP_REQUEST', 'hmp/admin/SAVE_BADGE_GROUP_SUCCESS', 'hmp/admin/SAVE_BADGE_GROUP_FAILURE')();
exports.saveBadgeGroupAsync = saveBadgeGroupAsync;
const saveBadgeCollectionAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/SAVE_BADGE_COLLECTION_REQUEST', 'hmp/admin/SAVE_BADGE_COLLECTION_SUCCESS', 'hmp/admin/SAVE_BADGE_COLLECTION_FAILURE')();
exports.saveBadgeCollectionAsync = saveBadgeCollectionAsync;
const deleteBadgeGroupAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/DELETE_BADGE_GROUPS_REQUEST', 'hmp/admin/DELETE_BADGES_GROUP_SUCCESS', 'hmp/admin/DELETE_BADGES_GROUP_FAILURE')();
exports.deleteBadgeGroupAsync = deleteBadgeGroupAsync;
const undeleteBadgeGroupAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UNDELETE_BADGE_GROUPS_REQUEST', 'hmp/admin/UNDELETE_BADGES_GROUP_SUCCESS', 'hmp/admin/UNDELETE_BADGES_GROUP_FAILURE')();
exports.undeleteBadgeGroupAsync = undeleteBadgeGroupAsync;
const getBadgeCollectionTypesAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_BADGE_COLLECTION_TYPES_REQUEST', 'hmp/admin/GET_BADGE_COLLECTION_TYPES_SUCCESS', 'hmp/admin/GET_BADGE_COLLECTION_TYPES_FAILURE')();
exports.getBadgeCollectionTypesAsync = getBadgeCollectionTypesAsync;
const getParticipantBadgesAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/GET_PARTICIPANT_BADGES_REQUEST", "hmp/admin/GET_PARTICIPANT_BADGES_SUCCESS", "hmp/admin/GET_PARTICIPANT_BADGES_FAILURE")();
exports.getParticipantBadgesAsync = getParticipantBadgesAsync;
const awardParticipantBadgeAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/AWARD_PARTICIPANT_BADGE_REQUEST', 'hmp/admin/AWARD_PARTICIPANT_BADGE_SUCCESS', 'hmp/admin/AWARD_PARTICIPANT_BADGE_FAILURE')();
exports.awardParticipantBadgeAsync = awardParticipantBadgeAsync;
const badgesActions = {
  getBadgeCollectionsAsync,
  getBadgeGroupsAsync,
  getBadgeCollectionTypesAsync,
  deleteBadgeCollectionAsync,
  deleteBadgeGroupAsync,
  undeleteBadgeCollectionAsync,
  undeleteBadgeGroupAsync,
  saveBadgeGroupAsync,
  saveBadgeCollectionAsync,
  getParticipantBadgesAsync,
  awardParticipantBadgeAsync
};