"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.activityTypesReducer = exports.activityCategoriesReducer = exports.activitiesReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _activities = require("./activities.types");

var _state = require("../../types/state.types");

const updateActivitiesReducer = (0, _immer.default)((draft, activities) => {
  if (!activities) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(activities), Number));
  const ids = Object.keys(activities);

  for (const id of ids) {
    draft.byId[id] = activities[id];
  }
});
const refreshActivitiesReducer = (0, _immer.default)((draft, activities) => {
  if (!activities) {
    return;
  }

  draft.allIds = (0, _map.default)((0, _keys.default)(activities), Number);
  const ids = Object.keys(activities);

  for (const id of ids) {
    draft.byId[id] = { ...activities[id]
    };
  }
});
const updateActivityCategoriesReducer = (0, _immer.default)((draft, activityCategories) => {
  if (!activityCategories) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(activityCategories), Number));
  const ids = Object.keys(activityCategories);

  for (const id of ids) {
    draft.byId[id] = activityCategories[id];
  }
});
const updateActivityTypesReducer = (0, _immer.default)((draft, activityTypes) => {
  if (!activityTypes) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(activityTypes), Number));
  const ids = Object.keys(activityTypes);

  for (const id of ids) {
    draft.byId[id] = activityTypes[id];
  }
});

const activitiesReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_activities.getActivityAsync.success):
      return updateActivitiesReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_activities.getActivitySummariesAsync.success):
      return refreshActivitiesReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_activities.upsertActivityAsync.success):
      return updateActivitiesReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_activities.deleteActivityAsync.success):
      return updateActivitiesReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_activities.publishActivityAsync.success):
      return updateActivitiesReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_activities.unpublishActivityAsync.success):
      return updateActivitiesReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_activities.updateActivities):
      return updateActivitiesReducer(state, action.payload);

    default:
      return state;
  }
};

exports.activitiesReducer = activitiesReducer;

const activityCategoriesReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_activities.getActivityCategoriesAsync.success):
      return updateActivityCategoriesReducer(state, action.payload);

    default:
      return state;
  }
};

exports.activityCategoriesReducer = activityCategoriesReducer;

const activityTypesReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_activities.getActivityTypesAsync.success):
      return updateActivityTypesReducer(state, action.payload);

    default:
      return state;
  }
};

exports.activityTypesReducer = activityTypesReducer;
var _default = activitiesReducer;
exports.default = _default;