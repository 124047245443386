"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.hasStudyManagerRole = exports.hasCareNavigatorRole = exports.hasDataAnalystRole = exports.isNonYAB = exports.getCurrentUserPseudoParticipants = exports.getUser = exports.selectUser = exports.getExpertAdmin = exports.getAdminRoles = exports.getAdmin = exports.selectAdminRoles = exports.selectAdmin = exports.selectAuth = void 0;

var _reselect = require("reselect");

var _lodash = require("lodash");

var _normalizr = require("normalizr");

var _participants = require("../participants/participants.selectors");

var _schema = _interopRequireDefault(require("../schema"));

const selectAuth = state => {
  return state.auth;
};

exports.selectAuth = selectAuth;

const selectAdmin = state => {
  return state.entities.admin;
};

exports.selectAdmin = selectAdmin;

const selectAdminRoles = state => {
  return state.entities.adminRoles;
};

exports.selectAdminRoles = selectAdminRoles;
const getAdmin = (0, _reselect.createSelector)([selectAdmin], admin => {
  if (admin && admin.allIds && admin.allIds.length > 0) {
    const {
      admin: denormalizedAdmin
    } = (0, _normalizr.denormalize)({
      admin: admin.allIds
    }, _schema.default, {
      admin: admin.byId
    });
    return _lodash._.toArray(denormalizedAdmin);
  }

  return undefined;
});
exports.getAdmin = getAdmin;
const getAdminRoles = (0, _reselect.createSelector)([selectAdminRoles], adminRoles => {
  if (adminRoles && adminRoles.allIds && adminRoles.allIds.length > 0) {
    const {
      adminRoles: denormalizedAdminRoles
    } = (0, _normalizr.denormalize)({
      adminRoles: adminRoles.allIds
    }, _schema.default, {
      adminRoles: adminRoles.byId
    });
    return _lodash._.toArray(denormalizedAdminRoles);
  }

  return undefined;
});
exports.getAdminRoles = getAdminRoles;
const getExpertAdmin = (0, _reselect.createSelector)([getAdmin], admins => {
  if (admins) {
    const experts = _lodash._.filter(admins, a => _lodash._.find(a.roles, r => r.role.role === 'Expert'));

    return experts;
  }

  return [];
});
exports.getExpertAdmin = getExpertAdmin;
const selectUser = (0, _reselect.createSelector)([selectAuth], auth => {
  return auth ? auth.user : undefined;
}); // TODO: See if we need to add a custom function to determine if the user changed. I'm not sure all of the restructuring
//  has eliminated the un-necessary component re-rendering. The re-rendering could also have been a result of the OIDC
//  silent token renewals.

exports.selectUser = selectUser;
const getUser = (0, _reselect.createSelector)([selectUser, _participants.selectParticipants], (user, participants) => {
  if (user && user.id != -1) {
    return user;
  }

  return undefined;
});
exports.getUser = getUser;
const getCurrentUserPseudoParticipants = (0, _reselect.createSelector)([selectUser, _participants.selectParticipants], (user, participants) => {
  var _a;

  if (user && user.id != -1) {
    if (((_a = participants === null || participants === void 0 ? void 0 : participants.allIds) === null || _a === void 0 ? void 0 : _a.length) && user.pseudoParticipantIds.length) {
      const {
        participants: pseudoParticipants
      } = (0, _normalizr.denormalize)({
        participants: user.pseudoParticipantIds
      }, _schema.default, {
        participants: participants.byId
      });
      return pseudoParticipants;
    }
  }

  return [];
}); // YABs don't get to do much

exports.getCurrentUserPseudoParticipants = getCurrentUserPseudoParticipants;
const isNonYAB = (0, _reselect.createSelector)([getUser], user => {
  if (!user) {
    return false;
  }

  if (user.roles.find(role => role.role.role === 'YAB')) {
    return false;
  }

  return true;
});
exports.isNonYAB = isNonYAB;
const hasDataAnalystRole = (0, _reselect.createSelector)([getUser], user => {
  if (!user) {
    return false;
  }

  return !!_lodash._.find(user.roles, r => r.role.role === 'Data Analyst');
});
exports.hasDataAnalystRole = hasDataAnalystRole;
const hasCareNavigatorRole = (0, _reselect.createSelector)([getUser], user => {
  if (!user) {
    return false;
  }

  return !!_lodash._.find(user.roles, r => r.role.role === 'Care Navigator');
});
exports.hasCareNavigatorRole = hasCareNavigatorRole;
const hasStudyManagerRole = (0, _reselect.createSelector)([getUser], user => {
  if (!user) {
    return false;
  }

  return !!_lodash._.find(user.roles, r => r.role.role === 'Study Manager');
});
exports.hasStudyManagerRole = hasStudyManagerRole;
var _default = {
  getUser,
  isNonYAB
};
exports.default = _default;