"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAvatars = exports.getAvatarsAsync = exports.getAvatarAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getAvatarAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_AVATAR_REQUEST', 'hmp/admin/GET_AVATAR_SUCCESS', 'hmp/admin/GET_AVATAR_FAILURE')();
exports.getAvatarAsync = getAvatarAsync;
const getAvatarsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_AVATARS_REQUEST', 'hmp/admin/GET_AVATARS_SUCCESS', 'hmp/admin/GET_AVATARS_FAILURE')();
exports.getAvatarsAsync = getAvatarsAsync;
const updateAvatars = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_AVATARS')();
exports.updateAvatars = updateAvatars;
const avatarActions = {
  getAvatarAsync,
  getAvatarsAsync,
  updateAvatars
};