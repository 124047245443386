"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = articleSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _article = require("./article.types");

var selectors = _interopRequireWildcard(require("../selectors"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const getArticles = (query, topicIds, includeDeleted, includeUnpublished) => {
  let url = `/a/kc/articles?includeDeleted=${includeDeleted}&includeUnpublished=${includeUnpublished}`;

  if (query) {
    url += `&query=${query}`;
  }

  if (topicIds && topicIds.length) {
    url += '&topicIds=' + topicIds.join(',');
  }

  return (0, _api.default)({
    method: 'get',
    url
  });
};

const deleteArticle = articleId => {
  return (0, _api.default)({
    method: 'delete',
    url: `/a/kc/article/${articleId}`
  });
};

const getArticle = articleId => {
  const url = `/a/kc/article/${articleId}`;
  return (0, _api.default)({
    method: 'get',
    url
  });
};

const saveArticle = article => {
  return (0, _api.default)({
    method: 'post',
    url: '/a/cms/save',
    data: article
  });
};

const publishArticle = args => {
  const {
    id,
    publishDate
  } = args;
  return (0, _api.default)({
    method: 'put',
    url: `/a/kc/${id}/publish`,
    data: {
      publishDate
    }
  });
};

const unpublishArticle = id => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/kc/${id}/unpublish`
  });
};

function* loadArticlesHandler(action) {
  try {
    const {
      query,
      topicIds,
      includeDeleted,
      includeUnpublished
    } = action.payload;
    const response = yield (0, _effects.call)(getArticles, query, topicIds, includeDeleted, includeUnpublished);
    const {
      entities,
      result
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.articles);
    const {
      articles
    } = entities;
    yield (0, _effects.put)(_article.loadArticlesAsync.success(articles));
    yield (0, _effects.put)((0, _article.updateSelectedArticleIds)(result));
  } catch (error) {
    yield (0, _effects.put)(_article.loadArticlesAsync.failure(error));
  }
}

function* deleteArticleHandler(action) {
  try {
    const articleId = action.payload;
    const response = yield (0, _effects.call)(deleteArticle, articleId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.articles);
    const {
      articles
    } = entities;
    yield (0, _effects.put)(_article.deleteArticleAsync.success(articles));
  } catch (error) {
    yield (0, _effects.put)(_article.deleteArticleAsync.failure(error));
  }
}

function* getRequestedArticleHandler(action) {
  try {
    const requestedArticleId = yield (0, _effects.select)(selectors.getRequestedEditResourceId);
    const response = yield (0, _effects.call)(getArticle, requestedArticleId); // const {entities} = normalize(response.data, entitySchema.articles) as NormalizerResult;

    const article = {
      [requestedArticleId]: response.data
    };
    yield (0, _effects.put)(_article.getRequestedArticleAsync.success(article));
  } catch (error) {
    yield (0, _effects.put)(_article.getRequestedArticleAsync.failure(error));
  }
}

function* saveArticleHandler(action) {
  try {
    const article = action.payload;
    const response = yield (0, _effects.call)(saveArticle, article);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.articles);
    const {
      articles
    } = entities;
    yield (0, _effects.put)((0, _article.updateNewArticleId)(response.data.id));
    yield (0, _effects.put)(_article.saveArticleAsync.success(articles));
  } catch (error) {
    yield (0, _effects.put)(_article.saveArticleAsync.failure(error));
  }
}

function* publishArticleHandler(action) {
  try {
    const response = yield (0, _effects.call)(publishArticle, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.articles);
    const {
      articles
    } = entities;
    yield (0, _effects.put)(_article.publishArticleAsync.success(articles));
  } catch (error) {
    yield (0, _effects.put)(_article.publishArticleAsync.failure(error));
  }
}

function* unpublishArticleHandler(action) {
  try {
    const response = yield (0, _effects.call)(unpublishArticle, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.articles);
    const {
      articles
    } = entities;
    yield (0, _effects.put)(_article.unpublishArticleAsync.success(articles));
  } catch (error) {
    yield (0, _effects.put)(_article.unpublishArticleAsync.failure(error));
  }
}

function* publishArticleWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_article.publishArticleAsync.request), publishArticleHandler);
}

function* unpublishArticleWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_article.unpublishArticleAsync.request), unpublishArticleHandler);
}

function* loadArticlesWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_article.loadArticlesAsync.request), loadArticlesHandler);
}

function* deleteArticleWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_article.deleteArticleAsync.request), deleteArticleHandler);
}

function* getRequestedArticleWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_article.getRequestedArticleAsync.request), getRequestedArticleHandler);
}

function* saveArticleWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_article.saveArticleAsync.request), saveArticleHandler);
}

function* articleSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(loadArticlesWatcher), (0, _effects.fork)(deleteArticleWatcher), (0, _effects.fork)(getRequestedArticleWatcher), (0, _effects.fork)(saveArticleWatcher), (0, _effects.fork)(publishArticleWatcher), (0, _effects.fork)(unpublishArticleWatcher)]);
}