"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = notificationsSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _qna = require("../qna/qna.types");

var _schema = require("../schema");

var _user = require("../user/user.types");

var _notification = require("./notification.types");

const updateNotificationSubscription = subscription => {
  const emailOn = subscription.emailOn ? "true" : "false";
  const smsOn = subscription.smsOn ? "true" : "false";
  return (0, _api.default)({
    method: 'put',
    url: `/a/notification/${subscription.label}/subscribe?emailOn=${emailOn}&smsOn=${smsOn}`
  });
};

function* updateNotificationSubscriptionHandler(action) {
  try {
    const subscription = action.payload;
    const response = yield (0, _effects.call)(updateNotificationSubscription, subscription);
    yield (0, _effects.put)(_user.getUserAsync.request(subscription.userId));
  } catch (error) {
    yield (0, _effects.put)(_notification.updateNotificationSubscriptionAsync.failure(error));
  }
}

function* updateNotificationSubscriptionWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_notification.updateNotificationSubscriptionAsync.request), updateNotificationSubscriptionHandler);
}

const getMyAdminNotificationSubscriptions = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/notification/myAdminNotificationSubscriptions`
  });
};

function* getMyAdminNotificationSubscriptionsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getMyAdminNotificationSubscriptions);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.notifications);
    const {
      notifications
    } = entities;
    yield (0, _effects.put)(_notification.getMyAdminNotificationSubscriptionsAsync.success(notifications));
  } catch (error) {
    yield (0, _effects.put)(_notification.getMyAdminNotificationSubscriptionsAsync.failure(error));
  }
}

function* getMyAdminNotificationSubscriptionsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_notification.getMyAdminNotificationSubscriptionsAsync.request), getMyAdminNotificationSubscriptionsHandler);
}

const getParticipantNotifications = participantId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/notification/participantNotifications?participantId=${participantId}`
  });
};

function* getParticipantNotificationsHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(getParticipantNotifications, participantId);
    const participantNotifications = response.data ? response.data : [];
    const {
      entities
    } = (0, _normalizr.normalize)(participantNotifications, _schema.entitySchema.notifications);
    const {
      notifications
    } = entities;
    yield (0, _effects.put)(_notification.getParticipantNotificationsAsync.success(notifications));
  } catch (error) {
    yield (0, _effects.put)(_notification.getParticipantNotificationsAsync.failure(error));
  }
}

function* getParticipantNotificationsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_notification.getParticipantNotificationsAsync.request), getParticipantNotificationsHandler);
}

const sendAdHocAdminQnaNotification = (userId, qnaPostId) => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/notification/${userId}/${qnaPostId}/sendUserAdHocQnaEmail`
  });
};

function* sendAdHocAdminQnaNotificationHandler(action) {
  try {
    const {
      userId,
      qnaPostId
    } = action.payload;
    const response = yield (0, _effects.call)(sendAdHocAdminQnaNotification, userId, qnaPostId);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.notifications);
    const {
      notifications
    } = entities;
    yield (0, _effects.put)(_notification.sendAdHocAdminQnaNotificationAsync.success(notifications));
    yield (0, _effects.put)(_qna.getQnaPostAsync.request(qnaPostId));
  } catch (error) {
    yield (0, _effects.put)(_notification.sendAdHocAdminQnaNotificationAsync.failure(error));
  }
}

function* sendAdHocAdminQnaNotificationWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_notification.sendAdHocAdminQnaNotificationAsync.request), sendAdHocAdminQnaNotificationHandler);
}

function* notificationsSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getParticipantNotificationsWatcher), (0, _effects.fork)(getMyAdminNotificationSubscriptionsWatcher), (0, _effects.fork)(updateNotificationSubscriptionWatcher), (0, _effects.fork)(sendAdHocAdminQnaNotificationWatcher)]);
}