"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateForms = exports.sendAdHocAdminQnaNotificationAsync = exports.updateNotificationSubscriptionAsync = exports.getMyAdminNotificationSubscriptionsAsync = exports.getParticipantNotificationsAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getParticipantNotificationsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_PARTICIPANT_NOTIFICATIONS_REQUEST', 'hmp/admin/GET_PARTICIPANT_NOTIFICATIONS_SUCCESS', 'hmp/admin/GET_PARTICIPANT_NOTIFICATIONS_FAILURE')();
exports.getParticipantNotificationsAsync = getParticipantNotificationsAsync;
const getMyAdminNotificationSubscriptionsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_MY_ADMIN_NOTIFICATION_SUBSCRIPTIONS_REQUEST', 'hmp/admin/GET_MY_ADMIN_NOTIFICATION_SUBSCRIPTIONS_SUCCESS', 'hmp/admin/GET_MY_ADMIN_NOTIFICATION_SUBSCRIPTIONS_FAILURE')();
exports.getMyAdminNotificationSubscriptionsAsync = getMyAdminNotificationSubscriptionsAsync;
const updateNotificationSubscriptionAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UPDATE_NOTIFICATION_SUBSCRIPTION_REQUEST', 'hmp/admin/UPDATE_NOTIFICATION_SUBSCRIPTION_SUCCESS', 'hmp/admin/UPDATE_NOTIFICATION_SUBSCRIPTION_FAILURE')();
exports.updateNotificationSubscriptionAsync = updateNotificationSubscriptionAsync;
const sendAdHocAdminQnaNotificationAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/SEND_AD_HOC_ADMIN_QNA_NOTIFICATION_REQUEST', 'hmp/admin/SEND_AD_HOC_ADMIN_QNA_NOTIFICATION_SUCCESS', 'hmp/admin/SEND_AD_HOC_ADMIN_QNA_NOTIFICATION_FAILURE')();
exports.sendAdHocAdminQnaNotificationAsync = sendAdHocAdminQnaNotificationAsync;
const updateForms = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_FORMS')();
exports.updateForms = updateForms;
const notificationsActions = {
  getParticipantNotificationsAsync,
  getMyAdminNotificationSubscriptionsAsync,
  updateNotificationSubscriptionAsync,
  sendAdHocAdminQnaNotificationAsync
};