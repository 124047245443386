"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = appSaga;

var _typesafeActions = require("typesafe-actions");

var _effects = require("redux-saga/effects");

var selectors = _interopRequireWildcard(require("../selectors"));

var _UserManager = _interopRequireDefault(require("../../oidc/UserManager"));

var _app = require("./app.types");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function* logoutHandler() {
  const oidcUser = yield (0, _effects.select)(selectors.selectOidcUser); // Signs you out of the application

  _UserManager.default.removeUser();

  if (oidcUser && oidcUser.id_token) {
    // Signs you out of the Authorization server
    _UserManager.default.signoutRedirect({
      id_token_hint: oidcUser && oidcUser.id_token
    });
  }
}

function* logoutWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_app.logout), logoutHandler);
}

function* appSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(logoutWatcher)]);
}