"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.selectGoalUIState = exports.getRequestedGoal = exports.getGoals = exports.selectGoals = void 0;

var _reselect = require("reselect");

var _reactRouterDom = require("react-router-dom");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = _interopRequireDefault(require("../schema"));

var _router = require("../router/router.selectors");

const selectGoals = state => {
  return state.entities.goals;
};

exports.selectGoals = selectGoals;
const getGoals = (0, _reselect.createSelector)([selectGoals], goals => {
  if (goals && goals.allIds && goals.allIds.length > 0) {
    const {
      goals: denormalizedGoals
    } = (0, _normalizr.denormalize)({
      goals: goals.allIds
    }, _schema.default, {
      goals: goals.byId
    });
    return (0, _toArray.default)(denormalizedGoals);
  }

  return undefined;
});
exports.getGoals = getGoals;
const getRequestedGoal = (0, _reselect.createSelector)([selectGoals, _router.selectRouterLocationPathname], (goals, pathname) => {
  var _a;

  if (pathname) {
    const match = (0, _reactRouterDom.matchPath)(pathname, {
      path: "/study/:studyId/goals/:goalId"
    });
    const goalId = (_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.goalId;

    if (goals && goals.allIds && goals.allIds.length > 0 && goalId && goalId.length > 0) {
      return goals.byId[goalId];
    }
  }

  return undefined;
});
exports.getRequestedGoal = getRequestedGoal;

const selectGoalUIState = state => {
  return state.ui.goals;
};

exports.selectGoalUIState = selectGoalUIState;
var _default = {
  getGoals,
  getRequestedGoal
};
exports.default = _default;