"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleSideBar = void 0;

var _typesafeActions = require("typesafe-actions");

const toggleSideBar = (0, _typesafeActions.createStandardAction)('hmp/admin/SIDEBAR_TOGGLE')();
exports.toggleSideBar = toggleSideBar;
const sideBarActions = {
  toggleSideBar
};