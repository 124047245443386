"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getArticleSearchTerm = exports.getIncludeDeletedArticles = exports.getSelectedArticleTopicIds = exports.selectNewArticleId = exports.getSelectedArticles = exports.selectedArticleIds = exports.selectArticles = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = require("../schema");

var _topic = require("../topics/topic.selectors");

const selectArticles = state => {
  return state.entities.articles;
};

exports.selectArticles = selectArticles;

const selectedArticleIds = state => {
  return state.ui.articles.articleIds;
};

exports.selectedArticleIds = selectedArticleIds;
const getSelectedArticles = (0, _reselect.createSelector)([selectArticles, selectedArticleIds, _topic.selectTopics], (articles, articleIds, selectTopics) => {
  if (articles && articles.allIds && articles.allIds.length > 0) {
    const {
      articles: denormalizedArticles
    } = (0, _normalizr.denormalize)({
      articles: articleIds
    }, _schema.entitySchema, {
      articles: articles.byId,
      topics: selectTopics.byId
    });
    return (0, _toArray.default)(denormalizedArticles);
  }

  return undefined;
});
exports.getSelectedArticles = getSelectedArticles;

const selectNewArticleId = state => {
  return state.ui.articles.newArticleId;
};

exports.selectNewArticleId = selectNewArticleId;

const getSelectedArticleTopicIds = state => {
  return state.ui.articles.selectedTopicIds;
};

exports.getSelectedArticleTopicIds = getSelectedArticleTopicIds;

const getIncludeDeletedArticles = state => {
  return state.ui.articles.includeDeleted;
};

exports.getIncludeDeletedArticles = getIncludeDeletedArticles;

const getArticleSearchTerm = state => {
  return state.ui.articles.searchTerm;
};

exports.getArticleSearchTerm = getArticleSearchTerm;
var _default = {
  selectArticles,
  selectedArticleIds,
  getSelectedArticles,
  selectNewArticleId,
  getSelectedArticleTopicIds,
  getIncludeDeletedArticles,
  getArticleSearchTerm
};
exports.default = _default;