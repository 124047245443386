"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cleanstring = void 0;

const cleanstring = dirty => {
  if (dirty) {
    return dirty.replace(/‘/g, "'").replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"');
  }

  return "";
};

exports.cleanstring = cleanstring;