"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = analyticsSaga;

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _analytics = require("./analytics.types");

// sortBy, sortDir, participantId, studyId, studyArmId, pageNumber, pageSize
const getAnalytics = (sortBy, sortDir, participantId, studyId, studyArmId, pageNumber, pageSize) => {
  let url = `/a/event/all`; //todo do we need the params?

  return (0, _api.default)({
    method: 'get',
    url
  });
};

function* getAnalyticsReportHandler(action) {
  try {
    const response = yield (0, _effects.call)(getAnalytics);
    yield (0, _effects.put)(_analytics.getAnalyticsReportAsync.success(response.data));
  } catch (error) {
    yield (0, _effects.put)(_analytics.getAnalyticsReportAsync.failure(error));
  }
}

function* getAnalyticsReportWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_analytics.getAnalyticsReportAsync.request), getAnalyticsReportHandler);
}

function* analyticsSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getAnalyticsReportWatcher)]);
}