"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reduxOidc = require("redux-oidc");

const SilentRenew = () => {
  (0, _reduxOidc.processSilentRenew)();
  return null;
};

var _default = SilentRenew;
exports.default = _default;