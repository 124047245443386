"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formsSaga;

var _normalizr = require("normalizr");

var _lodash = require("lodash");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _activities = require("./activities.types");

const getActivitySummaries = args => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/activity/all/summaries?${_lodash._.map(_lodash._.keys(args), key => `${key}=${args[key]}`).join('&')}`
  });
};

const getActivity = args => {
  const {
    id
  } = args;
  return (0, _api.default)({
    method: 'get',
    url: `/a/activity/${id}`
  });
};

const getCategories = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/activity/list/categories`
  });
};

const getTypes = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/activity/list/types`
  });
};

const upsertActivity = activity => {
  return (0, _api.default)({
    method: 'post',
    url: `/a/activity/`,
    data: {
      activity
    }
  });
};

const deleteActivity = args => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/activity/${args.id}/delete`,
    data: args
  });
};

const updateActivityComponent = args => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/activity/updateComponent/${args.type}/${args.typeId}`,
    data: {
      component: args.component
    }
  });
};

const publishActivity = id => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/activity/${id}/publish`
  });
};

const unpublishActivity = id => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/activity/${id}/unpublish`
  });
};

const uploadActivityImage = (image, id) => {
  const formData = new FormData();
  formData.append('file', image);
  return (0, _api.default)({
    method: 'POST',
    url: `/a/activity/${id}/upload/`,
    data: formData
  });
};

function* refreshActivitySummariesHandler(args) {
  yield (0, _effects.put)(_activities.getActivitySummariesAsync.request(args));
}

function* refreshActivityHandler(args) {
  yield (0, _effects.put)(_activities.getActivityAsync.request(args));
}

function* getActivitySummariesHandler(action) {
  try {
    const args = action.payload;
    const response = yield (0, _effects.call)(getActivitySummaries, args);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.activities);
    const {
      activities
    } = entities;
    yield (0, _effects.put)(_activities.getActivitySummariesAsync.success(activities));
  } catch (error) {
    yield (0, _effects.put)(_activities.getActivitySummariesAsync.failure(error));
  }
}

function* getActivityHandler(action) {
  try {
    const args = action.payload;
    const response = yield (0, _effects.call)(getActivity, args);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.activities);
    const {
      activities
    } = entities;
    yield (0, _effects.put)(_activities.getActivityAsync.success(activities));
  } catch (error) {
    yield (0, _effects.put)(_activities.getActivityAsync.failure(error));
  }
}

function* getCategoriesHandler(action) {
  try {
    const response = yield (0, _effects.call)(getCategories);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.activityCategories);
    const {
      activityCategories
    } = entities;
    yield (0, _effects.put)(_activities.getActivityCategoriesAsync.success(activityCategories));
  } catch (error) {
    yield (0, _effects.put)(_activities.getActivityCategoriesAsync.failure(error));
  }
}

function* getTypesHandler(action) {
  try {
    const response = yield (0, _effects.call)(getTypes);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.activityTypes);
    const {
      activityTypes
    } = entities;
    yield (0, _effects.put)(_activities.getActivityTypesAsync.success(activityTypes));
  } catch (error) {
    yield (0, _effects.put)(_activities.getActivityTypesAsync.failure(error));
  }
}

function* upsertActivityHandler(action) {
  try {
    const activity = action.payload;
    const response = yield (0, _effects.call)(upsertActivity, activity); // If there is an activity background image, we need to upload the image and update
    // the activity.

    if (response.data.id && activity.upload && activity.background) {
      const {
        upload: image
      } = activity;
      const uploadResponse = yield (0, _effects.call)(uploadActivityImage, image, response.data.id);
      activity.background.filename = uploadResponse.data.link;
      const updateResponse = yield (0, _effects.call)(updateActivityComponent, {
        type: 'activity',
        typeId: response.data.id,
        component: activity
      });
      response.data.background.filename = uploadResponse.data.link;
    }

    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.activities);
    const {
      activities
    } = entities;
    yield (0, _effects.put)(_activities.upsertActivityAsync.success(activities));
    const args = {
      pageNumber: 0,
      pageSize: 100000,
      includeUnpublished: true,
      includeDeleted: true
    };
    yield (0, _effects.call)(refreshActivitySummariesHandler, args);
  } catch (error) {
    yield (0, _effects.put)(_activities.upsertActivityAsync.failure(error));
  }
}

function* updateActivityComponentHandler(action) {
  try {
    const args = action.payload;
    const response = yield (0, _effects.call)(updateActivityComponent, args);
    yield (0, _effects.put)(_activities.updateActivityComponentAsync.success());
  } catch (error) {
    yield (0, _effects.put)(_activities.updateActivityComponentAsync.failure(error));
  }
}

function* deleteActivityHandler(action) {
  try {
    const response = yield (0, _effects.call)(deleteActivity, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.activities);
    const {
      activities
    } = entities;
    const args = {
      pageNumber: 0,
      pageSize: 100000,
      includeUnpublished: true,
      includeDeleted: true
    };
    yield (0, _effects.call)(refreshActivitySummariesHandler, args);
    yield (0, _effects.put)(_activities.deleteActivityAsync.success(activities));
  } catch (error) {
    yield (0, _effects.put)(_activities.deleteActivityAsync.failure(error));
  }
}

function* publishActivityHandler(action) {
  try {
    const id = action.payload;
    const response = yield (0, _effects.call)(publishActivity, id);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.activities);
    const {
      activities
    } = entities;
    yield (0, _effects.put)(_activities.publishActivityAsync.success(activities));
  } catch (error) {
    yield (0, _effects.put)(_activities.publishActivityAsync.failure(error));
  }
}

function* unpublishActivityHandler(action) {
  try {
    const id = action.payload;
    const response = yield (0, _effects.call)(unpublishActivity, id);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.activities);
    const {
      activities
    } = entities;
    yield (0, _effects.put)(_activities.unpublishActivityAsync.success(activities));
  } catch (error) {
    yield (0, _effects.put)(_activities.unpublishActivityAsync.failure(error));
  }
}

function* getActivitySummariesWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_activities.getActivitySummariesAsync.request), getActivitySummariesHandler);
}

function* getActivityWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_activities.getActivityAsync.request), getActivityHandler);
}

function* getCategoriesWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_activities.getActivityCategoriesAsync.request), getCategoriesHandler);
}

function* getTypesWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_activities.getActivityTypesAsync.request), getTypesHandler);
}

function* upsertActivityWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_activities.upsertActivityAsync.request), upsertActivityHandler);
}

function* updateActivityComponentWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_activities.updateActivityComponentAsync.request), updateActivityComponentHandler);
}

function* deleteActivityWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_activities.deleteActivityAsync.request), deleteActivityHandler);
}

function* publishActivityWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_activities.publishActivityAsync.request), publishActivityHandler);
}

function* unpublishActivityWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_activities.unpublishActivityAsync.request), unpublishActivityHandler);
}

function* formsSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getActivitySummariesWatcher), (0, _effects.fork)(getActivityWatcher), (0, _effects.fork)(getCategoriesWatcher), (0, _effects.fork)(getTypesWatcher), (0, _effects.fork)(upsertActivityWatcher), (0, _effects.fork)(updateActivityComponentWatcher), (0, _effects.fork)(deleteActivityWatcher), (0, _effects.fork)(publishActivityWatcher), (0, _effects.fork)(unpublishActivityWatcher)]);
}