"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSearchTerm = exports.updateIncludeDeleted = exports.updateSelectedTopicIds = exports.updateNewArticleId = exports.updateSelectedArticleIds = exports.unpublishArticleAsync = exports.publishArticleAsync = exports.deleteArticleAsync = exports.saveArticleAsync = exports.getRequestedArticleAsync = exports.loadArticlesAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const loadArticlesAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/LOAD_ARTICLES_REQUEST', 'hmp/admin/LOAD_ARTICLES_SUCCESS', 'hmp/admin/LOAD_ARTICLES_FAILURE')();
exports.loadArticlesAsync = loadArticlesAsync;
const getRequestedArticleAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_ARTICLE_REQUEST', 'hmp/admin/GET_ARTICLE_SUCCESS', 'hmp/admin/GET_ARTICLE_FAILURE')();
exports.getRequestedArticleAsync = getRequestedArticleAsync;
const saveArticleAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/SAVE_ARTICLE_REQUEST', 'hmp/admin/SAVE_ARTICLE_SUCCESS', 'hmp/admin/SAVE_ARTICLE_FAILURE')();
exports.saveArticleAsync = saveArticleAsync;
const deleteArticleAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/DELETE_ARTICLE_REQUEST', 'hmp/admin/DELETE_ARTICLE_SUCCESS', 'hmp/admin/DELETE_ARTICLE_FAILURE')();
exports.deleteArticleAsync = deleteArticleAsync;
const publishArticleAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/PUBLISH_ARTICLE_REQUEST', 'hmp/admin/PUBLISH_ARTICLE_SUCCESS', 'hmp/admin/PUBLISH_ARTICLE_FAILURE')();
exports.publishArticleAsync = publishArticleAsync;
const unpublishArticleAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/UNPUBLISH_ARTICLE_REQUEST', 'hmp/admin/UNPUBLISH_ARTICLE_SUCCESS', 'hmp/admin/UNPUBLISH_ARTICLE_FAILURE')();
exports.unpublishArticleAsync = unpublishArticleAsync;
const updateSelectedArticleIds = (0, _typesafeActions.createStandardAction)('hmp/admin/UPDATE_ARTCILE_IDS')();
exports.updateSelectedArticleIds = updateSelectedArticleIds;
const updateNewArticleId = (0, _typesafeActions.createStandardAction)("hmp/admin/UPDATE_NEW_ARTICLE_ID")();
exports.updateNewArticleId = updateNewArticleId;
const updateSelectedTopicIds = (0, _typesafeActions.createStandardAction)("hmp/admin/UPDATE_SELECTED_ARTICLE_TOPIC_IDS")();
exports.updateSelectedTopicIds = updateSelectedTopicIds;
const updateIncludeDeleted = (0, _typesafeActions.createStandardAction)("hmp/admin/UPDATE_INCLUDE_DELETED_ARTICLE")();
exports.updateIncludeDeleted = updateIncludeDeleted;
const updateSearchTerm = (0, _typesafeActions.createStandardAction)("hmp/admin/UPDATE_SEARCH_TERM_ARTICLE")();
exports.updateSearchTerm = updateSearchTerm;
const articleActions = {
  loadArticlesAsync,
  deleteArticleAsync,
  getRequestedArticleAsync,
  updateSelectedArticleIds,
  saveArticleAsync,
  publishArticleAsync,
  unpublishArticleAsync,
  updateNewArticleId,
  updateSelectedTopicIds,
  updateIncludeDeleted,
  updateSearchTerm
};