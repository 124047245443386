"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getAvatars = exports.selectAvatars = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = require("../schema");

const selectAvatars = state => {
  return state.entities.avatars;
};

exports.selectAvatars = selectAvatars;
const getAvatars = (0, _reselect.createSelector)([selectAvatars], avatars => {
  if (avatars && avatars.allIds && avatars.allIds.length > 0) {
    const {
      avatars: denormalizedAvatars
    } = (0, _normalizr.denormalize)({
      avatars: avatars.allIds
    }, _schema.entitySchema, {
      avatars: avatars.byId
    });
    return (0, _toArray.default)(denormalizedAvatars);
  }

  return undefined;
});
exports.getAvatars = getAvatars;
var _default = {
  getAvatars,
  selectAvatars
};
exports.default = _default;