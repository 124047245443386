"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.studyUIReducer = exports.initialStudyUIState = void 0;

var _typesafeActions = require("typesafe-actions");

var _study = require("./study.types");

const initialStudyUIState = {
  stats: []
};
exports.initialStudyUIState = initialStudyUIState;

const studyUIReducer = (state = initialStudyUIState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_study.loadStudyStatsAsync.success):
      return { ...state,
        stats: action.payload
      };

    default:
      return state;
  }
};

exports.studyUIReducer = studyUIReducer;
var _default = studyUIReducer;
exports.default = _default;