"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getStudyStats = exports.getStudies = exports.getStudyArms = exports.selectStudyArms = exports.selectStudies = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = require("../schema");

const selectStudies = state => {
  return state.entities.studies;
};

exports.selectStudies = selectStudies;

const selectStudyArms = state => {
  return state.entities.studyArms;
};

exports.selectStudyArms = selectStudyArms;
const getStudyArms = (0, _reselect.createSelector)([selectStudyArms], studyArms => {
  if (studyArms.allIds && studyArms.allIds.length > 0) {
    const {
      studyArms: denormalizedStudyArms
    } = (0, _normalizr.denormalize)({
      studyArms: studyArms.allIds
    }, _schema.entitySchema, {
      studyArms: studyArms.byId
    });
    return (0, _toArray.default)(denormalizedStudyArms);
  }

  return undefined;
});
exports.getStudyArms = getStudyArms;
const getStudies = (0, _reselect.createSelector)([selectStudies, selectStudyArms], (studies, studyArms) => {
  if (studies.allIds && studies.allIds.length > 0) {
    const {
      studies: denormalizedStudies
    } = (0, _normalizr.denormalize)({
      studies: studies.allIds
    }, _schema.entitySchema, {
      studies: studies.byId,
      studyArms: studyArms.byId
    });
    return (0, _toArray.default)(denormalizedStudies);
  }

  return undefined;
});
exports.getStudies = getStudies;

const getStudyStats = state => {
  return state.ui.study.stats;
};

exports.getStudyStats = getStudyStats;
var _default = getStudies;
exports.default = _default;