"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = messagesSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _dashboard = require("../dashboard/dashboard.types");

var _schema = require("../schema");

var _messages = require("./messages.types");

let lastInboxParams;
let lastGetMessagesParam;

const getParticipantThreads = participantId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/message/threadsByParticipant/${participantId}`
  });
};

const getMessageInbox = args => {
  let {
    pageNumber,
    pageSize,
    previewLength,
    searchTerm,
    view,
    filterBy,
    sortBy
  } = args;
  let url = `/a/message/inbox`;
  url += `?pageNumber=${pageNumber ? pageNumber : 0}`;
  url += `&pageSize=${pageSize ? pageSize : 25}`;
  url += `&previewLength=${previewLength ? previewLength : 30}`;
  url += `&view=${view ? view : 'all'}`;
  url += `&sortBy=${sortBy ? sortBy : 'newest'}`;

  if (searchTerm) {
    url += `&searchTerm=${searchTerm}`;
  }

  if (filterBy) {
    url += `&filterBy=${filterBy}`;
  }

  return (0, _api.default)({
    method: 'get',
    url
  });
};

const getMessageThreadCount = () => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/message/threadCount`
  });
};

const getMessages = args => {
  let {
    threadId,
    pageNumber,
    pageSize
  } = args;

  if (!pageSize) {
    pageSize = 16;
  }

  if (!pageNumber) {
    pageNumber = 0;
  }

  return (0, _api.default)({
    method: 'get',
    url: `/a/message/thread/${threadId}?pageSize=${pageSize}&pageNumber=${pageNumber}`
  });
};

const createThread = thread => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/message/newThread/${thread.participantId}`,
    data: {
      title: thread.title,
      body: thread.body
    }
  });
};

const sendMessage = args => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/message/${args.threadId}`,
    data: {
      body: args.body
    }
  });
};

const archiveThread = threadId => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/message/${threadId}/archive`
  });
};

const unarchiveThread = threadId => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/message/${threadId}/unarchive`
  });
};

const markImportant = threadId => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/message/${threadId}/important`
  });
};

const unmarkImportant = threadId => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/message/${threadId}/unimportant`
  });
};

const markUnread = threadId => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/message/${threadId}/unread`
  });
};

const markRead = threadId => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/message/${threadId}/read`
  });
};

const assignThread = (threadId, userId) => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/message/${threadId}/${userId}/assign`
  });
};

const unassignThread = (threadId, userId) => {
  return (0, _api.default)({
    method: 'put',
    url: `/a/message/${threadId}/${userId}/unassign`
  });
};

function* updateMessagesHandler(messages) {
  if (messages) {
    yield (0, _effects.put)((0, _messages.updateMessages)(messages));
  }
}

function* refreshInboxAndThreadHandler(refreshInboxAndThreadAction) {
  yield (0, _effects.call)(refreshMessageInboxHandler);
  yield (0, _effects.call)(refreshThreadHandler, refreshInboxAndThreadAction.payload);
}

function* refreshThreadHandler(threadId) {
  if (lastGetMessagesParam && lastGetMessagesParam.threadId === threadId) {
    const pageSize = lastGetMessagesParam.pageNumber && lastGetMessagesParam.pageSize ? (lastGetMessagesParam.pageNumber + 1) * lastGetMessagesParam.pageSize : 16;
    const refreshParams = { ...lastGetMessagesParam,
      pageNumber: 0,
      pageSize
    };
    yield (0, _effects.put)(_messages.getMessagesAsync.request(refreshParams));
  }
}

function* refreshMessageInboxHandler() {
  const refreshParams = { ...lastInboxParams,
    pageNumber: 0,
    pageSize: (lastInboxParams.pageNumber + 1) * lastInboxParams.pageSize
  };
  yield (0, _effects.put)(_messages.getMessageInboxAsync.request(refreshParams));
  yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.request());
}

function* getParticipantThreadsHandler(action) {
  try {
    const participantId = action.payload;
    const response = yield (0, _effects.call)(getParticipantThreads, participantId);
    const participantThreads = response.data ? response.data : [];
    const {
      entities
    } = (0, _normalizr.normalize)(participantThreads, _schema.entitySchema.threads);
    const {
      threads
    } = entities;
    yield (0, _effects.put)(_messages.getParticipantThreadsAsync.success(threads));
  } catch (error) {
    yield (0, _effects.put)(_messages.getParticipantThreadsAsync.failure(error));
  }
}

function* getMessageInboxHandler(action) {
  try {
    lastInboxParams = action.payload;
    const response = yield (0, _effects.call)(getMessageInbox, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data.data, _schema.entitySchema.threads);
    const {
      threads
    } = entities;
    yield (0, _effects.put)(_messages.getMessageInboxAsync.success(threads));
    yield (0, _effects.put)((0, _messages.updateMessageThreadCount)(response.data.total));
    yield (0, _effects.put)(_dashboard.loadDashboardDataAsync.request());
  } catch (error) {
    yield (0, _effects.put)(_messages.getMessageInboxAsync.failure(error));
  }
}

function* getMessageThreadCountHandler(action) {
  try {
    const response = yield (0, _effects.call)(getMessageThreadCount);
    yield (0, _effects.put)(_messages.getMessageThreadCountAsync.success(response.data));
  } catch (error) {
    yield (0, _effects.put)(_messages.getMessageThreadCountAsync.failure(error));
  }
}

function* getMessagesHandler(action) {
  try {
    lastGetMessagesParam = action.payload;
    const response = yield (0, _effects.call)(getMessages, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.messageResponses);
    const {
      messages,
      messageResponses
    } = entities;
    yield (0, _effects.call)(updateMessagesHandler, messages);
    yield (0, _effects.call)(refreshMessageInboxHandler);
    yield (0, _effects.put)(_messages.getMessagesAsync.success(messageResponses));
  } catch (error) {
    yield (0, _effects.put)(_messages.getMessagesAsync.failure(error));
  }
}

function* createThreadHandler(action) {
  try {
    const response = yield (0, _effects.call)(createThread, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.threads);
    const {
      threads
    } = entities;
    yield (0, _effects.put)(_messages.createThreadAsync.success(threads));
  } catch (error) {
    yield (0, _effects.put)(_messages.createThreadAsync.failure(error));
  }
}

function* sendMessageHandler(action) {
  try {
    const response = yield (0, _effects.call)(sendMessage, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.messages);
    const {
      messages
    } = entities;
    yield (0, _effects.call)(refreshMessageInboxHandler);
    yield (0, _effects.call)(refreshMessagesHandler, action.payload.threadId);
    yield (0, _effects.put)(_messages.sendMessageAsync.success(messages));
  } catch (error) {
    yield (0, _effects.put)(_messages.sendMessageAsync.failure(error));
  }
}

function* archiveThreadHandler(action) {
  try {
    const response = yield (0, _effects.call)(archiveThread, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.threads);
    const {
      threads
    } = entities;
    yield (0, _effects.put)(_messages.archiveThreadAsync.success(threads));
  } catch (error) {
    yield (0, _effects.put)(_messages.archiveThreadAsync.failure(error));
  }
}

function* unarchiveThreadHandler(action) {
  try {
    const response = yield (0, _effects.call)(unarchiveThread, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.threads);
    const {
      threads
    } = entities;
    yield (0, _effects.put)(_messages.unarchiveThreadAsync.success(threads));
  } catch (error) {
    yield (0, _effects.put)(_messages.unarchiveThreadAsync.failure(error));
  }
}

function* markThreadImportantHandler(action) {
  try {
    const response = yield (0, _effects.call)(markImportant, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.threads);
    const {
      threads
    } = entities;
    yield (0, _effects.put)(_messages.markThreadImportantAsync.success(threads));
  } catch (error) {
    yield (0, _effects.put)(_messages.markThreadImportantAsync.failure(error));
  }
}

function* unmarkThreadImportantHandler(action) {
  try {
    const response = yield (0, _effects.call)(unmarkImportant, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.threads);
    const {
      threads
    } = entities;
    yield (0, _effects.put)(_messages.unmarkThreadImportantAsync.success(threads));
  } catch (error) {
    yield (0, _effects.put)(_messages.unmarkThreadImportantAsync.failure(error));
  }
}

function* markThreadUnreadHandler(action) {
  try {
    const response = yield (0, _effects.call)(markUnread, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.threads);
    const {
      threads
    } = entities;
    yield (0, _effects.put)(_messages.markThreadUnreadAsync.success(threads));
  } catch (error) {
    yield (0, _effects.put)(_messages.markThreadUnreadAsync.failure(error));
  }
}

function* markThreadReadHandler(action) {
  try {
    const response = yield (0, _effects.call)(markRead, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.threads);
    const {
      threads
    } = entities;
    yield (0, _effects.put)(_messages.markThreadReadAsync.success(threads));
  } catch (error) {
    yield (0, _effects.put)(_messages.markThreadReadAsync.failure(error));
  }
}

function* assignThreadHandler(action) {
  try {
    const {
      threadId,
      userId
    } = action.payload;
    const response = yield (0, _effects.call)(assignThread, threadId, userId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.threads);
    const {
      threads
    } = entities;
    yield (0, _effects.put)(_messages.assignThreadAsync.success(threads));
  } catch (error) {
    yield (0, _effects.put)(_messages.assignThreadAsync.failure(error));
  }
}

function* unassignThreadHandler(action) {
  try {
    const {
      threadId,
      userId
    } = action.payload;
    const response = yield (0, _effects.call)(unassignThread, threadId, userId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.threads);
    const {
      threads
    } = entities;
    yield (0, _effects.put)(_messages.unassignThreadAsync.success(threads));
  } catch (error) {
    yield (0, _effects.put)(_messages.unassignThreadAsync.failure(error));
  }
}

function* threadActionSuccessHandler(action) {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_messages.archiveThreadAsync.success):
    case (0, _typesafeActions.getType)(_messages.unarchiveThreadAsync.success):
    case (0, _typesafeActions.getType)(_messages.assignThreadAsync.success):
    case (0, _typesafeActions.getType)(_messages.unassignThreadAsync.success):
      yield* clearThreadsHandler();
  }

  yield* refreshMessageInboxHandler();
}

function* clearThreadsHandler() {
  yield (0, _effects.put)((0, _messages.clearMessages)());
}

function* getParticipantMessagesWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.getParticipantThreadsAsync.request), getParticipantThreadsHandler);
}

function* getMessageInboxWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_messages.getMessageInboxAsync.request), getMessageInboxHandler);
}

function* getMessageThreadCountWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.getMessageThreadCountAsync.request), getMessageThreadCountHandler);
}

function* getMessagesWatcher() {
  yield (0, _effects.takeEvery)((0, _typesafeActions.getType)(_messages.getMessagesAsync.request), getMessagesHandler);
}

function* createThreadWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.createThreadAsync.request), createThreadHandler);
}

function* refreshMessagesHandler(threadId) {
  yield (0, _effects.put)(_messages.getMessagesAsync.request({
    threadId
  }));
}

function* sendMessageWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.sendMessageAsync.request), sendMessageHandler);
}

function* archiveThreadWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.archiveThreadAsync.request), archiveThreadHandler);
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.archiveThreadAsync.success), threadActionSuccessHandler);
}

function* unarchiveThreadWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.unarchiveThreadAsync.request), unarchiveThreadHandler);
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.unarchiveThreadAsync.success), threadActionSuccessHandler);
}

function* markThreadImportantWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.markThreadImportantAsync.request), markThreadImportantHandler);
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.markThreadImportantAsync.success), threadActionSuccessHandler);
}

function* unmarkThreadImportantWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.unmarkThreadImportantAsync.request), unmarkThreadImportantHandler);
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.unmarkThreadImportantAsync.success), threadActionSuccessHandler);
}

function* markThreadUnreadWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.markThreadUnreadAsync.request), markThreadUnreadHandler);
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.markThreadUnreadAsync.success), threadActionSuccessHandler);
}

function* markThreadReadWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.markThreadReadAsync.request), markThreadReadHandler);
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.markThreadReadAsync.success), threadActionSuccessHandler);
}

function* assignThreadWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.assignThreadAsync.request), assignThreadHandler);
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.assignThreadAsync.success), threadActionSuccessHandler);
}

function* unassignThreadWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.unassignThreadAsync.request), unassignThreadHandler);
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.unassignThreadAsync.success), threadActionSuccessHandler);
}

function* refreshInboxAndThreadWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.refreshInboxAndThread), refreshInboxAndThreadHandler);
}

function* refreshInboxWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_messages.refreshInbox), refreshMessageInboxHandler);
}

function* messagesSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getParticipantMessagesWatcher), (0, _effects.fork)(getMessageInboxWatcher), (0, _effects.fork)(getMessageThreadCountWatcher), (0, _effects.fork)(getMessagesWatcher), (0, _effects.fork)(createThreadWatcher), (0, _effects.fork)(sendMessageWatcher), (0, _effects.fork)(archiveThreadWatcher), (0, _effects.fork)(unarchiveThreadWatcher), (0, _effects.fork)(markThreadImportantWatcher), (0, _effects.fork)(unmarkThreadImportantWatcher), (0, _effects.fork)(markThreadUnreadWatcher), (0, _effects.fork)(markThreadReadWatcher), (0, _effects.fork)(assignThreadWatcher), (0, _effects.fork)(unassignThreadWatcher), (0, _effects.fork)(refreshInboxAndThreadWatcher), (0, _effects.fork)(refreshInboxWatcher)]);
}