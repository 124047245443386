"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAnalyticsReportAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getAnalyticsReportAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_ANALYTICS_REPORT_REQUEST', 'hmp/admin/GET_ANALYTICS_REPORT_SUCCESS', 'hmp/admin/GET_ANALYTICS_REPORT_FAILURE')();
exports.getAnalyticsReportAsync = getAnalyticsReportAsync;
const analyticsActions = {
  getAnalyticsReportAsync
};