"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCompletedTestkits = exports.getNeedsReviewTestkits = exports.getAwaitingResultTestkits = exports.getRequestedTestkits = exports.getTestkits = exports.selectTestkits = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _participants = require("../../redux/participants/participants.selectors");

var _schema = require("../schema");

const selectTestkits = state => {
  return state.entities.testkits;
};

exports.selectTestkits = selectTestkits;
const getTestkits = (0, _reselect.createSelector)([selectTestkits, _participants.selectParticipants], (testkits, participants) => {
  if (testkits.allIds && testkits.allIds.length > 0) {
    const {
      testkits: denormalizedTestkits
    } = (0, _normalizr.denormalize)({
      testkits: testkits.allIds
    }, _schema.entitySchema, {
      testkits: testkits.byId,
      participants: participants.byId
    });
    return (0, _toArray.default)(denormalizedTestkits);
  }

  return undefined;
});
exports.getTestkits = getTestkits;
const getRequestedTestkits = (0, _reselect.createSelector)([getTestkits], testkits => {
  return testkits ? testkits.filter(t => t.sentDate === null) : [];
});
exports.getRequestedTestkits = getRequestedTestkits;
const getAwaitingResultTestkits = (0, _reselect.createSelector)([getTestkits], testkits => {
  return testkits ? testkits.filter(t => t.sentDate !== null && t.reportDate === null && t.completedDate === null) : [];
});
exports.getAwaitingResultTestkits = getAwaitingResultTestkits;
const getNeedsReviewTestkits = (0, _reselect.createSelector)([getTestkits], testkits => {
  return testkits ? testkits.filter(t => t.sentDate !== null && t.reportDate !== null && t.completedDate === null) : [];
});
exports.getNeedsReviewTestkits = getNeedsReviewTestkits;
const getCompletedTestkits = (0, _reselect.createSelector)([getTestkits], testkits => {
  return testkits ? testkits.filter(t => t.completedDate !== null) : [];
});
exports.getCompletedTestkits = getCompletedTestkits;