"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getCommentFlags = exports.getFlags = exports.selectFlags = void 0;

var _reselect = require("reselect");

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _participants = require("../participants/participants.selectors");

var _schema = require("../schema");

var _user = require("../user/user.selectors");

const selectFlags = state => {
  return state.entities.flags;
};

exports.selectFlags = selectFlags;
const getFlags = (0, _reselect.createSelector)([selectFlags, _participants.selectParticipants, _user.selectAdmin], (flags, participants, admins) => {
  var _a, _b, _c;

  if (((_a = flags === null || flags === void 0 ? void 0 : flags.allIds) === null || _a === void 0 ? void 0 : _a.length) && ((_b = participants === null || participants === void 0 ? void 0 : participants.allIds) === null || _b === void 0 ? void 0 : _b.length) && ((_c = admins === null || admins === void 0 ? void 0 : admins.allIds) === null || _c === void 0 ? void 0 : _c.length)) {
    const {
      flags: denormalizedFlags
    } = (0, _normalizr.denormalize)({
      flags: flags.allIds
    }, _schema.entitySchema, {
      flags: flags.byId,
      participants: participants.byId,
      admin: admins.byId
    });
    return (0, _toArray.default)(denormalizedFlags);
  }

  return undefined;
});
exports.getFlags = getFlags;
const getCommentFlags = (0, _reselect.createSelector)([selectFlags, _participants.selectParticipants, _user.selectAdmin], (flags, participants, admins) => {
  var _a, _b, _c;

  if (((_a = flags === null || flags === void 0 ? void 0 : flags.allIds) === null || _a === void 0 ? void 0 : _a.length) && ((_b = participants === null || participants === void 0 ? void 0 : participants.allIds) === null || _b === void 0 ? void 0 : _b.length) && ((_c = admins === null || admins === void 0 ? void 0 : admins.allIds) === null || _c === void 0 ? void 0 : _c.length)) {
    const {
      flags: denormalizedFlags
    } = (0, _normalizr.denormalize)({
      flags: flags.allIds
    }, _schema.entitySchema, {
      flags: flags.byId,
      participants: participants.byId,
      admin: admins.byId
    });
    const commentFlags = (0, _toArray.default)(denormalizedFlags).filter(f => f.type === 'comment');
    return commentFlags;
  }

  return undefined;
});
exports.getCommentFlags = getCommentFlags;
var _default = {
  getFlags
};
exports.default = _default;