"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = studySaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _study = require("./study.types");

var _participants = require("../participants/participants.sagas");

var _avatar = require("../avatar/avatar.types");

var _user = require("../user/user.types");

var _forum = require("../forum/forum.types");

var _lodash = _interopRequireDefault(require("lodash"));

var _topic = require("../topics/topic.types");

// TODO The load functions should probably be at a higher level in the root saga or something, since it's coordinating
//  across concerns. However, right now I'm just going to import the participant handler from the participants sagas.
function* updateStudiesHandler(studies) {
  if (studies) {
    yield (0, _effects.put)((0, _study.updateStudies)(studies));
  }
}

function* updateStudyArmsHandler(studyArms) {
  if (studyArms) {
    yield (0, _effects.put)((0, _study.updateStudyArms)(studyArms));

    const armIds = _lodash.default.keys(studyArms);

    for (let i = 0; i < armIds.length; i++) {
      const arm = studyArms[armIds[i]];

      const forumFeature = _lodash.default.find(arm.features, f => f.feature === 'FORUM');

      if (forumFeature === null || forumFeature === void 0 ? void 0 : forumFeature.forumId) {
        yield (0, _effects.put)(_topic.loadForumTopicsAsync.request(forumFeature.forumId));
      }
    }
  }
}
/**
 * Orchestrates the loading of studies for the Admin portion of the portal. It allows us to monitor the storing of
 * Study and Study Arm data to make sure both actions have finished, which helps the UI know when the studies have
 * been fully loaded.
 *
 * @param action the request to load the study data for the Admin portion of the portal.
 */


function* loadStudiesHandler(action) {
  try {
    const response = yield (0, _effects.call)(getStudies);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.studies);
    const {
      studies,
      studyArms
    } = entities;
    yield (0, _effects.call)(updateStudiesHandler, studies);
    yield (0, _effects.call)(updateStudyArmsHandler, studyArms);
    yield (0, _effects.put)(_avatar.getAvatarsAsync.request());
    yield (0, _effects.put)(_user.getAdminAsync.request());
    yield (0, _effects.put)(_study.loadStudiesAsync.success());
  } catch (error) {
    yield (0, _effects.put)(_study.loadStudiesAsync.failure(error));
  }
}

function* loadStudiesWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_study.loadStudiesAsync.request), loadStudiesHandler);
}
/**
 * Orchestrates the loading of a study for the Study portion of the portal. It allows us to monitor the storing of
 * Study, Study Arm, and Participant data to make sure all actions have finished, which helps the UI know when the study
 * has been fully loaded.
 *
 * @param action the request to load a study for the Study portion of the portal.
 */


function* loadStudyHandler(action) {
  try {
    const studyId = action.payload;
    const response = yield (0, _effects.call)(getStudy, studyId);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.studies);
    const {
      studies,
      studyArms,
      participants
    } = entities;
    yield (0, _effects.call)(updateStudiesHandler, studies);
    yield (0, _effects.call)(updateStudyArmsHandler, studyArms);
    yield (0, _effects.call)(_participants.updateParticipantsHandler, participants);
    yield (0, _effects.put)(_avatar.getAvatarsAsync.request());
    yield (0, _effects.put)(_user.getAdminAsync.request());
    yield (0, _effects.put)(_user.getAdminRolesAsync.request());
    yield (0, _effects.put)(_forum.getForumDetailsByStudyIdAsync.request(studyId));
    yield (0, _effects.put)(_study.loadStudyAsync.success());
  } catch (error) {
    yield (0, _effects.put)(_study.loadStudyAsync.failure(error));
  }
}

function* loadStudyWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_study.loadStudyAsync.request), loadStudyHandler);
}
/**
 * Makes the service call to get the studies for the Admin portion of the portal. The service returns both Study and
 * Study Arm information but not Participant information.
 */


const getStudies = () => {
  return (0, _api.default)({
    method: 'get',
    url: '/a/study'
  });
};
/**
 * Makes the service call to get the study for the Study portion of the portal. The service returns Study, Study Arm,
 * and Participant information.
 *
 * @param studyId the id of the study to get
 */


const getStudy = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/study/${studyId}`
  });
};

const loadStudyStats = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/study/${studyId}/stats`
  });
};

function* loadStudyStatsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_study.loadStudyStatsAsync.request), loadStudyStatsHandler);
}
/**
 * Orchestrates the loading of a study for the Study portion of the portal. It allows us to monitor the storing of
 * Study, Study Arm, and Participant data to make sure all actions have finished, which helps the UI know when the study
 * has been fully loaded.
 *
 * @param action the request to load a study for the Study portion of the portal.
 */


function* loadStudyStatsHandler(action) {
  try {
    const studyId = action.payload;
    const response = yield (0, _effects.call)(loadStudyStats, studyId);
    yield (0, _effects.put)(_study.loadStudyStatsAsync.success(response.data));
  } catch (error) {
    yield (0, _effects.put)(_study.loadStudyStatsAsync.failure(error));
  }
}

function* studySaga() {
  yield (0, _effects.all)([(0, _effects.fork)(loadStudiesWatcher), (0, _effects.fork)(loadStudyWatcher), (0, _effects.fork)(loadStudyStatsWatcher)]);
}