"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.markPaidAsync = exports.getPaymentsReportAsync = exports.getPaymentsAsync = exports.getParticipantPaymentsAsync = exports.requestAdHocPaymentAsync = exports.requestExitInterviewPaymentAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const requestExitInterviewPaymentAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/REQUEST_EXIT_INTERVIEW_PAYMENT_REQUEST', 'hmp/admin/REQUEST_EXIT_INTERVIEW_PAYMENT_SUCCESS', 'hmp/admin/REQUEST_EXIT_INTERVIEW_PAYMENT_FAILURE')();
exports.requestExitInterviewPaymentAsync = requestExitInterviewPaymentAsync;
const requestAdHocPaymentAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/REQUEST_AD_HOCPAYMENT_REQUEST', 'hmp/admin/REQUEST_AD_HOC_PAYMENT_SUCCESS', 'hmp/admin/REQUEST_AD_HOC_PAYMENT_FAILURE')();
exports.requestAdHocPaymentAsync = requestAdHocPaymentAsync;
const getParticipantPaymentsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_PARTICIPANT_PAYMENTS_REQUEST', 'hmp/admin/GET_PARTICIPANT_PAYMENTS_SUCCESS', 'hmp/admin/GET_PARTICIPANT_PAYMENTS_FAILURE')();
exports.getParticipantPaymentsAsync = getParticipantPaymentsAsync;
const getPaymentsAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_PAYMENTS_REQUEST', 'hmp/admin/GET_PAYMENTS_SUCCESS', 'hmp/admin/GET_PAYMENTS_FAILURE')();
exports.getPaymentsAsync = getPaymentsAsync;
const getPaymentsReportAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/GET_PAYMENTS_REPORT_REQUEST', 'hmp/admin/GET_PAYMENTS_REPORT_SUCCESS', 'hmp/admin/GET_PAYMENTS_REPORT_FAILURE')();
exports.getPaymentsReportAsync = getPaymentsReportAsync;
const markPaidAsync = (0, _typesafeActions.createAsyncAction)('hmp/admin/MARK_PAID_REQUEST', 'hmp/admin/MARK_PAID_SUCCESS', 'hmp/admin/MARK_PAID_FAILURE')();
exports.markPaidAsync = markPaidAsync;
const paymentActions = {
  getPaymentsAsync,
  getPaymentsReportAsync,
  requestExitInterviewPaymentAsync,
  requestAdHocPaymentAsync,
  getParticipantPaymentsAsync,
  markPaidAsync
};