"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getForums = exports.selectForums = exports.selectFlaggedPostIds = exports.selectForumUncategorizedPostIds = void 0;

var _toArray = _interopRequireDefault(require("lodash/toArray"));

var _normalizr = require("normalizr");

var _schema = _interopRequireDefault(require("../schema"));

var _reselect = require("reselect");

const selectForumUncategorizedPostIds = state => {
  return state.ui.forums.uncategorizedPostIds;
};

exports.selectForumUncategorizedPostIds = selectForumUncategorizedPostIds;

const selectFlaggedPostIds = state => {
  return state.ui.forums.flaggedPostIds;
};

exports.selectFlaggedPostIds = selectFlaggedPostIds;

const selectForums = state => {
  return state.entities.forums;
};

exports.selectForums = selectForums;
const getForums = (0, _reselect.createSelector)([selectForums], forums => {
  if (forums && forums.allIds && forums.allIds.length > 0) {
    const {
      forums: denormalizedForums
    } = (0, _normalizr.denormalize)({
      forums: forums.allIds
    }, _schema.default, {
      forums: forums.byId
    });
    return (0, _toArray.default)(denormalizedForums);
  }

  return undefined;
});
exports.getForums = getForums;
var _default = {
  selectForumUncategorizedPostIds
};
exports.default = _default;