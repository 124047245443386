"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = announcementsSaga;

var _normalizr = require("normalizr");

var _effects = require("redux-saga/effects");

var _typesafeActions = require("typesafe-actions");

var _api = _interopRequireDefault(require("../api"));

var _schema = require("../schema");

var _announcements = require("./announcements.types");

const getAnnouncements = studyId => {
  return (0, _api.default)({
    method: 'get',
    url: `/a/announcement/${studyId}`
  });
};

const createAnnouncement = announcement => {
  const {
    type,
    typeId
  } = announcement;
  return (0, _api.default)({
    method: 'put',
    url: `/a/announcement/${type}/${typeId}/create`,
    data: announcement
  });
};

const updateAnnouncement = announcement => {
  const {
    id
  } = announcement;
  return (0, _api.default)({
    method: 'put',
    url: `/a/announcement/${id}/update`,
    data: announcement
  });
};

function* getAnnouncementsHandler(action) {
  try {
    const response = yield (0, _effects.call)(getAnnouncements, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)(response.data, _schema.entitySchema.announcements);
    const {
      announcements
    } = entities;
    yield (0, _effects.put)(_announcements.getAnnouncementsAsync.success(announcements));
  } catch (error) {
    yield (0, _effects.put)(_announcements.getAnnouncementsAsync.failure(error));
  }
}

function* createAnnouncementHandler(action) {
  try {
    const response = yield (0, _effects.call)(createAnnouncement, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.announcements);
    const {
      announcements
    } = entities;
    yield (0, _effects.put)(_announcements.createAnnouncementAsync.success(announcements));
  } catch (error) {
    yield (0, _effects.put)(_announcements.createAnnouncementAsync.failure(error));
  }
}

function* updateAnnouncementHandler(action) {
  try {
    const response = yield (0, _effects.call)(updateAnnouncement, action.payload);
    const {
      entities
    } = (0, _normalizr.normalize)([response.data], _schema.entitySchema.announcements);
    const {
      announcements
    } = entities;
    yield (0, _effects.put)(_announcements.updateAnnouncementAsync.success(announcements));
  } catch (error) {
    yield (0, _effects.put)(_announcements.updateAnnouncementAsync.failure(error));
  }
}

function* getAnnouncementsWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_announcements.getAnnouncementsAsync.request), getAnnouncementsHandler);
}

function* createAnnouncementWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_announcements.createAnnouncementAsync.request), createAnnouncementHandler);
}

function* updateAnnouncementWatcher() {
  yield (0, _effects.takeLatest)((0, _typesafeActions.getType)(_announcements.updateAnnouncementAsync.request), updateAnnouncementHandler);
}

function* announcementsSaga() {
  yield (0, _effects.all)([(0, _effects.fork)(getAnnouncementsWatcher), (0, _effects.fork)(createAnnouncementWatcher), (0, _effects.fork)(updateAnnouncementWatcher)]);
}