"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.paymentReducer = void 0;

var _typesafeActions = require("typesafe-actions");

var _immer = _interopRequireDefault(require("immer"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _map = _interopRequireDefault(require("lodash/map"));

var _union = _interopRequireDefault(require("lodash/union"));

var _payment = require("./payment.types");

var _state = require("../../types/state.types");

const updatePaymentsReducer = (0, _immer.default)((draft, payments) => {
  if (!payments) {
    return;
  }

  draft.allIds = (0, _union.default)(draft.allIds, (0, _map.default)((0, _keys.default)(payments), Number));
  const ids = Object.keys(payments);

  for (const id of ids) {
    draft.byId[id] = payments[id];
  }
});

const paymentReducer = (state = _state.initialNormalizedState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_payment.getPaymentsAsync.success):
      return updatePaymentsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_payment.getPaymentsReportAsync.success):
      return updatePaymentsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_payment.getParticipantPaymentsAsync.success):
      return updatePaymentsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_payment.requestExitInterviewPaymentAsync.success):
      return updatePaymentsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_payment.requestAdHocPaymentAsync.success):
      return updatePaymentsReducer(state, action.payload);

    case (0, _typesafeActions.getType)(_payment.markPaidAsync.success):
      return updatePaymentsReducer(state, action.payload);

    default:
      return state;
  }
};

exports.paymentReducer = paymentReducer;
var _default = paymentReducer;
exports.default = _default;