"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.analyticsUIReducer = exports.initialAnalyticsUIState = void 0;

var _typesafeActions = require("typesafe-actions");

var _analytics = require("./analytics.types");

const initialAnalyticsUIState = {
  reportRows: []
};
exports.initialAnalyticsUIState = initialAnalyticsUIState;

const analyticsUIReducer = (state = initialAnalyticsUIState, action) => {
  switch (action.type) {
    case (0, _typesafeActions.getType)(_analytics.getAnalyticsReportAsync.success):
      return { ...state,
        reportRows: action.payload
      };

    default:
      return { ...state
      };
  }
};

exports.analyticsUIReducer = analyticsUIReducer;
var _default = analyticsUIReducer;
exports.default = _default;