"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteAvailabilityAsync = exports.createAvailabilityAsync = exports.getAvailabilitiesAsync = void 0;

var _typesafeActions = require("typesafe-actions");

const getAvailabilitiesAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/GET_AVAILABILITY_REQUEST", "hmp/admin/GET_AVAILABILITY_SUCCESS", "hmp/admin/GET_AVAILABILITY_FAILURE")();
exports.getAvailabilitiesAsync = getAvailabilitiesAsync;
const createAvailabilityAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/CREATE_AVAILABILITY_REQUEST", "hmp/admin/CREATE_AVAILABILITY_SUCCESS", "hmp/admin/CREATE_AVAILABILITY_FAILURE")();
exports.createAvailabilityAsync = createAvailabilityAsync;
const deleteAvailabilityAsync = (0, _typesafeActions.createAsyncAction)("hmp/admin/DELETE_AVAILABILITY_REQUEST", "hmp/admin/DELETE_AVAILABILITY_SUCCESS", "hmp/admin/DELETE_AVAILABILITY_FAILURE")();
exports.deleteAvailabilityAsync = deleteAvailabilityAsync;
const availabilityActions = {
  getAvailabilitiesAsync,
  createAvailabilityAsync,
  deleteAvailabilityAsync
};